import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  displayHistory: 'history',
  historyStatusFilter: [],
  pollStatusFilter: []
};

export const vehicleModelPollFilterSlice = createSlice({
  name: 'vehicleModelPollFilter',
  initialState,
  reducers: {
    setDisplayHistory(state, { payload }) {
      state.displayHistory = payload;
    },
    setHistoryStatusFilter(state, { payload }) {
      state.historyStatusFilter = payload;
    },
    setPollStatusFilter(state, { payload }) {
      state.pollStatusFilter = payload;
    }
  }
});

// Actions
export const { 
  setDisplayHistory, 
  setHistoryStatusFilter, 
  setPollStatusFilter
} = vehicleModelPollFilterSlice.actions;

export default vehicleModelPollFilterSlice.reducer;
