import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const headerSlice = createSlice({
  initialState: {
    title: '',
    backButton: false
  },
  name: 'header',
  reducers: {
    setPageTitle: (state, { payload }) => {
      if (state.title === payload) {
        return;
      }
      state.title = payload;
    },
    setBackButton: (state, { payload }) => {
      if (state.backButton === payload) {
        return;
      }
      state.backButton = payload;
    }
  }
});

export const usePageTitle = () => useSelector(state => state.header.title);
export const useBackButton = () => useSelector(state => state.header.backButton);

export const {
  setPageTitle,
  setBackButton
} = headerSlice.actions;
export default headerSlice.reducer;
