import React from 'react';
import styled from '@emotion/styled';
import i18n from 'i18next';

import { useGetRegionsQuery } from '../features/regions/regionsApi';
import { useUserInfo } from '../features/user/userApi';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

const Subpage = styled.section`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  justify-content: center;
  align-items: center;
`;

export const NoAccessBody = () => {
  const data = useUserInfo();
  const { data: regions } = useGetRegionsQuery(undefined, { skip: data.id === undefined });
  const history = useHistory();

  const supportEmail = () => {
		const currentRegion = regions?.find(
		  region => region.locale.split('-')[0] === i18n.language
		);
		return typeof currentRegion === 'undefined'
		  ? 'tn360support-au@teletracnavman.com'
		  : currentRegion.supportEmail;
	  };

  return (
    <Subpage className="NoAccess">
      <h1>Dang it!</h1>
      <h2>You don't have access to this feature.</h2>
      <p>
        If you think you should have access or this is an error please contact{' '}
        <a href={`mailto:${supportEmail(regions)}`}>{supportEmail(regions)}</a>
      </p>
      <Button type="link" onClick={()=>{history.push("/")}}>Back to Home</Button>
    </Subpage>
  );
};
