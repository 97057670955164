import {
  logout,
  useUser,
  useUserKey,
  REMOTE_SIGN_IN_ERROR,
  remoteSignInFailed,
  REMOTE_SIGN_IN_TIMEOUT,
  setRemoteSignInAvaliable
} from '../../features/user/ssoSlice';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {  KEYCLOAK_URL } from '../../config';
import { RouteKey } from '../../config/page-key';
import moment from 'moment';
import { StorageKey } from '../../features/localStorage/storageKey';

export function useSSO() {
  const user = useUser();
  const userKey = useUserKey();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user == null || userKey == null || user.auth?.keycloakConfig == null) return;

    if (document.querySelector('#keycloakScript') == null) {
      const keycloakScriptNode = document.createElement('script');
      keycloakScriptNode.type = 'text/javascript';
      keycloakScriptNode.src = KEYCLOAK_URL;
      keycloakScriptNode.onload = async () => {
        const keycloakConfig = user.auth.keycloakConfig;
        window.keycloak = new window.Keycloak(keycloakConfig);
        const logoutUser = () => {
          let redirectUrl = RouteKey.SSO;
          if (user.auth.keycloakConfig.realm) {
            redirectUrl += '?realm=' + user.auth.keycloakConfig.realm;
          }
          dispatch(remoteSignInFailed());
          window.location.href = redirectUrl;
        };
        window.keycloak.onAuthLogout = logoutUser;

        window.keycloak.onTokenExpired = () => {
          window.keycloak
            .updateToken(30)
            .catch(e => {
              logoutUser();
              dispatch(remoteSignInFailed(REMOTE_SIGN_IN_ERROR.KC_AUTH_ERROR));
            });
        };
        window.localStorage.setItem(StorageKey.USER_ACTION_TIME, moment().format('X'));
        const { error } = await checkSSO(true);
        
        dispatch(setRemoteSignInAvaliable({ remoteSignInAvaliable: !error, remoteSignInError: error }));
      };
      keycloakScriptNode.onerror = () => {
        dispatch(
          setRemoteSignInAvaliable({
            remoteSignInAvaliable: false,
            remoteSignInError: REMOTE_SIGN_IN_ERROR.KC_ADAPTER_LOAD_ERROR
          })
        );
      };
      keycloakScriptNode.id = 'keycloakScript';
      document.head.appendChild(keycloakScriptNode);
    }
  }, [user, userKey, dispatch]);
}

const checkSSO = async (authRequired = false) => {
  return new Promise(resolve => {
    window.keycloak
      .init({
        silentCheckSsoRedirectUri: window.location.origin+RouteKey.DUMMY,
        flow: 'standard',
        onLoad: 'check-sso',
        messageReceiveTimeout: REMOTE_SIGN_IN_TIMEOUT,
        checkLoginIframe: false
      })
      .then(authenticated =>
        resolve({
          error: !authenticated && authRequired ? REMOTE_SIGN_IN_ERROR.KC_AUTH_ERROR : null
        })
      )
      .catch(e => resolve({ error: REMOTE_SIGN_IN_ERROR.KC_INIT_ERROR }));
  });
};