import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Form, Button, Modal, Space, AutoComplete } from 'antd';

import { useFuzzySearch } from '../../utils/fuzzySearch.js';
import styles from './VehicleMakeModelYearFormModal.module.scss';

import { useVehicleModelWithSource, useSaveVehicleModelMutation } from '../../features/vehicleModels/vehicleModelApi';
import { useCurrentCompany } from '../../features/company/companySlice';
import { addToast } from '../../features/toast/toastsSlice.js';
import { VehicleModelFormItem } from './constant.js';
import { executeSingleAction } from '../../utils/ApiResponseHandler.js';

export const VehicleMakeModelYearFormModal = ({
  isModalOpen,
  onClose
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [vehicleMakeForm] = Form.useForm();
  const currentCompany = useCurrentCompany();
  const { vehicleModels: makeModelYearList } = useVehicleModelWithSource(currentCompany?.id)
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormSaving, setIsFormSaving] = useState(false);
	const [saveVehicleModel] = useSaveVehicleModelMutation();
  const { performIndividualKeySearch } = useFuzzySearch(makeModelYearList || [], ['make', 'model', 'yearOfManufacture']);

  const formItems = VehicleModelFormItem(t);

  const [localTempSearchedValue, setLocalTempSearchedValue] = useState({
    make: "",
    model: "",
    yearOfManufacture: ""
  });

  useEffect(()=>{
    if (isModalOpen) {
      handleResetFields();
    }
  }, [isModalOpen])

  const renderNotFoundPanel = type => {
    let possibleMatch = [];
    if (localTempSearchedValue.value) {
      const possibleMatchModel = performIndividualKeySearch(localTempSearchedValue.value || '', type);
      possibleMatch = Array.from(new Set(possibleMatchModel.map(i => i[type]))).slice(0, 5);
    }

    if ((possibleMatch || []).length !== 0) {
      return (
        <div style={{ padding: "0px 10px" }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {(possibleMatch || []).map((data, index) => (
              <a
                key={index}
                className={styles.makeModelYearSuggestionValue}
                onClick={() => {
                  vehicleMakeForm.setFieldValue(type, data);
                  document.activeElement.blur();
                }}
              >
                {data}
              </a>
            ))}
          </div>
        </div>
      );
    }
  };

  const handleValueSearch = (value, type) => {
    value && setLocalTempSearchedValue({ value, type });
  };

  const renderFormItems = formItems => {
    return formItems.map((formItem, formItemIndex) => {
      const { name, label, rules } = formItem;
      return (
        <Form.Item
          key={`${formItemIndex}-${name}`}
          name={name}
          labelAlign="left"
          label={label}
          colon={false}
          rules={rules}
        >
          {renderFormItemChild(name)}
        </Form.Item>
      );
    });
  };

  const renderFormItemChild = (name) => {
    const options = Array.from(new Set((makeModelYearList || []).map(i => i[name])))
      .filter(i => i?.toString() !== undefined && i?.toString()?.length !== 0)
      .map(data => {
        return { label: data?.toString(), value: data?.toString() };
      });

    return (
      <AutoComplete
        onSelect={e => {
          vehicleMakeForm.setFieldValue(name, e);
        }}
        options={options}
        filterOption={(inputValue, option) =>
          option.value
            ?.toString()
            .toUpperCase()
            .indexOf(inputValue?.toString().toUpperCase()) !== -1
        }
        onFocus={() => {
          handleValueSearch(vehicleMakeForm.getFieldValue(name), name);
        }}
        onSearch={e => {
          handleValueSearch(e, name);
        }}
        notFoundContent={renderNotFoundPanel(name)}
      />
    );
  };

  const renderFooterContactFormModal = () => {
    return (
      <Space>
        <Button
          htmlType="submit"
          type="primary"
          disabled={!isFormValid}
          loading={isFormSaving}
        >
          {t('Common.SaveButton')}
        </Button>
        <Button
          disabled={!isFormDirty}
          onClick={handleResetFields}
        >
          {t(`Common.ResetButton`)}
        </Button>
        <Button onClick={closeModal}>
          {t(`Common.CancelButton`)}
        </Button>
      </Space>
    );
  };

  const handleResetFields = () => {
    setIsFormDirty(false);
    setIsFormValid(false);
    vehicleMakeForm.resetFields();
  };

  const closeModal = () => {
    handleResetFields();
    onClose();
  };

  const handleValuesChange = () => {
    const isValid = Object.values(vehicleMakeForm.getFieldsValue()).every(value => !!value);
    setIsFormDirty(true);
    setIsFormValid(isValid);
  };

  const handleSave = async () => {
    //disable the save button when the saving process starts
    setIsFormSaving(true);
    if (isFormSaving) {
      return;
    }
    const valuesFromForm = vehicleMakeForm.getFieldsValue();
    const { ...payload } = valuesFromForm;
    payload.companyId = currentCompany?.id;
    const identicalMakeModelYearEntity = makeModelYearList.find(
      makeModelYear =>
        makeModelYear.make.toLowerCase() === payload.make.toLowerCase() &&
        makeModelYear.model.toLowerCase() === payload.model.toLowerCase() &&
        parseInt(makeModelYear.yearOfManufacture, 0) === parseInt(payload.yearOfManufacture, 0) &&
        makeModelYear.source?.toLowerCase() === 'internal'
    );

    if (identicalMakeModelYearEntity) {
      setIsFormSaving(false);
      dispatch(addToast({
        key: identicalMakeModelYearEntity.id,
        type: "error",
        content: t('ValidationErrors.AddMakeModelYearIdenticalError')
      }));
      return;
    }

    // We need to save with the same make and/or model value from the database (avoid case sensitive)
    const identicalMakeEntity = makeModelYearList.find(
      makeModelYear => makeModelYear.make.toLowerCase() === payload.make.toLowerCase()
    );

    if (identicalMakeEntity) {
      payload.make = identicalMakeEntity.make;
    }

    const identicalModelEntity = makeModelYearList.find(
      makeModelYear => makeModelYear.model.toLowerCase() === payload.model.toLowerCase()
    );

    if (identicalModelEntity) {
      payload.model = identicalModelEntity.model;
    }

    payload.source = 'internal';
    payload.identifier = "VehicleModel" + payload.id;
    payload.loadingMessage = t("Common.Saving");
    payload.successMessage = t("VehicleModel.VehicleModelSaved");

    executeSingleAction(payload, saveVehicleModel, dispatch).then(()=>{
      closeModal();
      setIsFormSaving(false);
		}).catch(()=>{
      setIsFormSaving(false);
    })
  };

  return (
    <Modal
      title={t('VehicleModel.AddModelFormModalTitle')}
      open={isModalOpen}
      onCancel={closeModal}
      wrapClassName={styles.vehicleMakeFormModal}
      footer={null}
    >
      <div className={styles.vehicleMakeFormContainer}>
        <Form
          form={vehicleMakeForm}
          onValuesChange={handleValuesChange}
          name="VehicleMakeForm"
          onFinish={handleSave}
          layout="vertical"
        >
          {renderFormItems(formItems)}
          {renderFooterContactFormModal()}
        </Form>
      </div>
    </Modal>
  );
};
