import React, { forwardRef } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

export const CsvLink = forwardRef(({ data, fileName }, ref) => {
	const { i18n, t } = useTranslation();

	const addCustomContent = (event) => {
		event.preventDefault();
		const customHeader = Object.keys(data[0]);
		const dataArray = data.map(obj => Object.values(obj));
		const csvContent = [[t("Common.ExportWarning")], customHeader, ...dataArray].map(row => row.join(',')).join('\n');

		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.setAttribute('href', url);
		link.setAttribute('download', fileName);
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<CSVLink
			data={data || []}
			enclosingCharacter={``}
			className={{ display: "none" }}
			onClick={addCustomContent}
			ref={ref} />
	);
});