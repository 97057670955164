import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Radio, Space } from 'antd';
import { Select, Modal, Row, Col } from 'antd';
import { MoreOutlined, SearchOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import "./VehicleCompatibilityInfoModal.scss"
import infoImg from '../../assets/image/hr-our-solutions-l1-hero.jpg';
import { useMediaQuery } from 'react-responsive';
import {ResponsiveModal} from '../../features/responsive';

const VehicleCompatibilityInfoModal = ({isModalOpen, onClose}) => {
	const { i18n, t } = useTranslation();
  const [viewingIndex, setViewingIndex] = useState(0);

  const vinDetail = () => {
    return (
      <>
        <Row className={"infoRow"}>
          <Col xs={{ span: 24 }} className={"mb-2"}>
            <img src={infoImg} style={{ width: "100%", height: "300px", objectFit:"contain" }} />
          </Col>
        </Row>
        <Row className={"infoRow"}>
          <Col xs={{ span: 24 }} className={"mb-2"}>
            <p style={{ textAlign: "center" }}>
              {t("VehicleModel.VinInformation")}
            </p>
          </Col>
        </Row>
      </>
    );
  }

  const vehicleCompatibilityDetail = ()=>{
    return (
      <>
        <Row className={"infoRow"}>
          <Col xs={{ span: 24 }} className={"mb-2"}>
            <Select suffixIcon={<MoreOutlined />}
              defaultValue={["Acura","2008"]}
              placeholder={
                <SearchOutlined />
              }
              mode="tags" style={{ width: '100%' }}/>
          </Col>
        </Row>
        <Row className={"infoRow"}>
          <Col xs={{ span: 24 }} className={"mb-2"}>
            <p style={{ textAlign: "center" }}>
              {t("VehicleModel.SearchInformation")}
            </p>
          </Col>
        </Row>
      </>
    );
  }

  const detailContent =[
    vehicleCompatibilityDetail,
    vinDetail,
  ]

  return (
    <ResponsiveModal
      onClose={onClose}
      isModalOpen={isModalOpen}
      footer={null}
      width={"30%"}
      style={{ minWidth: 350 }}
      className={"customFooter"}
    >
      {detailContent[viewingIndex]()}
      <Row className={"footerRow"}>
        <Col xs={{ span: 12 }}>
          <Button type="link" className='defaultBtn' style={{ display: viewingIndex == 0 ? "none" : "inline" }} onClick={() => { setViewingIndex(prev => prev - 1) }}>
            <LeftOutlined />
            {t("Common.Previous")}
          </Button>
        </Col>
        <Col xs={{ span: 12 }} style={{ textAlign: "right" }}>
          <Button type="link" className='defaultBtn' style={{ display: viewingIndex + 1 == detailContent.length ? "none" : "inline" }} onClick={() => { setViewingIndex(prev => prev + 1) }}>
            {t("Common.Next")}
            <RightOutlined />
          </Button>
        </Col>
      </Row>
    </ResponsiveModal>
  )
}

export default VehicleCompatibilityInfoModal;