import React from 'react';
import { addToast } from "../features/toast/toastsSlice";
import { parseErrorMessage } from "./strings";
import i18n from 'i18next';

export const executeSingleAction = (payload, action, dispatch) => {
  return new Promise((resolve, reject) => {
    const key = payload.identifier;

    dispatch(addToast({
      key,
      type: "loading",
      content: payload.loadingMessage + "...",
    }));

    action(payload)
      .unwrap()
      .then(() => {
        dispatch(addToast({
          key,
          type: "success",
          content: payload.successMessage,
        }));
        resolve();
      })
      .catch((error) => {
        if (error) {
          dispatch(addToast({
            key,
            type: "error",
            content: parseErrorMessage(error.data)
          }));
        }
        reject(error);
      })
  });
}

export const executeBulkAction = (payload, action, dispatch) => {
  return new Promise((resolve, reject) => {
    const key = payload.identifier;

    dispatch(addToast({
      key,
      type: "loading",
      content: payload.loadingMessage + "...",
    }));

    action(payload)
      .unwrap()
      .then((e) => {
        if (e?.errorCount !== undefined && e?.errorCount !== 0) {
          const mainMessage = i18n.t(payload.partialMessage, {failedCount: e.errorCount});
      
          const messageArr = [mainMessage, ...e.errors];
          dispatch(
            addToast({
              key,
              type: "warning",
              content: <span>
                {
                  messageArr.map((msg, index) => (
                    <React.Fragment key={index}>
                      {msg}
                      {index < messageArr.length - 1 && <br />}
                    </React.Fragment>
                  ))
                }
              </span>
            })
          );
        } else {
          dispatch(
            addToast({
              key,
              type: "success",
              content: payload.successMessage
            })
          );
        }
        resolve();
      })
      .catch((error) => {
        if (error) {
          dispatch(addToast({
            key,
            type: "error",
            content: parseErrorMessage(error.data)
          }));
        }
        reject(error);
      })
  });
}