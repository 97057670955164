import React from 'react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_PATH } from '../../config';
import { useMemo } from 'react';

import { addToast } from "../../features/toast/toastsSlice"
import i18n from 'i18next';
import { parseErrorMessage } from '../../utils/strings';
import { useCan } from '../user/Can';
import { Role, checkIsAdminOrSiteAdmin } from '../user/Permission';
import { CompanyApi } from '../company/companyApi';
import { useUserKey } from '../user/ssoSlice';
import { UserApi } from '../user/userApi';

export const VehicleModelApi = createApi({
  reducerPath: "VehicleModelApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_PATH,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const { user } = getState();
      headers.append('Authorization', `Token token="${user?.current?.auth?.key}"`)

      return headers
    },
  }),
  tagTypes: ["VehicleModel", "UserVoteHistory", 'Polls'],
  endpoints: (builder) => ({
    getVehicleModelCompatibilities: builder.query({
      query: (arg) => {
        const params = {
          company_id: arg.companyId,
          embed: arg.embed,
          pruning: "IDS"
        };

        return {
          url: `/vehiclecompatibility`,
          params
        };
      },
      providesTags: ['VehicleModel'],
      async onQueryStarted({ companyId, userKey, embed }, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled;
        const selectDeviceTypes = VehicleModelApi.endpoints.getDeviceTypes.select({ companyId });
        const selectKits = VehicleModelApi.endpoints.getKits.select({ companyId });
        const selectPids = VehicleModelApi.endpoints.getPids.select({ companyId });
        const selectVehicleModels = VehicleModelApi.endpoints.getVehicleModels.select({ companyId });
        const selectCompanies = CompanyApi.endpoints.getCompanies.select({ userKey });
        const selectDeviceModels = VehicleModelApi.endpoints.getDeviceModels.select({});

        const allKits = selectKits(getState()).data;
        const allPids = selectPids(getState()).data;
        const allDeviceTypes = selectDeviceTypes(getState()).data;
        const allVehicleModels = selectVehicleModels(getState()).data;
        const allDeviceModels = selectDeviceModels(getState()).data;

        dispatch(
          VehicleModelApi.util.updateQueryData('getVehicleModelCompatibilities',
            { companyId, userKey, embed }
            , (draft) => {
              const response = JSON.parse(JSON.stringify(draft));

              let index = 0;
              let formattedResponse = [];

              let kitDetailMap = allKits.reduce((map, item) => {
                map[item.id] = item;
                return map;
              }, {});

              let vehicleModelMap = allVehicleModels.reduce((map, item) => {
                map[item.id] = item;
                return map;
              }, {});

              const deviceTypeMap = allDeviceTypes.reduce((map, item) => {
                map[item.id] = item;
                return map;
              }, {})

              const deviceModelsMap = allDeviceModels.reduce((map, item) => {
                map[item.id] = item;
                return map;
              }, {})

              const pidIndex = {};
              allPids.forEach(pid => {
                pidIndex[pid.id] = pid;
              });

              response.map((data) => {
                data.vehicleModel = { ...vehicleModelMap[data.id] };
                if (data.vehicleModel.extras) {
                  data.vehicleModel.extras = JSON.parse(data.vehicleModel.extras);
                }

                if (data.vehicleModel?.source === undefined && data.vehicleModel?.displaySource) {
                  data.vehicleModel.source = data.vehicleModel.displaySource;
                }

                const supportedKit = {};
                let supportedDeviceType = new Set();
                if (data.deviceModels) {
                  data.supportedPid = {};
                  data.deviceModels.forEach((deviceModel) => {
                    const deviceType = deviceTypeMap[deviceModel.deviceTypeId];
                    supportedDeviceType.add(deviceType)
                    supportedKit[deviceType.name] = [];
                    if (deviceModel.pidDetail) {
                      deviceModel.pids = [];

                      deviceModel.pidDetail.forEach(det => {
                        // Use map for the inner loop to create an array of pidData objects
                        const pidDataArray = det.pidIds.map(pidId => {
                          let pidData = JSON.parse(JSON.stringify(pidIndex[pidId]));
                          pidData.dbcKey = det.dbcKey;
                          pidData.canId = det.canId;
                          return pidData;
                        });

                        // Concatenate the pidDataArray to the deviceModel.pids array
                        deviceModel.pids = deviceModel.pids.concat(pidDataArray);
                      });
                    }

                    if (deviceModel.availableKits && deviceModel.availableKits.length !== 0) {
                      deviceModel.availableKits.map((kit) => {
                        let kitDetail = kitDetailMap[kit.kitId];
                        supportedKit[deviceType.name].push({
                          id: kit.kitId,
                          sapCode: kitDetail.sapCode,
                          sapType: kitDetail.sapType,
                          kitType: kit.kitType
                        })
                      });
                    }

                    const deviceTypePids = data.supportedPid[deviceType.name] || [];
                    data.supportedPid[deviceType.name] = deviceTypePids.concat(deviceModel.pids.map((pid) => {
                      return {
                        deviceTypeId: deviceType.id,
                        deviceModelId: deviceModel.id,
                        deviceModelName: deviceModelsMap[deviceModel.id].name,
                        busType: pid.busType,
                        pid: pid.pid,
                        id: pid.id,
                        dbcKey: pid.dbcKey,
                        canId: pid.canId,
                        description: pid.description,
                        category: pid.category,
                      }
                    }));
                  });
                }

                data.vehicleModel.supportedPid = [];
                data.vehicleModel.supportedKit = [];
                data.vehicleModel.vins = data.vins || [];

                if (supportedDeviceType.size !== 0) {
                  data.vehicleModel.deviceTypeDetail = [...supportedDeviceType].map(deviceType => {
                    let deviceTypeInfo = {}

                    deviceTypeInfo.deviceType = deviceType.name;
                    deviceTypeInfo.deviceTypeId = deviceType.id;
                    data.vehicleModel.supportedPid = [...data.vehicleModel.supportedPid, ...Object.values(data.supportedPid[deviceType.name])];
                    data.vehicleModel.supportedKit = [...data.vehicleModel.supportedKit, ...Object.values(supportedKit[deviceType.name])];

                    return deviceTypeInfo;
                  });

                  if (data.vehicleModel.supportedPid && data.vehicleModel.supportedPid.length !== 0) {
                    data.vehicleModel.grouppedPid = data.vehicleModel.supportedPid.reduce((acc, obj) => {
                      const category = obj.category.toUpperCase() === "UNKNOWN" ? "Others" : obj.category;
                      if (!acc[category]) {
                        acc[category] = [];
                      }
                      acc[category].push(obj.description);
                      return acc;
                    }, {});
                  }
                  
                  delete data.supportedPid;
                }
                data.vehicleModel.rowId = ++index;
                if (data.documentId) {
                  data.vehicleModel.documentId = data.documentId;
                  data.vehicleModel.documentLink = data.documentLink;
                  data.vehicleModel.attachmentId = data.attachmentId;
                }

                if (data.vehicleModel.source == "internal") {
                  data.vehicleModel.displaySource = "Hermes Native";
                }

                if (data.vehicleModel?.extras?.hideHcv === undefined) {
                  formattedResponse.push(data.vehicleModel);
                }
                delete data.deviceModels;
              })

              return formattedResponse;
            })
        )
      }
    }),
    getUnverifiedVehicleModels: builder.query({
      query: (arg) => {
        const { companyId } = arg;
        const params = {
          company_id: companyId
        };

        return {
          url: `/vehiclemodels/unverifiedmake`,
          params
        };
      },
      providesTags: ['VehicleModel'],
      async onQueryStarted({ companyId }, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled;
        const selectVehicleModels = VehicleModelApi.endpoints.getVehicleModels.select({ companyId });

        const allVehicleModels = selectVehicleModels(getState()).data;

        dispatch(
          VehicleModelApi.util.updateQueryData('getUnverifiedVehicleModels',
            { companyId }
            , (draft) => {
              const response = JSON.parse(JSON.stringify(draft));

              let vehicleModelMap = allVehicleModels.reduce((map, item) => {
                map[item.id] = item;
                return map;
              }, {});

              let formattedResponse = [];

              formattedResponse = response.filter(i => vehicleModelMap[i.id] !== undefined).map((data) => {
                return { ...data, ...vehicleModelMap[data.id] };
              });

              return formattedResponse;
            })
        )
      },
    }),
    bulkUpdateVehicleModel: builder.mutation({
      query: (payload) => {
        const { id, action } = payload;
        let url = '/vehiclemodels/' + id + '/make/' + action;

        return {
          url,
          method: 'PUT',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      invalidatesTags: ['VehicleModel'],
    }),
    getVehicleModels: builder.query({
      query: (arg) => {
        const { companyId } = arg;
        const params = {
          company_id: companyId
        };

        return {
          url: `/vehiclemodels`,
          params
        };
      },
      providesTags: ['VehicleModel'],
      transformResponse: (response, meta, arg) => {
        response.forEach(data => {
          if (data.source === undefined && data.displaySource) {
            data.source = data.displaySource
          }

          if (data.source == "internal") {
            data.displaySource = "Hermes Native";
          }
        })
        return response;
      }
    }),
    getVehicleModelById: builder.query({
      query: (arg) => {
        const { id } = arg;
        let url = '/vehiclemodels';

        if (id) {
          url += "/" + id;
        }

        return {
          url
        };
      },
      providesTags: ['VehicleModel'],
      transformResponse: (response, meta, arg) => {
        if (response.source === undefined && response.displaySource) {
          response.source = response.displaySource
        }

        if (response.source == "internal") {
          response.displaySource = "Hermes Native";
        }
        return response;
      }
    }),
    getVehicleModelDocumentById: builder.query({
      query: (arg) => {
        const { id } = arg;
        let url = `/eventattachments/documentDetails?vehicleModelId=${id}`;

        return {
          url
        };
      },
      providesTags: ['VehicleModel'],
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: documentData } = await queryFulfilled;
          const selectUser = UserApi.endpoints.getUserInfo.select({ userId: getState().user?.current?.id });
          const currentUser = selectUser(getState()).data;

          const userKey = getState().user?.current?.auth?.key;
          const isAdminOrSiteAdmin = checkIsAdminOrSiteAdmin(currentUser);

          dispatch(
            VehicleModelApi.util.updateQueryData('getVehicleModelCompatibilities',
              { companyId: currentUser?.companyId, userKey, embed: isAdminOrSiteAdmin ? "devicemodels,pids,kits" : "devicemodels,pids" }
              , (draft) => {
                const cacheData = draft.filter(i => i.id === id);

                (cacheData || []).forEach(c => {
                  c.documentInfo = documentData;
                  c.isDocumentFetched = true;
                  c.make = c.make;
                })
              })
          )
        }
        catch {

        }
      },
      invalidatesTags: ['VehicleModel']
    }),
    mergeVehicleModel: builder.mutation({
      query: (payload) => {
        let url = '/vehiclemodels/merge';

        return {
          url,
          method: 'PUT',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      invalidatesTags: ['VehicleModel'],
    }),
    saveVehicleModel: builder.mutation({
      query: (payload) => {
        let url = '/vehiclemodels';

        if (payload.id) {
          url += "/" + payload.id;
        }

        return {
          url,
          method: payload.id ? 'PUT' : 'POST',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedData } = await queryFulfilled;
          const selectUser = UserApi.endpoints.getUserInfo.select({ userId: getState().user?.current?.id });
          const currentUser = selectUser(getState()).data;

          const userKey = getState().user?.current?.auth?.key;
          const isAdminOrSiteAdmin = checkIsAdminOrSiteAdmin(currentUser);

          dispatch(
            VehicleModelApi.util.updateQueryData('getVehicleModelCompatibilities',
              { companyId: currentUser?.companyId, userKey, embed: isAdminOrSiteAdmin ? "devicemodels,pids,kits" : "devicemodels,pids" }
              , (draft) => {
                const cacheData = draft.find(i => i.id === updatedData.id);

                if (cacheData) {
                  updateVehicleModel(cacheData, {...updatedData, extras: JSON.parse(updatedData.extras|| '{}')});
                }
                else {
                  return [...[updatedData], ...draft];
                }
              })
          )

          if (id) {
            dispatch(
              VehicleModelApi.util.updateQueryData('getVehicleModelById',
                { id: updatedData.id }
                , (draft) => {
                  updateVehicleModel(draft, updatedData);
                })
            )
          }

          dispatch(
            VehicleModelApi.util.updateQueryData('getVehicleModels',
              { companyId: currentUser?.companyId }
              , (draft) => {
                const cacheData = draft.find(i => i.id === updatedData.id);

                if (cacheData) {
                  updateVehicleModel(cacheData, updatedData);
                }
                else {
                  return [...[updatedData], ...draft];
                }
              })
          )
        }
        catch {

        }
      },
    }),
    saveVehicleModelBulk: builder.mutation({
      query: (payload) => {
        let url = '/vehiclemodels/bulk';
        return {
          url,
          method: 'PUT',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedData } = await queryFulfilled;
          const selectUser = UserApi.endpoints.getUserInfo.select({ userId: getState().user?.current?.id });
          const currentUser = selectUser(getState()).data;

          const userKey = getState().user?.current?.auth?.key;
          const isAdminOrSiteAdmin = checkIsAdminOrSiteAdmin(currentUser);

          (updatedData.vehicleModels || []).forEach(e => {
            dispatch(
              VehicleModelApi.util.updateQueryData('getVehicleModelCompatibilities',
                { companyId: currentUser?.companyId, userKey, embed: isAdminOrSiteAdmin ? "devicemodels,pids,kits" : "devicemodels,pids" }
                , (draft) => {
                  const cacheData = draft.find(i => i.id === e.id);

                  if (cacheData) {
                    updateVehicleModel(cacheData, {...e, extras: JSON.parse(e.extras|| '{}')});
                  }
                  else {
                    return [...[e], ...draft];
                  }
                })
            );

            dispatch(
              VehicleModelApi.util.updateQueryData('getVehicleModelById',
                { id: e.id }
                , (draft) => {
                  updateVehicleModel(draft, e);
                })
            )

            dispatch(
              VehicleModelApi.util.updateQueryData('getVehicleModels',
                { companyId: currentUser?.companyId }
                , (draft) => {
                  const cacheData = draft.find(i => i.id === e.id);
  
                  if (cacheData) {
                    updateVehicleModel(cacheData, e);
                  }
                  else {
                    return [...[e], ...draft];
                  }
                })
            )
          })
        }
        catch {

        }
      },
    }),
    deleteVehicleModel: builder.mutation({
      query: (payload) => {
        let url = '/vehiclemodels';

        if (payload.id) {
          url += "/" + payload.id;
        }

        return {
          url,
          method: 'DELETE',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        try {
          const selectUser = UserApi.endpoints.getUserInfo.select({ userId: getState().user?.current?.id });
          const currentUser = selectUser(getState()).data;

          const userKey = getState().user?.current?.auth?.key;
          const isAdminOrSiteAdmin = checkIsAdminOrSiteAdmin(currentUser);

          dispatch(
            VehicleModelApi.util.updateQueryData('getVehicleModelCompatibilities',
              { companyId: currentUser?.companyId, userKey, embed: isAdminOrSiteAdmin ? "devicemodels,pids,kits" : "devicemodels,pids" }
              , (draft) => {
                return draft.filter(i => i.id !== id);
              })
          )

          dispatch(
            VehicleModelApi.util.updateQueryData('getVehicleModels',
              { companyId: currentUser?.companyId }
              , (draft) => {
                return draft.filter(i => i.id !== id);
              })
          )
        }
        catch {

        }
      },
    }),
    getDeviceModels: builder.query({
      query: (arg) => {
        const { companyId } = arg;
        const params = companyId ? { company_id: companyId } : undefined;

        return {
          url: arg.compatibleModelOnly ? `/vehiclecompatibility/deviceModels` : `/devices/models`,
          params
        };
      }
    }),
    getPids: builder.query({
      query: (arg) => {
        const { companyId } = arg;
        const params = { company_id: companyId };

        return {
          url: `/vehiclecompatibility/pids`,
          params
        };
      }
    }),
    getDeviceTypes: builder.query({
      query: (arg) => {
        const { companyId } = arg;
        const params = { company_id: companyId };

        return {
          url: `/vehiclecompatibility/deviceTypes`,
          params
        };
      }
    }),
    getKits: builder.query({
      query: (arg) => {
        const { companyId } = arg;
        const params = { company_id: companyId };

        return {
          url: `/vehiclecompatibility/kits`,
          params
        };
      }
    }),
    voteForScanRequest: builder.mutation({
      query: (payload) => {
        const { id } = payload;
        let url = '/vehiclemodels/' + id + '/vote';

        return {
          url,
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      invalidatesTags: ['UserVoteHistory', 'Polls'],
    }),
    unVoteForScanRequest: builder.mutation({
      query: (payload) => {
        const { id } = payload;
        let url = '/vehiclemodels/' + id + '/unvote';

        return {
          url,
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      invalidatesTags: ['UserVoteHistory', 'Polls'],
    }),
    changePollStatus: builder.mutation({
      query: (payload) => {
        const { id } = payload;
        let url = '/vehiclemodels/poll/' + id;

        return {
          url,
          method: 'PUT',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      invalidatesTags: ['UserVoteHistory', 'Polls'],
    }),
    getUserVoteHistory: builder.query({
      query: () => {
        return {
          url: `/vehiclemodels/poll/history`
        };
      },
      providesTags: ['UserVoteHistory']
    }),
    getPolls: builder.query({
      query: () => {
        return {
          url: `/vehiclemodels/polls`
        };
      },
      providesTags: ['Polls']
    })
  }),
})

const updateVehicleModel = (cacheData, updatedData) => {
  cacheData.make = updatedData.make;
  cacheData.model = updatedData.model;
  cacheData.yearOfManufacture = updatedData.yearOfManufacture;
  cacheData.isSupported = updatedData.isSupported;
  cacheData.priority = updatedData.priority;
  cacheData.dbcFileKey = updatedData.dbcFileKey;
  cacheData.extras = updatedData.extras;
}

export const {
  useGetVehicleModelCompatibilitiesQuery,
  useGetVehicleModelsQuery,
  useGetVehicleModelByIdQuery,
  useGetUnverifiedVehicleModelsQuery,
  useGetPidsQuery,
  useGetDeviceModelsQuery,
  useGetDeviceTypesQuery,
  useGetKitsQuery,
  useGetUserVoteHistoryQuery,
  useGetPollsQuery,
  useSaveVehicleModelMutation,
  useSaveVehicleModelBulkMutation,
  useDeleteVehicleModelMutation,
  useMergeVehicleModelMutation,
  useBulkUpdateVehicleModelMutation,
  useChangePollStatusMutation,
  useVoteForScanRequestMutation,
  useUnVoteForScanRequestMutation
} = VehicleModelApi

export const { getVehicleModelDocumentById } = VehicleModelApi.endpoints;

export const useUnverifiedVehicle = (companyId) => {
  const { data } = useGetVehicleModelsQuery({ companyId: companyId }, { pollingInterval: 3600000, skip: companyId === undefined });
  const { data: vehicleModels, isFetching } = useGetUnverifiedVehicleModelsQuery({ companyId: companyId }, { pollingInterval: 3600000, skip: companyId === undefined || data === undefined });

  return useMemo(() => {
    const isCompatiblityDataLoaded = (vehicleModels || []).length !== 0 && vehicleModels[0].source !== undefined;

    if (isCompatiblityDataLoaded) {
      return {
        vehicleModels: vehicleModels,
        isFetching: isFetching
      };
    }
    else {
      return { isFetching: true };
    }
  }, [vehicleModels, isFetching]);
}

export const useVehicleModelWithSource = (companyId, source = 'internal') => {
  const { data, isFetching } = useGetVehicleModelsQuery({ companyId: companyId }, { pollingInterval: 3600000, skip: companyId === undefined });
  return useMemo(() => {
    if (data) {
      const targetSource = source;
      return {
        vehicleModels: (data || []).filter(
          i =>
            targetSource === undefined ||
            i.source?.toLowerCase() === targetSource?.toLowerCase() ||
            i.displaySource?.toLowerCase() === targetSource?.toLowerCase()
        ),
        isFetching: isFetching
      };
    }
    else {
      return { isFetching: true };
    }
  }, [data, isFetching]);
}

export const usePolls = () => {
  const { data: history, isFetching: isHistoryFetching } = useGetUserVoteHistoryQuery({}, { pollingInterval: 3600000 });
  const { data: polls, isFetching: isPollsFetching } = useGetPollsQuery({}, { pollingInterval: 3600000 });

  return useMemo(() => {

    if (!isPollsFetching && !isHistoryFetching && history && polls) {
      const clonedList = new Array();
      history.forEach(obj => {
        const matchedPoll = polls.filter(poll => obj.pollId === poll.id)[0];
        const cloned = {
          ...obj,
          vehicleModel: matchedPoll.vehicleModel,
          comment: matchedPoll.comment,
          status: matchedPoll.status
        };
        clonedList.push(cloned);
      });
      return {
        polls,
        history: clonedList,
        isFetching: false
      };
    }
    else {
      return { isFetching: true };
    }
  }, [history, isHistoryFetching, polls, isPollsFetching]);
}

export const useVehicleCompatibility = (companyId) => {
  const can = useCan();
  const userKey = useUserKey();
  const { data: allKits } = useGetKitsQuery({ companyId: companyId }, { pollingInterval: 3600000, skip: companyId === undefined });
  const { data: allPids } = useGetPidsQuery({ companyId: companyId }, { pollingInterval: 3600000, skip: companyId === undefined });
  const { data: allDeviceTypes } = useGetDeviceTypesQuery({ companyId: companyId }, { pollingInterval: 3600000, skip: companyId === undefined });
  const { data: vehicleModels } = useGetVehicleModelsQuery({ companyId: companyId }, { pollingInterval: 3600000, skip: companyId === undefined });
  const { data: deviceModels } = useGetDeviceModelsQuery({}, { pollingInterval: 3600000 });

  const { data: compatibilityData, isFetching: isFetchingVehicleCompatibility } = useGetVehicleModelCompatibilitiesQuery({ companyId: companyId, userKey, embed: can({ oneOfRole: [Role.ADMIN, Role.SITE_ADMIN] }) ? "devicemodels,pids,kits" : "devicemodels,pids" },
    { pollingInterval: 3600000, skip: companyId === undefined || allKits === undefined || allPids === undefined || vehicleModels === undefined || allDeviceTypes === undefined || deviceModels === undefined });

  return useMemo(() => {
    const isCompatiblityDataLoaded = (compatibilityData || []).length !== 0 && compatibilityData[0].make !== undefined;

    if (isCompatiblityDataLoaded) {
      const vehicleCompatibility = isCompatiblityDataLoaded ? compatibilityData : [];

      return {
        vehicleCompatibility: vehicleCompatibility,
        allDeviceTypes: allDeviceTypes,
        allKits,
        vehicleModels,
        isFetching: isFetchingVehicleCompatibility || compatibilityData === undefined
      };
    }
    else {
      return {
        isFetching: true,
        allDeviceTypes: allDeviceTypes,
        allKits,
        vehicleModels
      };
    }
  }, [compatibilityData, allKits, allPids, allDeviceTypes, isFetchingVehicleCompatibility]);
};

export const useGrouppedVehicleCompatibility = (companyId) => {
  const { vehicleCompatibility, isFetching } = useVehicleCompatibility(companyId);

  return useMemo(() => {
    const groupedModelData = (vehicleCompatibility || []).reduce((acc, item) => {
      const key = `${(item.make +"_" + item.model+"_"+item.yearOfManufacture+ "_"+item.company?.id ?? "-1") + "_" + (item.isSupported || 'unknown')}`;

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(item);

      return acc;
    }, {});

    const uniqueList = (vehicleCompatibility || []).reduce((acc, current) => {
      const currentGroupModel = groupedModelData[(current.make +"_" + current.model+"_"+current.yearOfManufacture + "_"+current.company?.id ?? "-1")+ "_" + (current.isSupported || 'unknown')];
      let matchedModelList = [];

      currentGroupModel.forEach(c=>{
        if (matchedModelList.length === 0) {
          matchedModelList = acc.filter(item => item.make === c.make &&
            item.model === c.model &&
            item.isSupported === c.isSupported &&
            ((item.source !== c.source && item.yearOfManufacture === c.yearOfManufacture) ||
              (item.attachmentId === c.attachmentId &&
                item.priority === c.priority && item.extras?.note === c.extras?.note &&
                item.dbcFileKey === c.dbcFileKey && (item.supportedPid || []).length === (c.supportedPid || []).length)) &&
            item.company?.id === c.company?.id);
        }
      })

      //if not the same source but with same year and group found in list, mean the paired record is already there, let skip
      if (matchedModelList.find(i => i.source !== current.source && i.yearOfManufacture === current.yearOfManufacture && i.groupId === current.groupId)) {
        return acc;
      }

      let matchedModel = matchedModelList[0];

      let clonedObj = { ...current };
      //check for detail inside to see if they really match
      if (matchedModel) {
        if (currentGroupModel.some(m => {
          return (m.supportedPid || []).length !== (matchedModel.groupModelDetail[m.source]?.supportedPid || []).length ||
            m.attachmentId !== matchedModel.groupModelDetail[m.source]?.attachmentId ||
            m.priority !== matchedModel.groupModelDetail[m.source]?.priority ||
            m.extras?.note !== matchedModel.groupModelDetail[m.source]?.note ||
            m.dbcFileKey !== matchedModel.groupModelDetail[m.source]?.dbcFileKey;
        })) {
          matchedModel = undefined;
        }
      }

      if (!matchedModel) {
        clonedObj.yearList = [clonedObj.yearOfManufacture];
        clonedObj.yearListString = clonedObj.yearOfManufacture + "";
        clonedObj.groupModel = currentGroupModel;
        clonedObj.fullGroupModels = [...clonedObj.groupModel];
        clonedObj.groupModelDetail = clonedObj.groupModel.reduce((acc, item) => {
          acc[item.source] = {
            supportedPid: item.supportedPid || [],
            attachmentId: item.attachmentId,
            dbcFileKey: item.dbcFileKey,
            priority: item.priority,
            note: item.extras?.note
          };
          return acc;
        }, {});
        acc.push(clonedObj);
      }
      else if (current.yearOfManufacture) {
        matchedModel.yearList.push(current.yearOfManufacture);
        matchedModel.yearListString = matchedModel.yearListString + "," + current.yearOfManufacture;
        matchedModel.fullGroupModels = [...matchedModel.fullGroupModels, ...currentGroupModel];
      }

      return acc;
    }, []);
    
    return {
      isFetching,
      vehicleCompatibility: uniqueList,
    };

  }, [vehicleCompatibility, isFetching]);
}