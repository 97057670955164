import React, { useState, useEffect } from 'react';
import { PageSettings } from './config/page-settings.js';
import { useLocation, useHistory } from 'react-router-dom';

import Header from './components/header/header.js';
import Sidebar from './components/sidebar/sidebar.js';
import Content from './components/content/content.js';
import { useSSO } from './pages/login/ssoHook';
import { RouteKey } from './config/page-key.js';
import { Layout, Skeleton } from 'antd';

export const App = () => {
	const location = useLocation();
	const [pageHeader, setPageHeader] = useState(true);
	const [pageSidebar, setPageSidebar] = useState(true);

	useSSO();

	useEffect(() => {
		setPageHeader(location.pathname !== RouteKey.LOGIN && location.pathname !== RouteKey.SSO)
		setPageSidebar(location.pathname !== RouteKey.LOGIN && location.pathname !== RouteKey.SSO)
	}, [location.pathname]);

	const handleSetPageSidebar = (value) => {
		setPageSidebar(value);
	}

	const handleSetPageHeader = (value) => {
		setPageHeader(value)
	}

	return (
		<PageSettings.Provider value={{
			pageHeader,
			pageSidebar,
			handleSetPageHeader,
			handleSetPageSidebar
		}}>
			<Layout style={{minHeight:"100vh", background:"#fff"}}>
				{pageSidebar && (<Sidebar />)}
				<Layout style={{background:"#fff"}}>
					{pageHeader && (<Header />)}
					<Content />
				</Layout>
			</Layout>
		</PageSettings.Provider>
	)
}

export default App;