import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { AuditsTable } from '../../components/auditsTable/AuditsTable';
import { useGetAuditsForTypes } from '../../features/audit/auditApi';
import { setBackButton, setPageTitle } from '../../features/header/headerSlice';

//constants
import { AUDIT_ENTITY } from '../../components/auditsTable/constants';
import { AUDIT_COMMENTS } from '../document/constant';

const HardwareAttachmentAudits = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data, isFetching, refetch } = useGetAuditsForTypes([AUDIT_ENTITY.ATTACHMENT]);
  const pageTitle = t('Audits.AuditHistoryFor', {name: t('Document.Title')});

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle, dispatch]);

  return <AuditsTable audits={(data || []).filter(i=>i.comment === AUDIT_COMMENTS.LINK_VEHICLE_MODEL_DOCUMENT || i.comment === AUDIT_COMMENTS.UNLINK_VEHICLE_MODEL_DOCUMENT)} isFetching={isFetching} />;
};

export default HardwareAttachmentAudits;