import React from 'react';
import { Modal } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import styles from './modal.module.scss';

export const confirmationModal = (
  title,
  content,
  confirmButtonText,
  cancelButtonText,
  onOk,
  buttonType,
  onCancel,
  className
) => {
  return Modal.warning({
    autoFocusButton: null,
    cancelText: cancelButtonText,
    centered: true,
    className: `${styles.modal} ${className ? className : ''}`,
    content: content,
    icon: (
      <InfoCircleFilled className={buttonType === 'delete' ? styles.dangerIcon : styles.warningIcon} />
    ),
    okButtonProps: {
      danger: buttonType === 'delete',
      style: {
        ...(buttonType === 'delete'
          ? { backgroundColor: 'rgba(218, 97, 97, 255)' }
          : { backgroundColor: 'rgba(134, 179, 65, 1)' }),
        marginInlineStart:0
      },
      type: 'primary'
    },
    okCancel: true,
    okText: confirmButtonText,
    onCancel: () => (onCancel ? onCancel() : Modal.destroyAll()),
    onOk: () => onOk(),
    title: title
  });
};
