import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Panel, PanelBody } from './../../components/panel/panel.js';
import { Table, Spin, Space, Select, Form, Button, Row, Col, Input } from 'antd';
import style from './ResourceListing.module.scss';
import { SapType } from './constant';
import { useDispatch } from 'react-redux';
import { setBackButton, setPageTitle } from '../../features/header/headerSlice';
import { useGetSapItemByIdQuery, useSaveSapItemMutation, useSapItemCategories, saveSap } from '../../features/vehicleModels/sapApi';
import { RouteKey } from '../../config/page-key.js';
import { executeSingleAction } from '../../utils/ApiResponseHandler.js';

const ResourceForm = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const { i18n, t } = useTranslation();
	const { data, isFetching } = useGetSapItemByIdQuery({ id: history.location.state.id, type: location.state.type }, { skip: history.location?.state?.id === undefined });
	const [selectedSapType, setSelectedSapType] = useState(location.state.type);
	const [selectedCategory, setSelectedCategory] = useState(location.state.category);
	const [isDirty, setIsDirty] = useState(false);
	const [saveSapItem, response] = useSaveSapItemMutation();
	const sapCategories = useSapItemCategories();
	const [form] = Form.useForm()

	useEffect(() => {
		if (data) {
			dispatch(setPageTitle(data.sapCode));
		}
		else {
			dispatch(setPageTitle(t("SapItem.NewSapItem")));
		}

		dispatch(setBackButton(true));

		return () => {
			dispatch(setPageTitle(""));
			dispatch(setBackButton(false));
		}
	}, [data, t]);

	const onFinish = (e) => {
		let payload = {
			...data, ...e, ...{
				identifier: "Sap" + selectedSapType,
				loadingMessage: t("Common.Saving"),
				successMessage: t("SapItem.SapSaved")
			}
		};

		executeSingleAction(payload, saveSapItem, dispatch, selectedSapType).then(()=>{
			if (history.length > 2){
				history.goBack();
			}
			else{
				history.push(RouteKey.SAP);
			}
		})
	};

	const onTypeChange = (data) => {
		setSelectedSapType(data);
	}

	useEffect(() => {
		let formData = {type:selectedSapType, categories:selectedCategory};
		form.setFieldsValue({...formData, ...data})
	}, [form, data])

	return (
		<Spin spinning={isFetching}>
			<Panel style={{ marginBottom: "15px" }}>
				<PanelBody>
					<Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={() => { setIsDirty(true) }}>
						<Row className={style.fullWidthRow}>
							<Col xs={{ span: 24 }} sm={{ span: 12 }}>
								<Form.Item
									label={t("SapItem.Code")}
									name="sapCode"
									className={style.inputLeft}
									rules={[{ required: true, message: t("ValidationErrors.SapCodeRequired") }]}
								>
									<Input />
								</Form.Item>
							</Col>
							<Col xs={{ span: 24 }} sm={{ span: 12 }}>
								<Form.Item
									label={t("SapItem.Type")}
									name="type"
									className={style.inputLeft}
									rules={[{ required: true, message: t("ValidationErrors.SapTypeRequired") }]}
								>
									<Select
									    value={selectedSapType}
										options={SapType}
										onChange={onTypeChange}
									/>
								</Form.Item>
							</Col>
							<Col xs={{ span: 24 }} sm={{ span: 12 }}>
								<Form.Item
									label={t("SapItem.Category")}
									name="categories"
									className={style.inputLeft}
									rules={[{ required: true, message: t("ValidationErrors.SapCategoryRequired") }]}
								>
									<Select
									    mode="multiple"
										options={sapCategories}
										onChange={setSelectedCategory}
									/>
								</Form.Item>
							</Col>
							<Col xs={{ span: 24 }}>
								<Form.Item
									label={t("SapItem.Description")}
									name="name"
									className={style.inputLeft}
									rules={[{ required: true, message: t("ValidationErrors.SapDescriptionRequired") }]}
								>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<div className={"formFooter"}>
							<Space>
								<Button
									type="primary"
									size="large"
									htmlType="submit"
									disabled={!isDirty}
								>
									{t('Common.SaveButton')}
								</Button>
								<Button size="large" onClick={history.goBack}>
									{t('Common.CancelButton')}
								</Button>
							</Space>
						</div>
					</Form>
				</PanelBody>
			</Panel>
		</Spin>
	)
}

export default ResourceForm;