import React from 'react';
import { Button } from 'antd';
import { useMediaQuery } from 'react-responsive'

export const ResponsiveButton = ({title, icon, ...props}) => {
  const isMobile =useMediaQuery({ maxWidth: 767 })

  return (
    <Button {...props}>
      {!isMobile && title}
      {icon}
    </Button>
  );
}