import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, FilePdfOutlined, PlusOutlined, MinusCircleOutlined, UploadOutlined, FileOutlined, CarOutlined } from '@ant-design/icons';
import { Button, Row, Col, Form, Select, Space, Tabs, Typography, Upload, Alert, Spin, Input } from 'antd';
import { useCurrentCompany } from '../../features/company/companySlice.js';
import { ResponsiveModal } from '../../features/responsive';
import { useDispatch } from 'react-redux';
import style from './DocumentListing.module.scss';
import { getFileSize } from '../../utils/files.js';
import {
  useGetVehicleModelsQuery
} from '../../features/vehicleModels/vehicleModelApi';
import {
  executeSaveDocument,
  useSaveDocumentMutation,
  useDeleteDocumentMutation
} from '../../features/document/documentApi.js';
import { ENTITY_TYPE_VEHICLE_MODEL } from "./constant.js"
import { useCan } from '../../features/user/Can';
import { Permission } from '../../features/user/Permission.js';

const { Text } = Typography;

const DocumentUploadModal = ({ document, entityType, isModalOpen, onClose }) => {
  const { Dragger } = Upload;
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const currentCompany = useCurrentCompany();
  const [form] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [selectedYearValue, setSelectedYearValue] = useState([]);
  const [selectedSourceValue, setSelectedSourceValue] = useState([]);
  const [selectedModelValue, setSelectedModelValue] = useState([]);
  const [selectedMakeValue, setSelectedMakeValue] = useState([]);
  const [selectedVehicleModels, setSelectedVehicleModels] = useState([]);
  const [saveDocument] = useSaveDocumentMutation();
  const [deleteDocument] = useDeleteDocumentMutation();
  const [isDirty, setIsDirty] = useState(false);
  const [isZipFile, setIsZipFile] = useState(false);
  const can = useCan();
  const documentUrl = Form.useWatch('documentUrl', form);

  const isFileValid = (selectedFile.find(i=> !i?.name?.endsWith(".pdf") && !i?.name?.endsWith(".zip"))) === undefined;
  const { data: vehicleModels, isFetching } = useGetVehicleModelsQuery({ companyId: currentCompany.id }, {pollingInterval: 1800000, skip:currentCompany?.id === undefined});

  const renderVehicleModelPanel = () => {
    let groupedData = selectedVehicleModels.reduce((result, item) => {
      const { make } = item;

      const key = `${make}`;
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);
      return result;
    }, [selectedVehicleModels]);

    return (
      <Space direction={"vertical"} className={style.MMYPanel}>
        <Form.Item name="make" style={{ margin: 0 }}>
          <Select
            showSearch
            allowClear
            disabled={isZipFile}
            mode={"multiple"}
            onChange={handleMakeValueSelect}
            maxTagCount={"responsive"}
            style={{ width: "100%" }}
            loading={isFetching}
            placeholder={t("VehicleModel.Make")}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={makeValues}
          />
        </Form.Item>
        <Form.Item name="model" style={{ margin: 0 }}>
          <Select
            showSearch
            style={{ width: "100%" }}
            allowClear
            disabled={isZipFile}
            mode={"multiple"}
            onChange={handleModelValueSelect}
            loading={isFetching}
            maxTagCount={"responsive"}
            placeholder={t("VehicleModel.Model")}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={modelValues}
          />
        </Form.Item>
        <Form.Item name="year" style={{ margin: 0 }}>
          <Select
            showSearch
            allowClear
            disabled={isZipFile}
            mode={"multiple"}
            onChange={handleYearValueSelect}
            style={{ width: "100%" }}
            maxTagCount={"responsive"}
            loading={isFetching}
            placeholder={t("VehicleModel.Year")}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={yearValues}
          />
        </Form.Item>
        <Form.Item name="source" style={{ margin: 0 }}>
          <Select
            showSearch
            allowClear
            disabled={isZipFile}
            mode={"multiple"}
            onChange={handleSourceValueSelect}
            style={{ width: "100%" }}
            maxTagCount={"responsive"}
            loading={isFetching}
            placeholder={t("VehicleModel.Source")}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={sourceValues}
          />
        </Form.Item>

        <Form.List
          name="vehicleModelIds"
          rules={[
            {
              validator: async (_, names) => {
                if ((!selectedVehicleModels || selectedVehicleModels.length === 0) && !isZipFile) {
                  return Promise.reject(new Error(t('ValidationErrors.VehicleModelRequired')));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                <Form.Item className={"mb-2"}>
                  <Button
                    type="dashed"
                    disabled={(!selectedMakeValue || 
                      !selectedModelValue || 
                      !selectedYearValue || 
                      !selectedSourceValue ||
                      selectedMakeValue.length ==0 || 
                      selectedModelValue.length ==0 || 
                      selectedYearValue.length ==0 || 
                      selectedSourceValue.length ==0)}
                    onClick={() => {
                      addVehicleModel(add);
                    }}
                    style={{ width: '100%' }}
                    icon={<PlusOutlined />}
                  >
                    {t("Document.DocumentSetupModal.AddVehicle")}
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
                {Object.keys(groupedData).slice(1).map((key) => {
                  return (
                    <Form.Item className="mb-2"
                      key={key}
                    >
                      <Row className={style.vehicleModelListingRow}>
                        <Col xs={{ span: 20 }}>
                          <Space>
                            <CarOutlined />
                            <Text className={style.selectedMMYText}>{Array.from(new Set(groupedData[key].map(v => v.make))).join(' ')}</Text>/
                            <Text className={style.selectedMMYText}>{Array.from(new Set(groupedData[key].map(v => v.model))).join(' ')}</Text>/
                            <Text className={style.selectedMMYText}>{Array.from(new Set(groupedData[key].map(v => v.yearOfManufacture))).join(' ')}</Text>/
                            <Text className={style.selectedMMYText}>{Array.from(new Set(groupedData[key].map(v => v.source))).join(' ')}</Text>
                          </Space>
                        </Col>
                        <Col xs={{ span: 4 }} className={style.dynamicVehicleModelField}>
                          {!isZipFile && <MinusCircleOutlined
                            onClick={() => {
                              let newSelectedVehicleModels = [...selectedVehicleModels];
                              newSelectedVehicleModels = newSelectedVehicleModels.filter(i => groupedData[key].find(k => k.id === i.id) === undefined);
                              setSelectedVehicleModels(newSelectedVehicleModels);
    
                              form.setFieldValue('vehicleModelIds', newSelectedVehicleModels.map(i=>i.id));
                            }}
                          />}
                        </Col>
                      </Row>
                    </Form.Item>
                  )
                })}
              </>
            )
          }}
        </Form.List>
      </Space>
    );
  }

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedYearValue([]);
      setSelectedMakeValue([]);
      setSelectedModelValue([]);
      setSelectedSourceValue([]);
      setFileList([]);
      setSelectedVehicleModels([]);
      setSelectedFile([]);
      form.resetFields();
      setIsDirty(false);
      setIsZipFile(false);
    }
    else if (document) {
      setSelectedVehicleModels(document.data.map(i => { return { ...vehicleModels.find(k => k.id === i.entityId), ...{ entityType: i.entityType } } }))

      if (document.mimeType) {
        setSelectedFile([{ name: document.name }]);
        setIsZipFile(document.name.endsWith(".zip"));
        form.setFieldValue('fileName', { name: document.name });
        form.setFieldValue('documentUrl',  document.documentUrl); 
      }
      else {
        form.setFieldValue('documentUrl',  document.url); 
      }
      
      setIsDirty(false);
    }
  }, [isModalOpen, document]);

  const makeValues = useMemo(() => {
    const makeList = (vehicleModels || []).map(i => i.make);
    return Array.from(new Set(makeList)).map(data => { return { label: data, value: data } });
  }, [vehicleModels]);

  const modelValues = useMemo(() => {
    const modelListWithDuplicates = (vehicleModels || []).reduce((accumulator, makeModelYear) => {
			if ((selectedMakeValue || []).includes(makeModelYear.make)) {
				accumulator.push(makeModelYear.model);
			}
			return accumulator;
		}, []);

		const modelList = modelListWithDuplicates.reduce((accumulator, model, index) => {
			if (modelListWithDuplicates.indexOf(model) === index) {
				accumulator.push({ label: model, value: model });
			}

			return accumulator.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
		}, []);
		return modelList;
  }, [vehicleModels, selectedMakeValue]);

  const yearValues = useMemo(() => {
    const yearListWithDuplicates = (vehicleModels || []).reduce((accumulator, makeModelYear) => {
			if ((selectedMakeValue || []).includes(makeModelYear.make) && (selectedModelValue || []).includes(makeModelYear.model) && makeModelYear.yearOfManufacture) {
				accumulator.push(makeModelYear.yearOfManufacture);
			}
			return accumulator;
		}, []);
    
		const yearList = yearListWithDuplicates.reduce((accumulator, yearOfManufacture, index) => {
			if (yearListWithDuplicates.indexOf(yearOfManufacture) === index) {
				accumulator.push({ label: yearOfManufacture?.toString(), value: yearOfManufacture });
			}

			return accumulator.sort((a, b) => (a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1));
		}, []);
		return yearList;
  }, [vehicleModels, selectedMakeValue, selectedModelValue]);

  const sourceValues = useMemo(() => {
    return (vehicleModels || []).reduce((accumulator, makeModelYear) => {
      if (
        selectedMakeValue.includes(makeModelYear.make) &&
        selectedModelValue.includes(makeModelYear.model) &&
        selectedYearValue.includes(parseInt(makeModelYear.yearOfManufacture, 0))
      ) {
        const source = makeModelYear.displaySource
          ? makeModelYear.displaySource
          : makeModelYear.source;
        if (accumulator.find(i => i.value === source) === undefined) {
          accumulator.push({
            label: source || 'Unknown',
            value: source
          });
        }
      }
      return accumulator;
    }, []);
  }, [selectedMakeValue, selectedModelValue, selectedYearValue, vehicleModels]);

  const onFinish = (e) => {
    const parsedPayload = {
      ids: selectedVehicleModels.map(data => data.id),
      entityType: entityType
    }

    if (document && fileList.length === 0) {
      parsedPayload["attachmentId"] = document.attachmentId;
    }
    else if (fileList.length > 0) {
      parsedPayload["file"] = fileList;
    }

    parsedPayload["documentUrl"] = e.documentUrl;

    let deletePayload;

    if (document) {
      deletePayload = {
        attachmentId: document.attachmentId,
        ids: document.data.map(i => i.entityId),
        entityType:entityType
      }
    }

    setIsSubmitting(true);
    executeSaveDocument(parsedPayload, deleteDocument, saveDocument, deletePayload, dispatch)
      .then(onClose).finally(() => { setIsSubmitting(false) });
  }

  const handleMakeValueSelect = value => {
    setSelectedMakeValue(value);
    if (value.length === 0) {
      setSelectedModelValue([]);
      setSelectedYearValue([]);
      setSelectedSourceValue([]);
    }
  };

  const handleModelValueSelect = value => {
    setSelectedModelValue(value);
    if (value.length === 0) {
      setSelectedYearValue([]);
      setSelectedSourceValue([]);
    }
  };

  const handleYearValueSelect = value => {
    setSelectedYearValue(value);
    if (value.length === 0) {
      setSelectedSourceValue([]);
    }
  };

  const handleSourceValueSelect = value => {
    setSelectedSourceValue(value);
  };

  const addVehicleModel = (add) => {
    const combinedArray = selectedMakeValue.map((make) => {
      return selectedModelValue.map((model) => {
        return selectedYearValue.map((year) => {
          return selectedSourceValue.map((source) => {
            return { make, model, year, source };
          });
        });
      });
    }).flat().flat().flat();
    
    let matchedVehicleModelIds = [];
    combinedArray.flat().forEach(data=>{
      const matchedVehicleModel = vehicleModels.filter(i=>i.make === data.make && 
        i.model === data.model && i.yearOfManufacture === data.year && 
        (i.source === data.source || i.displaySource === data.source));

      if (matchedVehicleModel.length !== 0) {
        matchedVehicleModelIds = [...matchedVehicleModelIds, ...matchedVehicleModel.map(i=>i.id)];
      }
    })

    const matchedVehicleModels = vehicleModels.filter(i => matchedVehicleModelIds.includes(i.id));

    let clonedVehicleModels = JSON.parse(JSON.stringify(matchedVehicleModels));
    clonedVehicleModels.map(data => {
      data.entityType = entityType;
      add(data.id);
    });

    setSelectedVehicleModels([...selectedVehicleModels, ...clonedVehicleModels]);
    setSelectedMakeValue([]);
    setSelectedModelValue([]);
    setSelectedYearValue([]);
    setSelectedSourceValue([]);

    form.setFieldValue('make', []);
    form.setFieldValue('model', []);
    form.setFieldValue('year', []);
    form.setFieldValue('source', []);
  }

  return (
    <ResponsiveModal
      onClose={onClose}
      isModalOpen={isModalOpen}
      title={t("Document.DocumentSetupModal.LoadSupportDocument")}
      style={{ minWidth: 700 }}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={() => setIsDirty(true)}>
        <Row className={style.fullWidthRow}>
          <Col xs={{ span: 24 }}>

            <Form.Item style={{ marginBottom: 5 }}>
              <Text>{t("Document.DocumentSetupModal.VehicleMakeModelYear")}</Text>
              <Text type="danger"> *</Text>
            </Form.Item>
            {entityType === ENTITY_TYPE_VEHICLE_MODEL ?
              renderVehicleModelPanel() :
              <div className={style.fullWidthRow} style={{ margin: "10px 0px" }}>
                <Alert message={t("Common.ComingSoon")} type="warning" style={{ height: 100, textAlign: "center" }} />
              </div>
            }
            <Space direction={"vertical"} className={style.fullWidthRow}>
              <Form.Item
                dependencies={["fileName"]}
                name="documentUrl"
                label={t("Document.DocumentLink")}
                style={{ margin: 0 }}
                rules={[{ required: selectedFile.length === 0 , message: t("ValidationErrors.DocumentRequired") }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name="fileName"
                dependencies={["documentUrl"]}
                label={t("Document.Document")}
                style={{ margin: 0 }}
                rules={[{ required: !documentUrl, message: t("ValidationErrors.DocumentRequired") }]}>

                <Dragger
                  multiple={document === undefined && can({ everyPermission: Permission.IMPORT })}
                  showRemoveIcon={true}
                  className={style.uploadDragger}
                  accept={".pdf,.zip"}
                  beforeUpload={(incomingFile, incomingFileLists) => {
                    if (incomingFileLists.find(i=>!i.name.endsWith(".zip")) !== undefined) {
                      const fileToUse = incomingFileLists.filter(i=>!i.name.endsWith(".zip"))[0];
                      form.setFieldValue('fileName', fileToUse)
  
                      setFileList([fileToUse]);
                      setSelectedFile([fileToUse]);
                      setIsZipFile(fileToUse.name.endsWith(".zip"));
                    }
                    else{
                      let newFileList =[...incomingFileLists];
                      
                      form.setFieldValue('fileName', newFileList);
                      setFileList(newFileList);
                      setSelectedFile(newFileList);
                      setIsZipFile(newFileList.find(i=>!i.name.endsWith(".zip")) === undefined);
                    }

                    return false;
                  }}
                  height={'fit-content'}
                  showUploadList={false}
                >
                  <p className="ant-upload-text">
                    {selectedFile.length !== 0 ? (
                      <div style={{maxHeight:"250px", overflow:"auto"}}>
                        {selectedFile.map(file=>{
                          return (
                            <div className={style.centerPanel}>
                              <Space style={{ flex: "1", justifyContent: "space-between", padding: "5px 10px", alignItems: "center" }}>
                                <div className={style.centerPanel} style={{ flex: "0.8" }}>
                                  <FileOutlined style={{ marginRight: 15, fontSize: 30 }} />
                                  <span className={style.centerPanel} style={{ flexDirection: "column" }}>
                                    <Text type="secondary" className={style.fullWidthRow} style={{ textAlign: "left" }}>{file.name}</Text>
                                    {file.size && <Text type="secondary" className={style.fullWidthRow} style={{ textAlign: "left" }}>{getFileSize(file)}</Text>}
                                  </span>
                                </div>
                                <div className={style.centerPanel} style={{ flex: "0.2" }}>
                                  <CheckCircleOutlined style={{ color: "#86B341", fontSize: 30 }} />
                                </div>
                              </Space>
                            </div>
                          )
                        })}
                      </div>
                    ) : (
                      <div className={style.centerPanel}>
                        <div style={{ flex: "1" }}>
                          {t('Document.DocumentSetupModal.UploadDocument')}&nbsp;
                          <UploadOutlined />
                        </div>
                      </div>
                    )}
                  </p>
                </Dragger>
                {selectedFile && !isFileValid && <Text type="danger">{t("ValidationErrors.InvalidPdfOrZipDocument")}</Text>}
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Row className={style.uploadModalFooter}>
          <Col xs={{ span: 24 }}>
            <Space>
              <Button
                loading={isSubmitting}
                disabled={isSubmitting || !isDirty || !isFileValid}
                htmlType="submit"
                type="primary"
              >
                {t('Common.SaveButton')}
              </Button>
              <Button onClick={onClose}>
                {t('Common.CancelButton')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </ResponsiveModal>
  )
}

export default DocumentUploadModal;