import React from 'react';
import App from './App.js';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import Store from "./store";
import ReactDOM from "react-dom/client";
// css
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'flag-icon-css/css/flag-icon.min.css';
import './assets/css/app.css';

// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
