import i18n from 'i18next';
import moment from 'moment';
import { Buttons} from '../../components/control/Buttons'
import { RouteKey } from '../../config/page-key';
import { Table } from 'antd';
import { Can } from "../../features/user/Can.js"
import { Permission } from '../../features/user/Permission';

export const getExportOptionButtons = ({
	handleExportExcel,
	handleExportCSV,
	isLoading = false
}) => {
	return [
		{
			name: i18n.t('Common.ExportToCSV'),
			onClick: handleExportCSV,
			disabled:isLoading,
			value: 'btn_exportCsv'
		},
		{
			name: i18n.t('Common.ExportToExcel'),
			onClick: handleExportExcel,
			disabled:isLoading,
			value: 'btn_exportExcel'
		}
	];
};

export const getSapItemListingColumns = (onDeleteClick) => [
	{
		title: i18n.t('SapItem.Code'),
		sorter: (a, b) => a.sapCode.localeCompare(b.sapCode),
		width: '15%',
		render: (e, r) => <div>{r.sapCode}</div>
	},
	{
		title: i18n.t('SapItem.Category'),
		width: '15%',
		render: (e, r) => {
			return (
				<>
					{
						(r.categories || []).map((data, index) => {
							return (<div>{data}</div>)
						})
					}
				</>
			);
		}
	},
	{
		title: i18n.t('SapItem.Description'),
		sorter: (a, b) => a.name.localeCompare(b.name),
		width: '55%',
		render: (e, r) => <div>{r.name}</div>
	},
	{
		width: '5%',
		render: (e, r) => <Buttons
			moreButtons={[{
				name: i18n.t('Common.Edit'),
				link: RouteKey.SAP_FORM,
				state: { id: r.id, type: r.type, action: "EDIT" }
			},
			{
				name: i18n.t('Common.Delete'),
				danger: true,
				permission: [Permission.COMMON_EDIT],
				onClick: () => { if (onDeleteClick) onDeleteClick(r) }
			}]}
		/>
	}
];

export const getSapItemDataColumns = () => [
	
	{
		title: i18n.t('SapItem.Code'),
		width: '20%',
		render: (e, r) => <div>{r.sapCode}</div>
	},
	{
		title: i18n.t('SapItem.Quantity'),
		width: '15%',
		render: (e, r) => <div>{r.quantity || "-"}</div>,
		responsive: ['md']
	},
	{
		title: i18n.t('SapItem.Description'),
		width: '40%',
		render: (e, r) => <div>{r.name}</div>
	},
	{
		title: i18n.t('SapItem.Category'),
		width: '20%',
		render: (e, r) => {
			return (
				<div>
					{
						(r.categories || []).map((data, index) => {
							return (<div>{data}</div>)
						})
					}
				</div>
			);
		}
	},
	{
		render: (e, r) => {}
	},
	Table.EXPAND_COLUMN
];

export const getSapChildItemDataColumns = (onDelete, onEdit) => [
	
	{
		title: i18n.t('SapItem.Code'),
		width: '20%',
		render: (e, r) => <div style={{paddingLeft:15}}>{r.sapCode}</div>
	},
	{
		title: i18n.t('SapItem.Quantity'),
		width: '15%',
		render: (e, r) => <div>{r.quantity || "-"}</div>,
		responsive: ['md']
	},
	{
		title: i18n.t('SapItem.Description'),
		width: '40%',
		render: (e, r) => <div>{r.name}</div>
	},
	{
		title: i18n.t('SapItem.Category'),
		width: '20%',
		render: (e, r) => {
			return (
				<div>
					{
						(r.categories || []).map((data, index) => {
							return (<div>{data}</div>)
						})
					}
				</div>
			);
		},
	},
	(onDelete || onEdit) && {
		align:"right",
		render: (e, r) => <Can requiredPermission={[Permission.COMMON_EDIT]}>
			<Buttons
				noMargin={true}
				moreButtons={[
					{
						name: i18n.t('Common.Edit'),
						onClick: () => {
							if (onEdit)
								onEdit(r)
						}
					},
					{
						name: i18n.t('Common.Delete'),
						onClick: () => {
							if (onDelete)
								onDelete(r)
						},
						permission: [Permission.COMMON_EDIT],
						danger: true
					}]}
			/>
		</Can>
	}
];

export const getExportFilename = ({ entityName, extension }) => {
	if (!entityName) {
		return;
	}
	const timestamp = moment()
		.local()
		.format('YYMMDD_HH:mm');
	return `${entityName}_${timestamp}${extension}`;
};