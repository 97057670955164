import React, { useState, useMemo,useEffect } from 'react';
import { LANGUAGE_LOCALES } from '../../../features/localization/languages';
import { StorageKey } from '../../../features/localStorage/storageKey';
import i18n from '../../../i18nextConfig.js';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Menu, Space, Dropdown } from 'antd';
import { useGetRegionsQuery } from '../../../features/regions/regionsApi';
import { CaretDownOutlined } from '@ant-design/icons';
import headerStyles from './dropdown.module.scss';
import { useUserInfo, useUserPreference } from '../../../features/user/userApi';

const DropdownLanguage = () => {
	const { t } = useTranslation();
	const data = useUserInfo();
	const { data: regions } = useGetRegionsQuery(undefined, {skip:data.id === undefined});
	const userPreferences = useUserPreference();

	useEffect(() => {
	  if (userPreferences?.language) {
		localStorage.setItem(StorageKey.LOCALE, userPreferences?.language)
		i18n.changeLanguage(userPreferences?.language.split('-')[0].toLowerCase());
	  }
	}, [userPreferences?.language]);

	const changeLanguage = (value) => {
		localStorage.setItem(StorageKey.LOCALE, value)
		i18n.changeLanguage(value.split('-')[0].toLowerCase());
	}

	const languageOptions = useMemo(() => {
		const options = [];
		(regions || []).forEach(region => {
			let locales_available = [];
			try {
				locales_available = JSON.parse(region.config).locales_available;
			} catch (error) {
				console.error(error);
			}
			if (locales_available?.length) {
				locales_available.forEach(locale => {
					// do not allow locale duplicates
					if (!options.find(opt => opt.key === locale)) {
						options.push({
							key: locale,
							label: t(`Preferences.ChangeLanguage.${locale}`)
						});
					}
				});
			}
		});

		// fallback to en-AU if no language is available in regions -> locales_available
		if (!options.length) {
			options.push({
				key: LANGUAGE_LOCALES.EN_AU,
				label: t(`Preferences.ChangeLanguage.${LANGUAGE_LOCALES.EN_AU}`)
			});
		}

		return options;
	}, [regions, localStorage.getItem(StorageKey.LOCALE)]);

	const renderLanguageOption = () => {
		return (
			<Menu>
				{
					languageOptions.map((data, index) => {
						return (
							<Menu.Item key={index} onClick={() => { changeLanguage(data.key) }}>{data.label}</Menu.Item>
						)
					})
				}
			</Menu>
		)
	}

	return (
		<Dropdown placement="bottomRight" overlay={renderLanguageOption()} trigger={['click']}>
			<Link onClick={(e) => e.preventDefault()} className={headerStyles.ClickableDiv} style={{ "display": "none" }}>
				<Space size={0} className={headerStyles.ClickableDiv}>
					<span className="name d-sm-inline">{languageOptions.find(i => i.key == localStorage.getItem(StorageKey.LOCALE))?.label}</span>
					<CaretDownOutlined className={headerStyles.DownOutlined} />
				</Space>
			</Link>
		</Dropdown>
	);
};

export default DropdownLanguage;
