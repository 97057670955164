import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_PATH } from '../../config';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const CompanyApi = createApi({
  reducerPath: "CompanyApi",
  baseQuery: fetchBaseQuery({ 
    baseUrl: API_PATH, 
    prepareHeaders: (headers, { getState, endpoint }) => {
      const { user } = getState();
      headers.set('Authorization', `Token token="${user?.current?.auth?.key}"`)
      return headers
  },
   }),
  tagTypes: ['company'],
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: (arg) => {
        const params = {
          pruning: 'ALL',
          key: arg.key
        };

        return {
          url : `/companies`,
          params
        };
      },
      providesTags: ['company'],
    })
  })
})

export const { 
  useGetCompaniesQuery
} = CompanyApi

export const useCompanies = () => {
  const userKey = useSelector(state => state?.user?.current?.auth?.key);
  const { data } = useGetCompaniesQuery({userKey});

  return useMemo(() => {
    return [...(data||[]), ...[{id:-1, name:"Global"}]];
  }, [data]);
};