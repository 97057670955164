import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useUserInfo } from '../user/userApi';
import { useCompanies } from './companyApi';

export const company = {
  current: undefined,
};

const companySlice = createSlice({
  name: 'companies',
  initialState: company,
  reducers: {
    setCurrent(state, action) {
      state.current = action.payload;
    }
  }
});

export const {
  setCurrent
} = companySlice.actions;

export default companySlice.reducer;

export const useCurrentCompany = () =>{
  const data = useUserInfo();
  const companies = useCompanies();
  const currentCompany = useSelector(state => state.companies.current);

  if (currentCompany === undefined && data && companies){
    return companies.find(company => company.id === parseInt(data.companyId)) || {}
  }
  else{
    return currentCompany || {};
  }
}