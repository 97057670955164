import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { AuditsTable } from '../../components/auditsTable/AuditsTable';
import { useGetAuditsForTypes } from '../../features/audit/auditApi';
import { setBackButton, setPageTitle } from '../../features/header/headerSlice';

//constants
import { AUDIT_ENTITY } from '../../components/auditsTable/constants';

const AvailableKitAudits = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data, isFetching, refetch } = useGetAuditsForTypes([AUDIT_ENTITY.ATTACHMENT]);
  const pageTitle = t('Audits.AuditHistoryFor', {name: t('Document.Title')});

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle, dispatch]);

  return <AuditsTable audits={data} isFetching={isFetching} />;
};

export default AvailableKitAudits;