import { API_PATH } from "../../config";
import { FeatureFlag } from "./Permission";
import { useUserInfo } from "./userApi";

export const Can = ({
  children,
  requiredPermission, 
  oneOfPermission, 
  oneOfRole,
  featureFlag
}) => {
  const can = useCan();

  const renderer = can({everyPermission:requiredPermission, 
    oneOfPermission:oneOfPermission,
    oneOfRole : oneOfRole, 
    featureFlag : featureFlag})
    ? children
    : null;

  return renderer ? renderer : null;
};

export const useCan = () => {
  const user = useUserInfo();
  const permission = user.permission;

  return ({
    everyPermission,
    oneOfPermission,
    oneOfRole,
    otherConditions,
    featureFlag
  }) => {
    let canChecks = [];
    const checkEveryPermission = canEveryPermission(everyPermission, permission);
    const checkOneOfRoles = canOneOfRoles(oneOfRole, user);
    const checkFeatureFlag = canFeatureFlag(featureFlag);
    const checkOneOfPermissions = canOneOfEntities(oneOfPermission, permission);

    if (oneOfPermission) {
      canChecks.push(checkOneOfPermissions);
    }

    if (everyPermission) {
      canChecks.push(checkEveryPermission);
    }

    if (oneOfRole) {
      canChecks.push(checkOneOfRoles);
    }

    if (otherConditions) {
      canChecks.push(otherConditions.every(f => f()));
    }

    if (featureFlag) {
      canChecks.push(checkFeatureFlag);
    }

    return canChecks.every(check => check);
  };
};

const canEveryPermission = (expectedPermissions, userPermissions) => {
  if (Array.isArray(expectedPermissions)) {
    return expectedPermissions.every(eP => userPermissions.includes(eP));
  }
  return userPermissions.includes(expectedPermissions);
};

export const canOneOfEntities = (expectedPermissions, userPermissions) => {
  if (Array.isArray(expectedPermissions)) {
    return expectedPermissions.some(eP => userPermissions.includes(eP));
  }
  return userPermissions.includes(expectedPermissions);
};

const canOneOfRoles = (expectedRoles, user) => {
  if (
    !expectedRoles ||
    !user?.roles ||
    !(expectedRoles.some(r => user.roles.some(ur => ur.global && ur.name === r)) || user.siteAdmin)
  ) {
    return false;
  }
  return true;
};

const canFeatureFlag = (flag) => {
  if (!flag) {
    return true;
  }

  const featureFlag = flag.featureFlag
    ? FeatureFlag[flag.featureFlag]
    : Object.values(FeatureFlag).find(feature => feature.path && feature.path === flag?.path);

  // unflagged feature, no active checks
  if (!featureFlag) {
    return true;
  }

  let checkAllowEnvs = true;

  // verify in allowed envs
  if (featureFlag?.allow && API_PATH) {
    checkAllowEnvs = featureFlag.allow.includes(API_PATH);
  }

  return checkAllowEnvs;
};