import React, {useState} from 'react';
import SidebarNav from './sidebar-nav.js';
import SidebarProfile from './sidebar-profile.js';
import { Layout, Menu, theme } from 'antd';
import style from  './sidebar.module.scss'
import { useMediaQuery } from 'react-responsive'
const { Sider } = Layout;

const Sidebar = (props) => {
	const [show, setShow] = useState(false);
	const [isMainNavPinned, setIsMainNavPinned] = useState(true);
	const isMobile =useMediaQuery({ maxWidth: 767 })
	
	return (
		<Sider
			breakpoint="md"
			collapsedWidth={isMobile ? "0" :undefined}
			className={`${style.Sidebar} ${show ? style.SidebarContentMobileExpanded : null}`}
			onCollapse={(e) => {
				setShow(!e);
			}}
			collapsed={!show}
			onMouseEnter={e => {
				!show && setShow(true);
			}}
			onBlur={()=>{
				(!isMainNavPinned && !isMobile) && show && setShow(false);
			}}
			onMouseLeave={e => {
				(!isMainNavPinned && !isMobile) && show && setShow(false);
			}}
		>
			<SidebarProfile isMainNavExpanded={show} isMainNavPinned={isMainNavPinned} onChange={(e) => { setIsMainNavPinned(e) }} />
			<SidebarNav />
		</Sider>
	)
}

export default Sidebar;
