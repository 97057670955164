import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LANGUAGES } from './features/localization/languages';

import EN from './features/localization/translation/en.json';
import ES from './features/localization/translation/es.json';
import FR from './features/localization/translation/fr.json';
import DA from './features/localization/translation/da.json';

import {StorageKey} from './features/localStorage/storageKey';

const resources = {
  [LANGUAGES.EN]:{
    common:EN
  },
  [LANGUAGES.ES]:{
    common:ES
  },
  [LANGUAGES.FR]:{
    common:FR
  },
  [LANGUAGES.DA]:{
    common:DA
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      default: [LANGUAGES.EN]
    },
    defaultNS:"common",
    lng: localStorage.getItem(StorageKey.LOCALE, LANGUAGES.EN),
    debug: false,
    resources,
    supportedLngs: [...Object.values(LANGUAGES)],
    lowerCaseLng: false,
    cleanCode: true,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

export const i18nConfig = i18n;
