import { Loading } from '../../components/loading/Loading';
import {
  logout,
  useUser,
  useUserKey,
  remoteSignInSuccess,
  remoteSignInStart,
  remoteSignInFailed,
  REMOTE_SIGN_IN_ERROR,
  REMOTE_SIGN_IN_TIMEOUT
} from '../../features/user/ssoSlice';
import React, { useMemo } from 'react';
import { Redirect, useHistory } from 'react-router';
import { KEYCLOAK_URL, API_PATH, KEYCLOAK_CONFIG } from '../../config';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LANGUAGE_LOCALES } from '../../features/localization/languages';
import { StorageKey } from '../../features/localStorage/storageKey';
import { getPathFromAuthedRedirectUrl } from '../../utils/authedRedirectUrl';
import { RouteKey } from '../../config/page-key';
import moment from 'moment';
import { useLocation } from 'react-router';
const SSO_API_AUTH_URL = `${API_PATH}/auth/sso`;

export function SSO() {
  const user = useUser();
  const userKey = useUserKey();
  const dispatch = useDispatch();
  const keycloakConfig = useKCConfig();
  const query = new URLSearchParams(useLocation().search)

  const [loginError, setLoginError] = useState('');

  const handlers = useMemo(() => {
    return {
      onSSOStart: () => {
        dispatch(remoteSignInStart());
      },
      onInitError: e => {
        setLoginError(REMOTE_SIGN_IN_ERROR.KC_INIT_ERROR);
        dispatch(remoteSignInFailed(REMOTE_SIGN_IN_ERROR.KC_INIT_ERROR));
      },
      onAuthLogout: () => {
        //keycloak logout
        let redirectUrl = '/sso';
        if (keycloakConfig.realm) {
          redirectUrl += '?realm=' + keycloakConfig.realm;
        dispatch(logout());
      }
        dispatch(remoteSignInFailed());
        window.location.href = redirectUrl;
      },
      onAuthError: () => {
        dispatch(logout());
        setLoginError(REMOTE_SIGN_IN_ERROR.KC_AUTH_ERROR);
        dispatch(remoteSignInFailed(REMOTE_SIGN_IN_ERROR.KC_AUTH_ERROR));
      },
      onAPIKeyError: (isKeyEmpty = false, authResp = {}) => {
        const hasAttemptsReturned =
          authResp && authResp?.failedAttempts !== undefined && authResp?.maxAttempts !== undefined;

        const err = isKeyEmpty
          ? REMOTE_SIGN_IN_ERROR.API_KEY_EMPTY_ERROR
          : REMOTE_SIGN_IN_ERROR.API_KEY_ERROR;
        dispatch(remoteSignInFailed(err));
        if (window?.keycloak?.logout) {
          let redirectUrl = `${window.location.origin}/error`;

          if (hasAttemptsReturned) {
            const userBlocked = authResp.failedAttempts === authResp.maxAttempts;
            const userCompanyBlocked = authResp.maxAttempts === 0;

            if (userBlocked || userCompanyBlocked) {
              redirectUrl = RouteKey.SSO;

              if (keycloakConfig.realm) {
                redirectUrl += '?realm=' + keycloakConfig.realm;
              }

              redirectUrl = window.location.origin + redirectUrl;
            }
          }

          window.keycloak.logout({ redirectUri: redirectUrl }).catch(e => setLoginError(err));
        } else {
          setLoginError(err);
        }
      },
      onSSOSuccess: authObj => {
        const {role, ...props} = authObj;
        window.localStorage.setItem(StorageKey.USER_ACTION_TIME, moment().format('X'));
        dispatch(remoteSignInSuccess({...props}));
      }
    };
  }, [dispatch, keycloakConfig]);

  const history = useHistory();
  if (user && userKey) {
    return (
      <Redirect
        to={getPathFromAuthedRedirectUrl({
          urlSearch: history.location.search
        })}
      />
    );
  }
  
  if (!document.querySelector('#keycloakScript')) {
    const keycloakScriptNode = document.createElement('script');
    keycloakScriptNode.type = 'text/javascript';
    keycloakScriptNode.src = KEYCLOAK_URL;
    keycloakScriptNode.onload = () => {
      ssoAuth({
        keycloakConfig,
        ...handlers,
        realm: keycloakConfig.realm,
        logoutType: query.get('logoutType')
      });
    };
    keycloakScriptNode.onerror = () => {
      setLoginError(prev => prev + '\nAdapter Load Failed.');
      dispatch(remoteSignInFailed(REMOTE_SIGN_IN_ERROR.KC_ADAPTER_LOAD_ERROR));
    };
    keycloakScriptNode.id = 'keycloakScript';
    document.head.appendChild(keycloakScriptNode);
  } 
  
  return <PageLoader />;
}

const PageLoader = () => (
  <div
    style={{
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%'
    }}
  >
    <Loading />
  </div>
);

const useKCConfig = () => {
  const history = useHistory();
  return useMemo(() => {
    const search = history.location.search;
    const keycloakConfig = JSON.parse(KEYCLOAK_CONFIG);
    if (search.trim() !== '' && search.toLowerCase().indexOf('realm') >= 0) {
      const keySets = search.substring(1).split('&');
      const realm = keySets.find(k => k.toLowerCase().startsWith('realm'))?.split('=')[1];
      keycloakConfig.realm = realm;
    }
    return keycloakConfig;
  }, [history]);
};

const ssoAuth = async ({
  keycloakConfig,
  onSSOStart = () => {},
  onInitError = () => {},
  onAuthError = () => {},
  onAuthLogout = () => {},
  onAPIKeyError = () => {},
  onSSOSuccess = () => {},
  logoutType,
  realm
}) => {
  onSSOStart();
  window.keycloak = new window.Keycloak(keycloakConfig);
  window.keycloak.onAuthLogout = onAuthLogout;
  window.keycloak.onTokenExpired = () => {
    window.keycloak
      .updateToken(30)
      .catch(onAuthError);
  };
  const queryParams = new URLSearchParams(window.location.search);
  const from = queryParams.get('from');

  let ssoObj = {
    flow: 'standard',
    messageReceiveTimeout: REMOTE_SIGN_IN_TIMEOUT,
    redirectUri: `${window.location.origin}/sso?realm=${realm}` + (from ? `&&page=${from}` : ''),
    checkLoginIframe: false
  };

  if (queryParams.get("token") && queryParams.get("refresh_token")) {
    ssoObj.token = queryParams.get("token");
    ssoObj.refreshToken = queryParams.get("refresh_token");
  }

  return window.keycloak
    .init(ssoObj)
    .then(async authenticated => {
      if (authenticated && window.keycloak.token) {
        return getAPIKeyAuth({
          keycloakAuthToken: window.keycloak.token,
          onAPIKeyError,
          onAPIKeySuccess: authObj =>
            onSSOSuccess({
              ...authObj,
              auth: {
                ...authObj?.auth,
                keycloakConfig
              }
            })
        });
      } else {
        let lang = localStorage.getItem(StorageKey.LOCALE) || LANGUAGE_LOCALES.EN_AU;
        let userRealm = realm;

        if (userRealm === undefined || userRealm === '') {
          userRealm = keycloakConfig.realm;
        }

        if (logoutType) {
          let baseUrl = keycloakConfig['auth-server-url'];
          let url =
            baseUrl +
            'realms/' +
            userRealm +
            '/TN360/Logout?logoutType=' +
            logoutType +
            '&loginUrl=' +
            window.location.origin+
            '/sso' +
            '&kc_locale=' +
            lang;
          return window.location.replace(url);
        } else {
          const isLoginDisabled = await isKCLoginDisabled(
            window.keycloak.createLoginUrl({ locale: lang })
          );
          return isLoginDisabled ? onAuthError() : window.keycloak.login({ locale: lang });
        }
      }
    })
    .catch(onInitError);
};

const isKCLoginDisabled = async kcLoginUrl =>
  fetch(kcLoginUrl, { credentials: 'include' })
    .then(resp => resp.status === 400)
    .catch(e => false);

const getAPIKeyAuth = ({
  keycloakAuthToken,
  onAPIKeySuccess = authObj => {},
  onAPIKeyError = isKeyEmpty => {},
  retry = 3
}) => {
  return fetch(SSO_API_AUTH_URL, { headers: { Authorization: `Bearer ${keycloakAuthToken}` } })
    .then(async resp => {
      let isKeyEmpty = false;
      const authObj = await resp.json();
      if (resp.status === 200) {
        const userKey = authObj?.keys?.find(k => k.type === 'USER');
        if (userKey) {
          return onAPIKeySuccess({
            ...authObj,
            auth: {
              ...authObj?.auth,
              key: userKey.accessToken
            }
          });
        } else {
          isKeyEmpty = true;
        }
      }
      return retry > 0
        ? getAPIKeyAuth({ keycloakAuthToken, onAPIKeySuccess, onAPIKeyError, retry: retry - 1 })
        : onAPIKeyError(isKeyEmpty, authObj);
    })
    .catch(e => {
      console.error(e);
      return retry > 0
        ? getAPIKeyAuth({ keycloakAuthToken, onAPIKeySuccess, onAPIKeyError, retry: retry - 1 })
        : onAPIKeyError();
    });
};
