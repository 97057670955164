export const toTitleCase = (str, forceLowerCase = true) => {
  return str?.replace(/\w\S*/g, txt => {
    return (
      txt.charAt(0).toUpperCase() + (forceLowerCase ? txt.substr(1).toLowerCase() : txt.substr(1))
    );
  });
};

export const parseErrorMessage = error => {
  const message =
    error?.error ||
    error?.data ||
    error;

  if (!typeof message === 'string' || message === undefined) {
    return 'Unknown error';
  }

  return message?.replace('java.lang.Exception:', '');
};

export const isValidHttpUrl =(string) => {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}