import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageNumber:1,
  searchFilter: null
};

export const vehicleModelFilterSlice = createSlice({
  name: 'vehicleModelFilter',
  initialState,
  reducers: {
    setPageNumber(state, { payload }) {
      state.pageNumber = payload;
    },
    resetPageNumber(state) {
      state.pageNumber = 1;
    },
    setSearchFilters(state, { payload }) {
      state.searchFilter = payload;
    },
    resetSearchFilters(state) {
      state.searchFilter = null;
    }
  }
});

// Actions
export const { 
  setSearchFilters, 
  resetSearchFilters, 
  resetPageNumber, 
  setPageNumber 
} = vehicleModelFilterSlice.actions;

export default vehicleModelFilterSlice.reducer;
