import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import { StorageKey } from './features/localStorage/storageKey';
import ssoReducer, { logout, initialUser } from './features/user/ssoSlice';
import { RegionApi } from './features/regions/regionsApi.js';
import { VehicleModelApi } from './features/vehicleModels/vehicleModelApi';
import { SapApi } from './features/vehicleModels/sapApi';
import { UserApi } from './features/user/userApi';
import { DocumentApi } from './features/document/documentApi';
import { CompanyApi } from './features/company/companyApi';
import companyReducer from './features/company/companySlice';
import headerReducer from './features/header/headerSlice';
import toastReducer from './features/toast/toastsSlice';
import vehicleModelFilterReducer from './features/vehicleModels/vehicleModelFilterSlice';
import vehicleModelPollFilterReducer from './features/vehicleModels/vehicleModelPollFilterSlice';
import { AuditApi } from './features/audit/auditApi';

const loadState = () => {
  try {
    const localState = localStorage.getItem(StorageKey.LOGIN_STATE);
    const isLoggedOut = localStorage.getItem(StorageKey.LOGOUT_STATE);

    if (isLoggedOut) {
      return undefined;
    }

    if (localState === null) {
      const initUserObj = Object.assign({}, initialUser);
      return {
        user: initUserObj
      };
    }

    const state = JSON.parse(localState);
    return state;
  } catch (err) {
    return undefined;
  }
};

const appReducer = combineReducers({
  user: ssoReducer,
  companies:companyReducer,
  header: headerReducer,
  toast:toastReducer,
  vehicleModelFilter:vehicleModelFilterReducer,
  vehicleModelPollFilter:vehicleModelPollFilterReducer,
  [RegionApi.reducerPath]: RegionApi.reducer,
  [VehicleModelApi.reducerPath]: VehicleModelApi.reducer,
  [SapApi.reducerPath]: SapApi.reducer,
  [UserApi.reducerPath]:UserApi.reducer,
  [DocumentApi.reducerPath]:DocumentApi.reducer,
  [AuditApi.reducerPath]:AuditApi.reducer,
  [CompanyApi.reducerPath]:CompanyApi.reducer
});

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    state = undefined;
  }

  return appReducer(state, action);
};

const saveState = state => {
  try {
    const localState = JSON.stringify({
      ...state,
      user: { ...state.user }
    });
    localStorage.setItem(StorageKey.LOGIN_STATE, localState);
  } catch (err) {
    console.error(err);
  }
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadState(),
  middleware: [
    ... getDefaultMiddleware({ thunk: true, immutableCheck: false, serializableCheck: false })
    .concat(RegionApi.middleware)
    .concat(VehicleModelApi.middleware)
    .concat(SapApi.middleware)
    .concat(UserApi.middleware)
    .concat(DocumentApi.middleware)
    .concat(CompanyApi.middleware)
    .concat(AuditApi.middleware)
  ]
});

setupListeners(store.dispatch);

store.subscribe(() => {
  const { companies, user } = store.getState();
  saveState({ companies, user });
});

export default store;
