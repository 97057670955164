import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_PATH } from '../../config';

export const RegionApi = createApi({
  reducerPath:"RegionApi",
  baseQuery: fetchBaseQuery({ 
    baseUrl: API_PATH, 
    prepareHeaders: (headers, { getState, endpoint }) => {
      const { user } = getState();
      headers.set('Authorization', `Token token="${user?.current?.auth?.key}"`)
      return headers
  },
   }),
  tagTypes: [],
  endpoints: (builder) => ({
    getRegions: builder.query({
      query: () => `/regions`,
    }),
  }),
})

export const { useGetRegionsQuery } = RegionApi
