import React from 'react';
import styles from './Loading.module.scss';

export const Loading = props => <div className={styles.Loading} {...props}></div>;

export const LoadingCentered = props => (
  <div className={styles.LoadingCentered} {...props}>
    <Loading {...props} />
  </div>
);
