import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, UploadOutlined, FileOutlined } from '@ant-design/icons';
import { Button, Row, Col, Form, Select, Space, Tabs, Typography, Upload, Input } from 'antd';
import { useCurrentCompany } from '../../features/company/companySlice.js';
import { ResponsiveModal } from '../../features/responsive';
import { useDispatch } from 'react-redux';
import style from './DocumentListing.module.scss';
import {
  useGetDeviceModelsQuery
} from '../../features/vehicleModels/vehicleModelApi';
import {
  executeSaveDocument,
  useSaveDocumentMutation,
  useDeleteDocumentMutation
} from '../../features/document/documentApi.js';
import { AUDIT_COMMENTS, DOCUMENT_TYPE_DEVICE_MODEL } from './constant.js';
import { Permission } from '../../features/user/Permission.js';
import { getFileSize } from '../../utils/files.js';
import { useCan } from '../../features/user/Can.js';

const { Text } = Typography;

const DocumentLinkUploadModal = ({ document, entityType, isModalOpen, onClose }) => {
  const { Dragger } = Upload;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentCompany = useCurrentCompany();
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [saveDocument] = useSaveDocumentMutation();
  const [deleteDocument] = useDeleteDocumentMutation();
  const [isDirty, setIsDirty] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const can = useCan();
  const { data: allDeviceModels, isFetching: isFetchingDeviceModel } = useGetDeviceModelsQuery({ companyId: currentCompany.id, compatibleModelOnly : true }, { pollingInterval: 3600000, skip: currentCompany.id === undefined });
  const formValues = Form.useWatch([], form);
  const documentUrl = Form.useWatch('documentUrl', form);

  useEffect(() => {
    if (isModalOpen && Object.keys(formValues || {}).length !== 0) {
      form.validateFields({validateOnly : true})
        .then(() => setIsFormValid(true))
        .catch(() => setIsFormValid(false));
    }
  }, [form, formValues, isModalOpen]);

  useEffect(() => {
    if (!isModalOpen) {
      form.resetFields();
      setSelectedFile(undefined);
      setIsFileUploaded(false);
      setIsDirty(false);
    }
    else if (document) {
      form.setFieldValue('deviceModelId',  document.data[0].entityId);

      if (document.mimeType) {
        form.setFieldValue('fileName', { name: document.name });
        setSelectedFile({ name: document.name });
        form.setFieldValue('documentUrl',  document.documentUrl); 
      }
      else {
        form.setFieldValue('documentUrl',  document.url); 
      }
      setIsDirty(false);
    }
  }, [isModalOpen, document]);

  const onFinish = (e) => {
    let parsedPayload = {entityType: entityType};
    parsedPayload["ids"] = [e.deviceModelId];
    parsedPayload["documentUrl"] = e.documentUrl;
    parsedPayload["docType"] = DOCUMENT_TYPE_DEVICE_MODEL;
    parsedPayload["comment"] = AUDIT_COMMENTS.LINK_VEHICLE_MODEL_DOCUMENT;

    if (document && !isFileUploaded) {
      parsedPayload["attachmentId"] = document.attachmentId;
    }
    else if (selectedFile){
      parsedPayload["file"] = [selectedFile];
    }

    let deletePayload;
    if (document) {
      deletePayload = {
        attachmentId: document.attachmentId,
        id: document.deviceModelId,
        entityType:entityType,
			  comment: AUDIT_COMMENTS.UNLINK_VEHICLE_MODEL_DOCUMENT,
      }
    }

    setIsSubmitting(true);
    executeSaveDocument(parsedPayload, deleteDocument, saveDocument, deletePayload, dispatch)
      .then(onClose).finally(() => { setIsSubmitting(false) });
  }

  return (
    <ResponsiveModal
      onClose={onClose}
      isModalOpen={isModalOpen}
      title={t("Document.DocumentSetupModal.LoadSupportDocument")}
      style={{ minWidth: 700 }}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={() => setIsDirty(true)}>
        <Row className={style.fullWidthRow}>
          <Col xs={{ span: 24 }}>
            <Space direction={"vertical"} className={style.DeviceTypePanel}>
              <Form.Item style={{ marginBottom: 5 }}>
                <Text type="danger" strong>* </Text>
                <Text>{t("Document.DocumentSetupModal.DeviceModel")}</Text>
              </Form.Item>
              <Form.Item name="deviceModelId" style={{ margin: 0 }} rules={[{ required: true, message: t("ValidationErrors.DeviceModelRequired") }]}>
                <Select
                  showSearch
                  allowClear
                  style={{ width: "100%" }}
                  loading={isFetchingDeviceModel}
                  placeholder={t("Document.DocumentSetupModal.DeviceModel")}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={(allDeviceModels || []).map(i => {
                    return { label: i.name, value: i.id };
                  })}
                />
              </Form.Item>
              <Form.Item
                dependencies={["fileName"]}
                name="documentUrl"
                label={t("Document.DocumentLink")}
                style={{ margin: 0 }}
                rules={[{ required: !selectedFile , message: t("ValidationErrors.DocumentRequired") }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name="fileName"
                dependencies={["documentUrl"]}
                label={t("Document.Document")}
                style={{ margin: 0 }}
                rules={[{ required: !documentUrl, message: t("ValidationErrors.DocumentRequired") }]}>
                <Dragger
                  multiple={document === undefined && can({ everyPermission: Permission.IMPORT })}
                  showRemoveIcon={true}
                  className={style.uploadDragger}
                  height={'fit-content'}
                  accept={".pdf"}
                  showUploadList={false}
                  beforeUpload={(incomingFile, incomingFileLists) => {
                    setSelectedFile(incomingFile);
                    setIsFileUploaded(true);
                    form.setFieldValue('fileName', incomingFile)
                    return false;
                  }}
                >
                  <p className="ant-upload-text">
                    {selectedFile ? (
                      <div style={{ maxHeight: "250px", overflow: "auto" }}>
                        <div className={style.centerPanel}>
                          <Space style={{ flex: "1", justifyContent: "space-between", padding: "5px 10px", alignItems: "center" }}>
                            <div className={style.centerPanel} style={{ flex: "0.8" }}>
                              <FileOutlined style={{ marginRight: 15, fontSize: 30 }} />
                              <span className={style.centerPanel} style={{ flexDirection: "column" }}>
                                <Text type="secondary" className={style.fullWidthRow} style={{ textAlign: "left" }}>{selectedFile.name}</Text>
                                {selectedFile.size && <Text type="secondary" className={style.fullWidthRow} style={{ textAlign: "left" }}>{getFileSize(selectedFile)}</Text>}
                              </span>
                            </div>
                            <div className={style.centerPanel} style={{ flex: "0.2" }}>
                              <CheckCircleOutlined style={{ color: "#86B341", fontSize: 30 }} />
                            </div>
                          </Space>
                        </div>
                      </div>
                    ) : (
                      <div className={style.centerPanel}>
                        <div style={{ flex: "1" }}>
                          {t('Document.DocumentSetupModal.UploadDocument')}&nbsp;
                          <UploadOutlined />
                        </div>
                      </div>
                    )}
                  </p>
                </Dragger>
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Row className={style.uploadModalFooter}>
          <Col xs={{ span: 24 }}>
            <Space>
              <Button
                loading={isSubmitting}
                disabled={isSubmitting || !isDirty || !isFormValid}
                htmlType="submit"
                type="primary"
              >
                {t('Common.SaveButton')}
              </Button>
              <Button onClick={onClose}>
                {t('Common.CancelButton')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </ResponsiveModal>
  )
}

export default DocumentLinkUploadModal;