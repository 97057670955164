import { Space, Table } from 'antd';
import React from 'react';
import moment from 'moment';

//helpers
import { isString } from 'lodash';

//constants
import { AUDITS_ACTIONS, ColumnKeys } from './constants';

//styles
import styles from './AuditsTable.module.scss';

export const prepareAuditsColumnsForTable = t =>
  Columns.map(column => ({
    ...column,
    title: t(`Audits.${column.title}`)
  }));

const prepareColumnsForChangesTable = (rowId, t) =>
  ChangesColumns.map(column => ({
    ...column,
    key: column.key + rowId,
    title: t(`Audits.${column.title}`),
    render: (text, record, rowIndex) =>
      Array.isArray(text) ? (
        <Space direction="vertical" size="small">
          {text.map((item, index) => (
            <span key={`${record.rowKey}${rowIndex}-${index}`}>{item}</span>
          ))}
        </Space>
      ) : (
        <span className={styles.wrapText}>{text}</span>
      ),
    ...(column.key === ColumnKeys.KEY && {
      onCell: record => (record.isPartial ? { rowSpan: record.rowSpan } : {})
    })
  }));

export const prepareAuditsDataForTable = (
  data = [],
  t
) => {
  const dataForTable = [];
  data.forEach(audit => {
    const ret = {
      key: audit.id,
      [ColumnKeys.TIME]: audit[ColumnKeys.TIME],
      [ColumnKeys.ACTION]: t(`Audits.${audit[ColumnKeys.ACTION]}`),
      [ColumnKeys.USER]: audit[ColumnKeys.USER] ? audit[ColumnKeys.USER].name : '-',
      [ColumnKeys.COMMENT]: audit[ColumnKeys.COMMENT],
      [ColumnKeys.CHANGES]: renderChangesTable(
        audit[ColumnKeys.CHANGES],
        audit.id,
        t
      )
    };
    //get rid of UI-Blank audit rows
    const hasCommentEmpty = !!audit[ColumnKeys.COMMENT],
      hasNestedTableData = !!ret[ColumnKeys.CHANGES]?.props?.dataSource?.length;
    if (
      hasCommentEmpty ||
      hasNestedTableData ||
      AUDITS_ACTIONS.DELETE === audit[ColumnKeys.ACTION] ||
      AUDITS_ACTIONS.RESTORE === audit[ColumnKeys.ACTION]
    ) {
      dataForTable.push(ret);
    }
  });
  return dataForTable;
};

const renderTime = (time) => {
  if (!time) {
    return;
  }
  return <span>{moment(time).format("DD MMM YYYY HH:mm:ss")}</span>;
};

const renderUser = (user) => {
  return !!user && <div>{user.fullName}</div>;
};

const prepareDataForChangesColumn = (
  data,
  localization
) => {
  // Build an object with "key", "from" and "to" values
  // in order to render them in the inner table
  const changesDataSource = Object.keys(data)
    .reduce((collection, changeKey, changeKeyIndex) => {
      let keyChanges = data[changeKey] || [];
      try {
        keyChanges = isString(keyChanges) ? JSON.parse(keyChanges) : keyChanges;
      } catch (error) {
        keyChanges = data[changeKey] || [];
      }
      collection.push({
        rowKey: `${changeKey}-${changeKeyIndex}`,
        [ColumnKeys.KEY]: changeKey,
        [ColumnKeys.FROM]: renderChangeItem(data[changeKey], 0, localization),
        [ColumnKeys.TO]: renderChangeItem(data[changeKey], 1, localization, true)
      });
      return collection;
    }, []);
  return changesDataSource;
};
const getChangeItemStr = value => (typeof value === 'string' ? value : JSON.stringify(value));
const getEntityChange = entityChange => {
  if (entityChange?.hasOwnProperty('entity')) {
    //show name only for standard entity, otherwise get rid of entity
    if (entityChange?.hasOwnProperty('name') && entityChange?.hasOwnProperty('id')) {
      return `${entityChange?.name}${
        entityChange?.entityType ? `(${entityChange.entityType})` : ''
      }`;
    } else {
      delete entityChange?.entity;
      delete entityChange?.entityType;
      return getChangeItemStr(entityChange);
    }
  }
};

const renderChangeItem = (item, itemKey, localization, nonNull = false) => {
  let ret = '';
  if (Array.isArray(item)) {
    const toValue = item[itemKey];
    if (Array.isArray(toValue)) {
      ret = toValue.map(getEntityChange).filter(item => !!item);
      ret = ret.length ? ret : getChangeItemStr(toValue);
    } else {
      ret = getEntityChange(toValue) || getChangeItemStr(toValue);
    }
  } else if (nonNull) {
    ret = isString(item) ? item : JSON.stringify(item);
  }
  return ret;
};

// render 3 column inner table "Key, From, To"
const renderChangesTable = (data, rowId, t, localization) => {
  try {
    const changes = JSON.parse(data || '{}');
    const { version, id, ...filteredChanges } = changes;

    return (
      Object.keys(filteredChanges).length > 0 && (
        <Table
          rowKey={record => record.rowKey + '-' + rowId}
          dataSource={prepareDataForChangesColumn(
            filteredChanges,
            localization
          )}
          columns={prepareColumnsForChangesTable(rowId, t)}
          pagination={false}
          bordered
          size="small"
        />
      )
    );
  } catch (err) {
    console.log(err);
  }
};

const Columns = [
  {
    title: 'Time',
    dataIndex: ColumnKeys.TIME,
    key: ColumnKeys.TIME,
    sorter: (a, b) => a.key - b.key,
    render: (e, r) => renderTime(e),
    width: '15%'
  },
  {
    title: 'Action',
    dataIndex: ColumnKeys.ACTION,
    key: ColumnKeys.ACTION,
    sorter: (a, b) => a.action.localeCompare(b.action),
    width: '10%'
  },
  {
    title: 'User',
    dataIndex: ColumnKeys.USER,
    key: ColumnKeys.USER,
    sorter: (a, b) => a.user.localeCompare(b.user),
    width: '10%'
  },
  {
    title: 'Comment',
    dataIndex: ColumnKeys.COMMENT,
    key: ColumnKeys.COMMENT,
    width: '10%'
  },
  {
    title: 'Changes',
    dataIndex: ColumnKeys.CHANGES,
    key: ColumnKeys.CHANGES,
    width: '45%'
  }
];

const ChangesColumns = [
  {
    title: 'Key',
    dataIndex: ColumnKeys.KEY,
    key: ColumnKeys.KEY,
    width: '20%'
  },
  {
    title: 'From',
    dataIndex: ColumnKeys.FROM,
    key: ColumnKeys.FROM,
    width: '40%'
  },
  {
    title: 'To',
    dataIndex: ColumnKeys.TO,
    key: ColumnKeys.TO,
    width: '40%'
  }
];
