import { RouteKey } from '../config/page-key';
const PATH_TOKEN = 'page';

export const redirectPath = () => {
  const currentPath = window.location.pathname;
  let pathname = currentPath;
  if (pathname === RouteKey.SSO || pathname === RouteKey.LOGOUT) {
    pathname = RouteKey.DEFAULT;
  }

  return pathname || RouteKey.DEFAULT;
};

export const getPathFromAuthedRedirectUrl = ({ urlSearch }) => {
  const reg = new RegExp(`${PATH_TOKEN}=(.+)`);
  let path;
  try {
    path = reg.exec(urlSearch)?.[1];
  } catch (error) {
    //leave it, we have no fallback but we should let page show error for this case
  }

  return path || redirectPath();
};
