import React, { useState, useEffect } from 'react';
import { Collapse, Space, Checkbox, Button, Typography, Skeleton, Input } from 'antd';
import './VerticalFilter.scss';
import { useTranslation } from 'react-i18next';
const { Panel: CollapsePanel } = Collapse;
const { Text, Link } = Typography;
const { Search } = Input;

const VerticalFilter = ({ data, value = [], name = "filter", onChange, props }) => {
  const [selectedValues, setSelectedValues] = useState(value);
  const [filteredData, setFilteredData] = useState(data);
  const [keyword, setKeywords] = useState('');
  const { i18n, t } = useTranslation();

  const onValueChange = (value) => {
    if (value.target.checked) {
      setSelectedValues([...selectedValues, value.target.value]);
    } else {
      setSelectedValues(selectedValues.filter(item => item !== value.target.value));
    }
  }

  const onClear = () =>{
    setSelectedValues([]);
  }

  useEffect(() => {
    setSelectedValues(value)
  }, [value]);

  useEffect(() => {
    const filteredResult = {};
    for (const key in data) {
      const filteredValues = data[key].filter(i => i.description?.toLowerCase().includes(keyword?.toLowerCase())) || [];
      if (filteredValues.length !== 0) {
        filteredResult[key] = filteredValues;
      }
    }

    setFilteredData(filteredResult);
  }, [data, keyword]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedValues)
    }
  }, [selectedValues]);

  const onKeywordSearch = (searchValue) => {
    setKeywords(searchValue);
  }

  return (
    <div>
      <div className='popoverHeader'>
        <Search placeholder={t("Common.Search")} onChange={(e) => { onKeywordSearch(e.target.value) }} />
      </div>
      <div className='filterScrollableDiv'>
        {filteredData ?
          (Object.keys(filteredData).sort().map((key, index) => {
            return (
              <Collapse
                ghost
                expandIconPosition="right">
                <CollapsePanel header={key} className={"popoverPanel"} key={key}>
                  <Space direction="vertical" size={0} className={"fullWidth"}>
                    {
                      (filteredData[key] || []).sort((a, b) => a.description.localeCompare(b.description)).map(selection => {
                        return (
                          <div className={selectedValues.includes(selection.description) ? 'checkedItem' : 'uncheckedItem'}>
                            <Checkbox checked={selectedValues.includes(selection.description)} name={name} value={selection.description} onChange={onValueChange}>{selection.description}</Checkbox>
                          </div>
                        )
                      })
                    }
                  </Space>
                </CollapsePanel>
              </Collapse>
            )
          }))
          :
          <Skeleton active />
        }
      </div>
      <div className='popoverFooter'>
        <Space>
          <Button 
            onClick={onClear} type="primary"
            disabled={(selectedValues || []).length === 0}
            style={{ background: "#86B341" }}>{t("Common.ClearFilter")}</Button>
        </Space>
      </div>
    </div>
  );
}

export default VerticalFilter;