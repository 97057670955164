import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PATHS } from './constant';
import { Can, useCan } from '../../features/user/Can';
import { FeatureFlag } from '../../features/user/Permission';
import VehicleCompatibilitySearch from './VehicleCompatibilitySearch';
import { NoAccessBody } from '../NoAccess';
import VehicleModelForm from './VehicleModelForm';
import VehicleModelGroupForm from './VehicleModelGroupForm';
import VehicleCompatibility from './VehicleCompatibility';

const VehicleCompatibilityRouter = () => {
	const can = useCan();

	return (
		<Can onFail={<NoAccessBody />}>
			<Switch>
				<Route path={PATHS.VEHICLE_MODEL}>
					<Can featureFlag={FeatureFlag.npi.flag} onFail={<NoAccessBody />}>
						<VehicleModelForm />
					</Can>
				</Route>
				<Route path={PATHS.VEHICLE_MODEL_GROUP}>
					<Can featureFlag={FeatureFlag.npi.flag} onFail={<NoAccessBody />}>
						<VehicleModelGroupForm />
					</Can>
				</Route>
				<Route path={PATHS.VEHICLE_COMPATIBILITY}>
					<VehicleCompatibility />
				</Route>
				<Route path={PATHS.VEHICLE_COMPATIBILITY_DEFAULT}>
					<VehicleCompatibilitySearch />
				</Route>
			</Switch>
		</Can>
	);
};

export default VehicleCompatibilityRouter;
