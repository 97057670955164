import Fuse from 'fuse.js'
import React, { useMemo, useEffect, useRef, useState } from 'react';

export function useFuzzySearch(data, keys) {
  const [searchResult, setSearchResult] = useState([]);
  const searchKeyword = useRef(null);
  
  const fuse = new Fuse(data, {
    keys,
    threshold: 0.3,
    minMatchCharLength: 2,
    ignoreLocation: true,
    useExtendedSearch: true
  });

  const parseSearchQuery = (query, defaultFields) => {
    const parts = splitToParts(query);
    const result = { $and: [] };
    let currentPart = '';
  
    parts.forEach((part, index) => {
      if (part.includes(':')) {
        if (currentPart) {
          const parsedPart = parseQueryPart(currentPart, defaultFields);
          result.$and.push(parsedPart);
          currentPart = '';
        }
        const parsedPart = parseQueryPart(part, defaultFields);
        result.$and.push(parsedPart);
      } else {
        currentPart = index === 0 ? part : currentPart + ` ${part}`;
        // If it's the last part, parse it separately
        if (index === parts.length - 1) {
          const parsedPart = parseQueryPart(currentPart?.toString()?.trim(), defaultFields);
          result.$and.push(parsedPart);
        }
      }
    });
  
    return parts.length === 1 ? result.$and[0] : result;
  };
    
  const parseQueryPart = (part, defaultFields) => {
    let [key, values] = part.includes(':') ? part.split(':') : [null, part];
    const valueArr = splitToParts(values, ',');

    if (!key) {
      key = 'fullText';
    }
  
    if (key && valueArr.length > 1) {
      const orArray = valueArr.map((value) => parseValue(key, value));
      return { $or: orArray };
    } else if (key && values) {
      return parseValue(key, valueArr[0]);
    }
  
    return null;
  };
  
  const parseValue = (key, value) => {
    // Remove quot for quoted value
    const match = value.match(/^"(.*)"$/);
    value = match ? match[1] : value;
  
    if (key === 'year') {
      key = 'yearOfManufacture';
    } else if (key === 'sap') {
      key = 'supportedKit.sapCode';
    } else if (key === 'vin') {
      key = 'vins';
    } else if (key === 'params') {
      key = 'supportedPid.description';
    }
  
    if (key === 'yearOfManufacture') {
      value = parseYears(value);
      if (!value) {
        return null;
      }
    }
    return { [key]: value };
  };
  
  const parseYears = (inputString) => {
    const yearRegex = /\b(\d{4})\b/g;
    const years = inputString.match(yearRegex);
  
    if (!years || years.length === 0) {
      return null;
    }
  
    const formattedYears = years.map((year) => `=${year}`);
    return formattedYears.join('|');
  };
  
  const constructQueryObject = (keywords, defaultFields) => {
    const queryObject = { $and: [] };
    const orArray = { $or: [] };
  
    keywords.forEach((keyword) => {
      let isOr = false;
  
      if (keyword.toLowerCase().startsWith("or ")) {
        isOr = true;
        keyword = keyword.substring(3);
      }
  
      const parsedQuery = parseSearchQuery(keyword, defaultFields);
      if (isOr) {
        orArray.$or.push(parsedQuery);
      } else {
        queryObject.$and.push(parsedQuery);
      }
    });
  
    if (orArray.$or.length > 0) {
      queryObject.$and.unshift(orArray);
    }
  
    return queryObject;
  };

  function areArraysExactMatch(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
  
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
  
    return true;
  }

  const search = (keywords, keys) => {
    if (areArraysExactMatch(keywords, (searchKeyword.current || []))) {
      return searchResult;
    }
    else if ((keywords || []).length == 0 ){
      searchKeyword.current = keywords;
      setSearchResult(data); 
      return data;
    }
    else {
      const parsedQuery = constructQueryObject(keywords, keys);
      let filteredData = fuse.search(parsedQuery).map(result => result.item);

      searchKeyword.current = keywords;
      setSearchResult(filteredData); 
      return filteredData;
    }
  }

  useEffect(()=>{
    searchKeyword.current = [];
  }, [data])

  const targetKeySearch = (keyword, key) => {
    const parsedQuery = { $and: [] };
    parsedQuery.$and.push({ [key]: keyword?.toString()?.trim() });
    
    let filteredData = fuse.search(parsedQuery).map(result => result.item);

    return filteredData;
  };

  return useMemo(() => {
    return {
      performSearch: search,
      performIndividualKeySearch: targetKeySearch,
      result : searchResult
    };
  }, [data, searchResult]);
}

export const splitToParts = (query, separator = ' ') => {
  const parts = [];
  let currentPart = '';
  let isInQuotes = false;

  for (let i = 0; i < query.length; i++) {
    const char = query[i];

    if (char === '"') {
      // Toggle the isInQuotes flag
      isInQuotes = !isInQuotes;
      currentPart += char;
    } else if (char === separator && !isInQuotes) {
      // Separator outside quotes indicates the end of a part
      if (currentPart !== '') {
        parts.push(currentPart);
        currentPart = '';
      }
    } else {
      currentPart += char;
    }
  }

  if (currentPart !== '') {
    parts.push(currentPart);
  }

  return parts;
};
