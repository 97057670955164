import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { StorageKey } from '../localStorage/storageKey';

export const REMOTE_SIGN_IN_ERROR = {
  KC_ADAPTER_LOAD_ERROR: 'KC_ADAPTER_LOAD_ERROR',
  KC_INIT_ERROR: 'KC_INIT_ERROR',
  KC_AUTH_ERROR: 'KC_AUTH_ERROR',
  API_KEY_ERROR: 'API_KEY_ERROR',
  API_KEY_EMPTY_ERROR: 'API_KEY_EMPTY_ERROR'
};

export const REMOTE_SIGN_IN_TIMEOUT = 300000; //5*60*1000;

export const initialUser = {
  current: {
    auth: {
      key: undefined,
      keycloakConfig: null
    }
  },
  meta: {},
};

const ssoSlice = createSlice({
  name: 'user',
  initialState: initialUser,
  reducers: {
    remoteSignInStart(state) {
      state.meta.remoteSigning = true;
      state.meta.error = null;
      state.meta.remoteSignInAvaliable = null;
      state.meta.remoteSignInError = null;
    },
    remoteSignInSuccess(state, { payload }) {
      window.localStorage.removeItem(StorageKey.LOGOUT_STATE);
      state.meta.remoteSigning = false;
      state.current = payload;
      state.meta.remoteSignInAvaliable = true;
      state.meta.remoteSignInError = null;
    },
    remoteSignInFailed(state, { payload }) {
      state.meta.remoteSigning = false;
      state.meta.error = payload;
      state.meta.remoteSignInAvaliable = ![
        REMOTE_SIGN_IN_ERROR.KC_ADAPTER_LOAD_ERROR,
        REMOTE_SIGN_IN_ERROR.KC_INIT_ERROR,
        REMOTE_SIGN_IN_ERROR.KC_AUTH_ERROR
      ].some(err => err === payload);
      state.meta.remoteSignInError = payload;
    },
    setRemoteSignInAvaliable(state, { payload }) {
      state.meta.remoteSignInAvaliable = payload.remoteSignInAvaliable;
      state.meta.remoteSignInError = payload.remoteSignInError;
    },
    resetRemoteSignIn(state) {
      state.meta.remoteSignInAvaliable = null;
      state.meta.remoteSignInError = null;
      state.meta.remoteSigning = false;
    },
    logout(state) {
      window.localStorage.setItem(StorageKey.LOGOUT_STATE, "true");
      state.current = initialUser.current;
    }
  }
});

export const {
  remoteSignInStart,
  remoteSignInSuccess,
  remoteSignInFailed,
  setRemoteSignInAvaliable,
  resetRemoteSignIn,
  logout
} = ssoSlice.actions;

export default ssoSlice.reducer;

export const useUser = () => {
  return useSelector(state => state?.user?.current);
};

export const useUserKey = () => {
  return useSelector(state => state.user?.current?.auth?.key);
};

export const useIsUserBlocked = () => {
  return useSelector(state => state.user?.userBlocked);
};

export const useIsUserCompanyBlocked = () => {
  return useSelector(state => state.user?.userCompanyBlocked);
};