import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

//components
import { Table } from 'antd';

//styles
import styles from './AuditsTable.module.scss';

//helpers
import { prepareAuditsColumnsForTable, prepareAuditsDataForTable } from './helpers';

export const AuditsTable = ({ audits, isFetching }) => {
  const { t } = useTranslation();
  const showPagination =
    (audits?.length > 100 && { pageSize: 100, showSizeChanger: false }) || false;
  const paginationHeight = (showPagination && 55) || 0;

  return (
    <Table
      className={styles.auditsTable}
      dataSource={prepareAuditsDataForTable(audits || [], t)}
      columns={prepareAuditsColumnsForTable(t)}
      pagination={showPagination}
      sticky
      loading={isFetching}
      size="small"
      scroll={{ y: `calc(100vh - 100px - ${paginationHeight}px)` }}
    />
  );
};
