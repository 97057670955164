import React from 'react';
import { Space, Popover } from 'antd';
import { MoreMenu } from './MoreMenu/MoreMenu';

export const Buttons = ({
  padding = 16,
  moreButtons = null,
  noMargin=false,
  placement = 'left',
  children
}) => {
  return (
    <Space size={padding}>
      <MoreMenu placement={placement} size="xl" border noMargin={noMargin} items={moreButtons || []}>
        {children}
      </MoreMenu>
    </Space>
  );
};
