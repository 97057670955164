import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, InfoOutlined, CloudDownloadOutlined, CloseCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import "./VehicleCompatibilityInfoModal.scss"
import { useMediaQuery } from 'react-responsive';
import { Button, Row, Col, Drawer, List, Tooltip } from 'antd';
import { useVehicleCompatibility } from '../../features/vehicleModels/vehicleModelApi';
import { useCurrentCompany } from '../../features/company/companySlice.js';
import { isValidHttpUrl, toTitleCase } from '../../utils/strings';
import { fetchDocument } from '../../features/document/documentApi';
import { useDispatch } from 'react-redux';
import { useUserKey } from '../../features/user/ssoSlice';
import { Can, useCan } from '../../features/user/Can';
import { Role } from '../../features/user/Permission';

const VehicleCompatibilityDetailModal = ({ vehicleDetail, setSelectedKit, isModalOpen, onClose }) => {
  const { i18n, t } = useTranslation();
	const dispatch = useDispatch();
  const userKey = useUserKey();
  const can = useCan();
  const currentCompany = useCurrentCompany();
  const { vehicleCompatibility } = useVehicleCompatibility(currentCompany.id)
  const [data, setData] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 767 })

  useEffect(() => {
    if (vehicleCompatibility && vehicleDetail && isModalOpen) {
      const vehicleModelWithDifferentSource = vehicleCompatibility.filter(i =>vehicleDetail.groupId !== undefined && i.groupId !== undefined &&
         i.groupId === vehicleDetail?.groupId);

      let filteredIdenticalVehicleModel = [];
      let totalSupportedPid = [];

      vehicleModelWithDifferentSource.forEach(data => {
        if (filteredIdenticalVehicleModel.find(i => i.source === data) === undefined) {
          filteredIdenticalVehicleModel.push(data);
          totalSupportedPid = [...totalSupportedPid, ...data.supportedPid]
        }
      })

      const joinedPid = [...vehicleDetail.supportedPid, ...totalSupportedPid].filter(pid=> pid.category.toUpperCase() !== "UNKNOWN")
      const uniquePid = joinedPid.filter((obj, index, self) =>
        index === self.findIndex((o) => (
          o.category === obj.category && o.description === obj.description
        ))
      );

      const groupedPid = (uniquePid || []).reduce((acc, obj) => {
        const { category } = obj;
        let categoryName = category;
        if (categoryName.toUpperCase() === "UNKNOWN") {
          categoryName = "Others"
        }

        if (!acc[categoryName]) {
          acc[categoryName] = [];
        }
        acc[categoryName].push(obj);
        return acc;
      }, {});

      let comparisonData = [];
      let headerRow = { measurementName: t("VehicleMeasurement.Parameters") };

      filteredIdenticalVehicleModel.push(vehicleDetail);
      filteredIdenticalVehicleModel = filteredIdenticalVehicleModel.sort((a, b) => {
        if (a.source?.toUpperCase() === "INTERNAL") return -1;
        if (b.source?.toUpperCase() === "INTERNAL") return 1;
        return 0;
      })

      filteredIdenticalVehicleModel.forEach(model => {
        headerRow[model.source] = {render: toTitleCase(`${model.displaySource} ${(model.isDummyInternal)?"*" :""}`)};
      })
      headerRow["isHeader"] = true;
      comparisonData.push(headerRow);

      Object.keys(groupedPid).sort((a, b) => {
        if (a.toUpperCase() === "OTHERS") return 1;
        if (b.toUpperCase() === "OTHERS") return -1;
        return 0;
      }).forEach(key => {
        comparisonData.push({ measurementName: key, isSubHeader: true })
        let groupComparisonData = [];
        groupedPid[key].forEach(data => {
          let pidComparison = { measurementName: data.description };
          filteredIdenticalVehicleModel.forEach(model => {
            pidComparison[model.source] = renderSupportedPid(model, data);
          })

          groupComparisonData.push(pidComparison);
        })
        groupComparisonData = groupComparisonData.sort((a, b) => {
          if (a.internal?.supported) return -1;
          if (b.internal?.supported) return 1;
        })

        comparisonData = [...comparisonData, ...groupComparisonData];
      })
      setData(comparisonData);
    }
  }, [vehicleCompatibility, vehicleDetail])

  const renderSupportedPid = (vehicleModel, data) => {
    const isSupported = vehicleModel.supportedPid.find(i => i.category === data.category && i.description === data.description);
    const isModelSupportPid = isSupported ? true : false;

    let renderingUI = isSupported ?
      <Tooltip title={t("Common.Available")}><CheckCircleOutlined style={{ color: "green" }} /></Tooltip> : 
      <Tooltip title={t("Common.NotAvailable")}><CloseCircleOutlined style={{ color: "red" }} /></Tooltip>;
    
    if (data.category.toUpperCase() === "UNKNOWN" && isSupported) {
      renderingUI = <Tooltip title={t("Common.NotUsed")}><MinusCircleOutlined style={{ color: "grey" }} /></Tooltip>
    }

    return {supported : isModelSupportPid, render : renderingUI};
  }

  const footer = () => {
    let supportKitList = [];
    if (vehicleDetail?.supportedKit?.length !== 0) {
      supportKitList = vehicleDetail?.supportedKit;

      supportKitList = (supportKitList || []).reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      supportKitList = supportKitList.filter(i => i.sapType === "kit");
    }

    return (
      <>
        <Row>
          <Col xs={{ span: 3 }} />
          <Col xs={{ span: 9 }} style={{ textAlign: "center", paddingRight: "5px" }}>
            {t("VehicleModel.InstallationSupport")}
          </Col>
          <Col xs={{ span: 9 }} style={{ textAlign: "center" }}>
            {can({ oneOfRole: [Role.ADMIN, Role.SITE_ADMIN] }) && t("VehicleModel.InstallationKit")}
          </Col>
          <Col xs={{ span: 3 }} />
        </Row>
        <Row>
          <Col xs={{ span: 3 }} />
          <Col xs={{ span: 9 }} style={{ textAlign: "center", paddingRight: "5px" }}>
            {
              vehicleDetail?.documentId ?
                <Button type="link" onClick={() => {
                  var win = window.open();
                  fetchDocument(vehicleDetail.documentId, dispatch, userKey).then(e => {
                    if (isValidHttpUrl(e)){
                      win.location = e;
                    }
                    else{
                      win.document.write(e);
                    }
                  }).catch(e => win.close())
                }}>
                  {t("Common.Download")}
                  <CloudDownloadOutlined />
                </Button> : "-"
            }
          </Col>
          <Col xs={{ span: 9 }} style={{ textAlign: "center" }}>
            <Can oneOfRole={[Role.ADMIN, Role.SITE_ADMIN]}>
              {
                supportKitList.length !== 0 ? supportKitList.map((kit) => {
                  return (
                    <Col xs={{ span: 24 }}>
                      <Button onClick={() => { setSelectedKit(kit, vehicleDetail) }} type="link" icon={<InfoOutlined style={{
                        backgroundColor: "#1677ff",
                        fontSize: 10,
                        borderRadius: 25,
                        padding: 2,
                        color: "#fff"
                      }} />}>

                        {t("VehicleModel.Kit #") + kit.sapCode}
                      </Button>
                    </Col>
                  );
                }) : <Col xs={{ span: 24 }}> - </Col>
              }
            </Can>
          </Col>
          <Col xs={{ span: 3 }} />
        </Row>
      </>
    );
  }
  return (
    <Drawer
      title={`${vehicleDetail?.make} ${vehicleDetail?.model} ${t("VehicleModel.CompatibilitySheet")}`}
      placement={isMobile ? "bottom" : "right"}
      height={"90%"}
      width={"auto"}
      onClose={onClose}
      open={isModalOpen}
      footer={footer()}
    >
      <List
        size="small"
        bordered
        dataSource={data}
        renderItem={(item) => {
          return (
            <List.Item style={{ background: item.isHeader || item.isSubHeader ? "#f2f2f2" : "#fff" }}>
              <Row style={{ width: "100%" }}>
                <Col xs={{ span: item.isSubHeader ? 24 : 10 }}>
                  {item.measurementName}
                </Col>
                {
                  !item.isSubHeader && <>
                    {
                      Object.keys(item).map((key, index) => {
                        if (index !== 0) {
                          return (
                            <Col xs={{ span: 14 / (Object.keys(item).length - (item.isHeader ? 2 : 1)) }} style={{ textAlign: "center" }}>
                              {item[key].render}
                            </Col>
                          )
                        }
                      })
                    }
                  </>
                }
              </Row>
            </List.Item>
          )
        }}
      />
    </Drawer>
  )
}

export default VehicleCompatibilityDetailModal;