export const RouteKey = {
  DEFAULT: '/',
  AUDIT_VEHICLE_MODEL: '/auditVehicleModel',
  AUDIT_VEHICLE_MODEL_POLL: '/auditVehicleModelPoll',
  AUDIT_ATTACHMENT: '/auditSttachment',
  AUDIT_RESOURCE: '/auditResource',
  AUDIT_HARDWARE_ATTACHMENT: '/auditHardwareAttachment',
  SSO: '/sso',
  LOGIN: '/login',
  VEHICLE_COMPATIBILITY: '/vehicleCompatibility',
  VEHICLE_COMPATIBILITY_SEARCH: '/vehicleCompatibilitySearch',
  UNVERIFIED_VEHICLE: '/unverifedModel',
  SCANNING_REQUEST: '/scanningRequest',
  LOGOUT: '/logout',
  SAP: '/sap',
  DUMMY: '/dummy',
  DOCUMENT: '/document',
  VEHICLE_MODEL: '/vehicleModel',
  VEHICLE_MODEL_GROUP: '/vehicleModelGroup',
  SAP_FORM: '/sapForm'
};