import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Panel, PanelBody } from './../../components/panel/panel.js';
import { Table, InputNumber, Space, Spin, Form, Button, Row, Col, Input, Select, AutoComplete, Tooltip, Typography } from 'antd';
import style from './VehicleCompatibility.module.scss';
import { useDispatch } from 'react-redux';
import { setBackButton, setPageTitle } from '../../features/header/headerSlice';
import { useSaveVehicleModelMutation, useGetVehicleModelByIdQuery, useDeleteVehicleModelMutation, useVehicleModelWithSource, useGetVehicleModelsQuery, useVoteForScanRequestMutation, useUnVoteForScanRequestMutation, usePolls } from '../../features/vehicleModels/vehicleModelApi.js';
import { RouteKey } from '../../config/page-key.js';
import { Permission } from '../../features/user/Permission.js';
import { DeleteOutlined } from '@ant-design/icons';
import { Can, useCan } from '../../features/user/Can.js';
import { ResponsiveButton } from '../../features/responsive';
import { confirmationModal } from '../../features/confirmationModal/confirmationModal.js';
import formStyles from './VehicleMakeModelYearFormModal.module.scss';
import { useCurrentCompany } from '../../features/company/companySlice.js';
import {
	ScanOutlined
} from '@ant-design/icons';
import { useFuzzySearch } from '../../utils/fuzzySearch.js';
import { SupportedFlag, VehicleModelFormItem } from './constant.js';
import { addToast } from '../../features/toast/toastsSlice.js';
import { executeSingleAction } from '../../utils/ApiResponseHandler.js';
const { Option } = Select;
const { TextArea } = Input;
const { Text, Title } = Typography;

const VehicleModelForm = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { i18n, t } = useTranslation();
	const currentCompany = useCurrentCompany();
	const { data, isFetching } = useGetVehicleModelByIdQuery({ id: history.location.state.record.id }, { skip: history.location?.state?.record?.id === undefined });
	const { data: fullVehicleModel, isFetching : isFetchingAllModel } = useGetVehicleModelsQuery({ companyId: currentCompany?.id }, { skip: currentCompany?.id === undefined });
	const [isDirty, setIsDirty] = useState(false);
	const [hasDbcOption, setHasDbcOption] = useState(false);
	const [dbcOption, setDbcOption] = useState([]);
	const [saveVehicleModel] = useSaveVehicleModelMutation();
	const [deleteVehicleModel] = useDeleteVehicleModelMutation();
	const [form] = Form.useForm();
	const { vehicleModels: makeModelYearList } = useVehicleModelWithSource(currentCompany?.id)
	const { performIndividualKeySearch } = useFuzzySearch(makeModelYearList || [], ['make', 'model', 'yearOfManufacture']);
	const can = useCan();
	const [canDelete, setCanDelete] = useState(false);
	const [canEdit, setCanEdit] = useState(false);

	const { polls, history: userVoteHistory, isFetching : isFetchingPoll } = usePolls();
	const [voteForScanRequest] = useVoteForScanRequestMutation();
	const [unVoteForScanRequest] = useUnVoteForScanRequestMutation();
	const [votes, setVotes] = useState(new Set());
	const [unvotablePolls, setUnvotablePolls] = useState(new Map());

	useEffect(() => {
		if (!isFetchingPoll) {
			const latestVotes = new Set();
			userVoteHistory.forEach(obj => {
				const key = obj['vehicleModelId'];
				latestVotes.add(key);
			});
			setVotes(latestVotes);
		}
	}, [isFetchingPoll, userVoteHistory]);

	useEffect(() => {
		if (!isFetchingPoll) {
			const latestPolls = new Map();
			polls.filter(poll => poll.status !== 'IN_REVIEW').forEach(poll => {
				latestPolls.set(poll.vehicleModel.id, poll);
			});
			setUnvotablePolls(latestPolls);
		}
	}, [isFetchingPoll, polls]);

	const [localTempSearchedValue, setLocalTempSearchedValue] = useState({
		make: "",
		model: "",
		yearOfManufacture: ""
	});

	const formItems = VehicleModelFormItem(t);

	useEffect(() => {
		if (data) {
			dispatch(setPageTitle(data.make + " " + data.model + " " + data.yearOfManufacture));
		}
		else {
			dispatch(setPageTitle(t("VehicleModel.NewVehicleModel")));
		}

		dispatch(setBackButton(true));

		return () => {
			dispatch(setPageTitle(""));
			dispatch(setBackButton(false));
		}
	}, [data, t, isFetching]);

	useEffect(() => {
		if (data && !isFetching && !isFetchingAllModel && fullVehicleModel) {
			let canEdit = true;
			if (!!data?.groupId) {
				const pairedInventure = fullVehicleModel.find(i => i.source == "inventure" && i.groupId == data.groupId);
				canEdit = !pairedInventure;
			}
			else {
				canEdit = data?.source !== "inventure";
			}

			canEdit &= can({ everyPermission: Permission.COMMON_EDIT });
			let canDelete = canEdit && data?.company?.id !== undefined;
	
			setCanEdit(canEdit);
			setCanDelete(canDelete);
		}
	}, [data, fullVehicleModel, isFetching, isFetchingAllModel]);

	const onFinish = (e) => {
		let payload = { ...data, ...e };

		if (e.dbc) {
			payload.dbcFileKey = e.dbc;
		}

		if (payload.extras) {
			const oldExtrasObj = JSON.parse(payload.extras);
			const newExtrasObj = {...oldExtrasObj};
			newExtrasObj.note = e.note;

			if (oldExtrasObj.dbcAvailable) {
				const selectedDbc = oldExtrasObj.dbcAvailable.find(i => i.dbc_file_key === payload.dbcFileKey);

				newExtrasObj.baudrate = selectedDbc.baudrate;
				newExtrasObj.dbcAvailable.forEach(i => {
					delete i["is_default"];
				})
			}
			payload.extras = JSON.stringify(newExtrasObj);
		}
		else {
			payload.extras = JSON.stringify({note:e.note});
		}

		if (payload.isSupported === SupportedFlag.NOT_SUPPORTED) {
			payload.isSupported = false;
		}
		else if (payload.isSupported === SupportedFlag.SUPPORTED) {
			payload.isSupported = true;
		}
		else {
			delete payload["isSupported"];
		}

		delete payload["dbc"];
		delete payload["source"];
		payload.identifier = "VehicleModel" + payload.id;
		payload.loadingMessage = t("Common.Saving");
		payload.successMessage = t("VehicleModel.VehicleModelSaved");
		executeSingleAction(payload, saveVehicleModel, dispatch).then(() => {
			if (history.length > 2) {
				history.goBack();
			}
			else {
				history.push(RouteKey.VEHICLE_COMPATIBILITY);
			}
		})
	};

	useEffect(() => {
		let formData = {
			make: data?.make,
			mode: data?.model,
			yearOfManufacture: data?.yearOfManufacture,
			priority: data?.priority,
			isSupported: data?.isSupported !== undefined ? (data.isSupported ? SupportedFlag.SUPPORTED : SupportedFlag.NOT_SUPPORTED) : SupportedFlag.UNKNOWN,
			source: data?.displaySource !== undefined ? data?.displaySource : data?.source,
		};

		if (data?.extras) {
			const extra = JSON.parse(data.extras);
			formData["note"] = extra.note;
		}

		if (data?.dbcFileKey) {
			let dbcOptionAvailable = [];
			dbcOptionAvailable.push({ label: data?.dbcFileKey, value: data?.dbcFileKey });
			if (data?.extras) {
				const vmExtras = JSON.parse(data.extras);
				if (vmExtras.dbcAvailable) {
					dbcOptionAvailable = [];
					vmExtras.dbcAvailable.forEach(dbcOpt => {
						dbcOptionAvailable.push({ label: dbcOpt?.dbc_file_key, value: dbcOpt?.dbc_file_key });
					})
					setHasDbcOption(true);
				}
			}
			setDbcOption(dbcOptionAvailable)
			formData["dbc"] = data?.dbcFileKey;
		}

		form.setFieldsValue({ ...data, ...formData });
	}, [form, data]);

	const renderFormItems = formItems => {
		return <Row className={style.fullWidthRow}>
			{
				formItems.map((formItem, formItemIndex) => {
					const { name, label, rules } = formItem;
					return (
						<Col xs={{ span: 24 }}>
							<Form.Item
								key={`${formItemIndex}-${name}`}
								name={name}
								labelAlign="left"
								className={style.inputLeft}
								label={label}
								colon={false}
								rules={rules}
							>
								{renderFormItemChild(name)}
							</Form.Item>
						</Col>
					);
				})
			}
		</Row>
	};

	const renderNotFoundPanel = type => {
		let possibleMatch = [];
		if (localTempSearchedValue.value) {
			const possibleMatchModel = performIndividualKeySearch(localTempSearchedValue.value || '', type);
			possibleMatch = Array.from(new Set(possibleMatchModel.map(i => i[type]))).slice(0, 5);
		}

		if ((possibleMatch || []).length !== 0) {
			return (
				<div style={{ padding: "0px 10px" }}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{(possibleMatch || []).map((data, index) => (
							<a
								key={index}
								className={formStyles.makeModelYearSuggestionValue}
								onClick={() => {
									form.setFieldValue(type, data);
									document.activeElement.blur();
								}}
							>
								{data}
							</a>
						))}
					</div>
				</div>
			);
		}
	};

	const handleValueSearch = (value, type) => {
		value && setLocalTempSearchedValue({ value, type });
	};

	const renderFormItemChild = (name) => {
		const options = Array.from(new Set((makeModelYearList || []).map(i => i[name])))
			.filter(i => i?.toString() !== undefined && i?.toString()?.length !== 0)
			.map(data => {
				return { label: data?.toString(), value: data?.toString() };
			});

		return (
			<AutoComplete
				onSelect={e => {
					form.setFieldValue(name, e);
				}}
				size={"large"}
				options={options}
				filterOption={(inputValue, option) =>
					option.value
						?.toString()
						.toUpperCase()
						.indexOf(inputValue?.toString().toUpperCase()) !== -1
				}
				disabled={!canEdit}
				onFocus={() => {
					handleValueSearch(form.getFieldValue(name), name);
				}}
				onSearch={e => {
					handleValueSearch(e, name);
				}}
				notFoundContent={renderNotFoundPanel(name)}
			/>
		);
	};

	const scanRequestRender = (record) => {
		if (record?.source === 'internal' && !record?.isDummyInternal) {
			if (unvotablePolls.has(record.id)) {
				return <Tooltip title={unvotablePolls.get(record.id).comment}>
					<ResponsiveButton size={"large"} style={{ color: '#A0A0A0', cursor: 'not-allowed' }}
						title={t("VehicleModel.PollClosed", { status: t('VehicleModel.RequestStatus.' + unvotablePolls.get(record.id).status) })}
						icon={<ScanOutlined />} />
				</Tooltip>
			} else if (votes.has(record.id)) {
				return <ResponsiveButton size={"large"} onClick={() => {
					unVoteForScanRequest({ id: record.id });
					votes.delete(record.id);
					dispatch(
						addToast({
							title: i18n.t("VehicleModel.ScanRequest"),
							type: "success",
							content: <Trans
								i18nKey={'VehicleModel.RequestCancelled'}
								components={{ primary: <Text type="success" strong />, secondary: <Text strong /> }}
							/>,
						})
					);

				}} title={t("VehicleModel.CancelRequest")} icon={<ScanOutlined />} />
			} else {
				return <ResponsiveButton size={"large"} onClick={() => {
					voteForScanRequest({ id: record.id });
					votes.add(record.id);
					dispatch(
						addToast({
							title: i18n.t("VehicleModel.ScanRequest"),
							type: "success",
							content: <Trans
								i18nKey={'VehicleModel.ScanRequested'}
								components={{ primary: <Text type="success" strong />, secondary: <Text strong /> }}
							/>,
						})
					);

				}} title={t("VehicleModel.RequestScan")} icon={<ScanOutlined />} />
			}
		}
	}

	return (
		<Spin spinning={isFetching}>
			<Panel noPadding style={{ marginBottom: "15px" }} newButtonAction={() => {
				return <Space>
					{data && scanRequestRender(data)}
					<Can requiredPermission={[Permission.COMMON_EDIT]}>
						<ResponsiveButton danger size={"large"} disabled={!canDelete} onClick={() => {
							confirmationModal(
								t('VehicleModel.DeleteVehicleModelTitle', { vehicleModel: data?.make + " " + data?.model + " " + data?.yearOfManufacture }),
								t('VehicleModel.DeleteVehicleModelDescription', { vehicleModel: data?.make + " " + data?.model + " " + data?.yearOfManufacture }),
								t('Common.Delete'),
								t('Common.CancelButton'),
								() => {
									const payload = {
										successMessage: i18n.t("VehicleModel.VehicleModelDeleted"),
										id: data.id,
										identifier: "VehicleModel" + data.id,
										loadingMessage : t("Common.Saving")
									}
									executeSingleAction(payload, deleteVehicleModel, dispatch).then(() => {
										if (history.length > 2) {
											history.goBack();
										}
										else {
											history.push(RouteKey.VEHICLE_COMPATIBILITY);
										}
									})
								},
								'delete'
							);
						}}
							title={t("Common.Delete")}
							icon={<DeleteOutlined />} />
					</Can>
				</Space>
			}}>
			<Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={() => { setIsDirty(true) }}>
				<Row style={{ margin: 16 }}>
					<Col xs={{ span: 24 }} lg={{ span: 16 }}>
						<Panel style={{ marginBottom: "15px", background:"#f7f7f7" }}>
							<Title type="secondary" level={4} className={style.titleText}>{t("VehicleModel.ExportFileName")}</Title>
							<PanelBody>
								<Row className={style.fullWidthRow}>
									<Col xs={{ span: 24 }} md={{ span: 12 }}>{renderFormItems(formItems)}</Col>
								</Row>
							</PanelBody>
						</Panel>
						<Panel style={{ marginBottom: "15px", background:"#f7f7f7" }}>
							<Title type="secondary" level={4} className={style.titleText}>{t("VehicleModel.Setting")}</Title>
							<PanelBody>
								<Row className={style.fullWidthRow}>
									<Col xs={{ span: 24 }} sm={{ span: 12 }}>
										<Row className={style.fullWidthRow}>
											<Col xs={{ span: 24 }}>
												<Form.Item
													label={t("VehicleModel.Priority")}
													name="priority"
													className={style.inputLeft}
													rules={[{ required: true, message: t("ValidationErrors.PriorityRequired") }]}
												>
													<InputNumber style={{ width: "100%" }} size={"large"} />
												</Form.Item>
											</Col>
											<Col xs={{ span: 24 }}>
												<Form.Item
													name="isSupported"
													label={t("VehicleModel.Supported")}
													className={style.inputLeft}
												>
													<Select size={"large"}>
														<Option value={SupportedFlag.UNKNOWN}>{t("VehicleModel.Unknown")}</Option>
														<Option value={SupportedFlag.SUPPORTED}>{t("VehicleModel.Yes")}</Option>
														<Option value={SupportedFlag.NOT_SUPPORTED}>{t("VehicleModel.No")}</Option>
													</Select>
												</Form.Item>
											</Col>
											<Col xs={{ span: 24 }}>
												<Form.Item
													label={t("VehicleModel.Source")}
													name="source"
													className={style.inputLeft}
												>
													<Input disabled={true} size={"large"} />
												</Form.Item>
											</Col>
											<Col xs={{ span: 24 }}>
												<Form.Item
													name="dbc"
													label={t("VehicleModel.DbcKey")}
													className={style.inputLeft}>
													{
														dbcOption.length !== 0 && hasDbcOption ?
															<Select size={"large"} options={dbcOption} disabled={dbcOption.length <= 1} /> :
															<Input size={"large"} disabled={data?.source === "inventure"}/>
													}
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col xs={{ span: 24 }} sm={{ span: 12 }}>
										<Form.Item
											label={t("VehicleModel.Note")}
											name="note"
											className={style.inputLeft}
										>
											<TextArea rows={8} />
										</Form.Item>
									</Col>
								</Row>
							</PanelBody>
						</Panel>
					</Col>
				</Row>
				<Row className={"formFooter"}>
					<Col xs={{ span: 24 }}>
						<Space>
							<Button
								type="primary"
								size="large"
								htmlType="submit"
								disabled={!isDirty}
							>
								{t('Common.SaveButton')}
							</Button>
							<Button size="large" onClick={history.goBack}>
								{t('Common.CancelButton')}
							</Button>
						</Space>
					</Col>
				</Row>
			</Form>
			</Panel>
		</Spin>
	)
}

export default VehicleModelForm;