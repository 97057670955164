import React, {useEffect, useMemo} from 'react';
import { Route, withRouter, useLocation, Redirect } from 'react-router-dom';
import routes from '../../config/page-route.js';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import contentStyles from './content.module.scss';
import { useUserKey } from '../../features/user/ssoSlice';
import { RouteKey } from '../../config/page-key';
import { useUserInfo } from '../../features/user/userApi';
import { Typography } from 'antd';
import IdleTimerContainer from '../../pages/idle/IdleTimerContainer.js';
import { useCan } from '../../features/user/Can.js';
import { NoAccessBody } from '../../pages/NoAccess.js';

function setTitle(path, routeArray) {
	var pageTitle;
	for (var i=0; i < routeArray.length; i++) {
		if (routeArray[i].path === path && i18n.t(routeArray[i].title)) {
			pageTitle = 'Teletrac Navman | ' + i18n.t(routeArray[i].title);
		}
	}
	document.title = (pageTitle) ? pageTitle : 'Teletrac Navman';
}

const Content = () =>{
	const userKey = useUserKey();
	const location = useLocation();
	const { t } = useTranslation();
	const data = useUserInfo();
	const can = useCan();

	useEffect(() => {
		setTitle(location.pathname, routes);

		return ()=>{
			setTitle(location.pathname, routes);
		}
	}, [location.pathname, t]);

	const renderContent = (component, checkGuard) =>{
		if (userKey && data?.id !== undefined && data?.isUserAllowed === false && checkGuard) {
			return <NoAccessBody />
		}
		else {
			return component();
		}
	}

	const fullRoutes = useMemo(()=>{
		//filter by feature flag
		const filteredRoutes = routes.filter(i=> (i.featureFlag === undefined || can({featureFlag: i.featureFlag})) && (i.featureHide === undefined || !can({featureFlag: i.featureHide})));
		
		//filter by role
		return filteredRoutes.filter(i=> i.role === undefined || data.isSiteAdmin || can({ oneOfRole: i.role }))
	}, [routes, data]);

	return (
		<div className={contentStyles.content}>
			{userKey && data?.id !== undefined && data?.isUserAllowed && <IdleTimerContainer />}
			{
				fullRoutes.map((route, index) => (
					<Route
						key={index}
						path={route.path}
						exact={route.exact}
						component={!userKey?.length && route.guardRequired ? (() => <Redirect to={{
							pathname: RouteKey.LOGIN,
							state: { from: location }
						}} />) : () => renderContent(route.component, route.guardRequired)}
					/>
				))
			}
		</div>
	);
}

export default withRouter(Content);
