import { createSlice } from '@reduxjs/toolkit';
import { Spin, notification } from 'antd';
import React from 'react';

export const toastSlice = createSlice({
  initialState: {},
  name: 'toast',
  reducers: {
    addToast: (state, { payload }) => {
      let formattedPayload = {
        key:payload.key,
        placement : 'topRight',
        description:payload.content
      };

      if (payload.title) {
        formattedPayload.message = payload.title;
      }

      if (payload.type === "loading") {
        formattedPayload.style = {background:"#e6f4ff"}
        formattedPayload.icon = <Spin />
        formattedPayload.duration = 0;
        notification.open(formattedPayload)
      }
      else if (payload.type ==="error"){
        formattedPayload.style = {background:"#fff2f0"}
        notification.error(formattedPayload)
      }
      else if (payload.type ==="success"){
        formattedPayload.style = {background:"#F6FFED"}
        notification.success(formattedPayload)
      }
      else {
        formattedPayload.style = {background:"#e6f4ff"}
        notification.info(formattedPayload)
      }
    }
  }
});

export const {
  addToast
} = toastSlice.actions;

export default toastSlice.reducer;
