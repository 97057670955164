export const AUDITS_ACTIONS = {
  DELETE: 'delete',
  RESTORE: 'restore'
};

export const AUDIT_ENTITY = {
  VEHICLE_MODEL: 'vehicle_model',
  VEHICLE_MODEL_POLL: 'vehicle_model_poll',
  ATTACHMENT: 'attachment',
  AVAILABLE_KIT: 'available_kit',
  INSTALL_KIT: 'install_kit',
  SAP_ITEM: 'sap_item',
};

export const ColumnKeys = {
  TIME: 'createdAt',
  ACTION: 'action',
  USER: 'user',
  USERID: 'userId',
  COMMENT: 'comment',
  CHANGES: 'parameters',
  KEY: 'keyValue',
  FROM: 'from',
  TO: 'to'
};

