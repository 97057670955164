import React from 'react';
import Logout from '../pages/login/Logout.js';
import VehicleCleanup from './../pages/vehicleModel/VehicleCleanup.js';
import VehicleScanningRequest from './../pages/vehicleModel/VehicleScanningRequest.js';
import Login from './../pages/login/Login';
import ResourceListing from './../pages/resource/ResourceListing';
import ResourceForm from './../pages/resource/ResourceForm';
import { SSO } from './../pages/login/SSO';
import { RouteKey } from './page-key';
import { Redirect } from 'react-router';
import AttachmentAudits from '../pages/audits/AttachmentAudits.js';
import ResourceAudits from '../pages/audits/ResourceAudits.js';
import VehicleModelAudits from '../pages/audits/VehicleModelAudits.js';
import VehicleModelPollAudits from '../pages/audits/VehicleModelPollAudits.js';
import { Role } from '../features/user/Permission.js';
import VehicleCompatibilityRouter from '../pages/vehicleModel/VehicleCompatibilityRouter.js';
import Documents from '../pages/document/Documents.js';
import HardwareAttachmentAudits from '../pages/audits/HardwareAttachmentAudits.js';

const routes = [
  {
    path: RouteKey.DEFAULT,
    exact: true,
    guardRequired:true,
    title: 'VehicleModel.VehicleLookupTool',
    component: () => <Redirect to={RouteKey.VEHICLE_COMPATIBILITY_SEARCH} />
  },
  {
    path: RouteKey.UNVERIFIED_VEHICLE,
    exact: true,
    guardRequired:true,
    title: 'VehicleModel.VehicleModelCleanup',
    component: () => <VehicleCleanup />
  },
  {
    path: RouteKey.SCANNING_REQUEST,
    exact: true,
    guardRequired:true,
    title: 'VehicleModel.VehicleModelScanningRequest',
    component: () => <VehicleScanningRequest />
  },
  {
    path: RouteKey.AUDIT_VEHICLE_MODEL,
    exact: true,
    guardRequired:true,
    title: 'Audits.AuditHistory',
    component: () => <VehicleModelAudits />
  },
  {
    path: RouteKey.AUDIT_VEHICLE_MODEL_POLL,
    exact: true,
    guardRequired:true,
    title: 'Audits.AuditHistory',
    component: () => <VehicleModelPollAudits />
  },
  {
    path: RouteKey.AUDIT_ATTACHMENT,
    exact: true,
    guardRequired:true,
    title: 'Audits.AuditHistory',
    role:[Role.ADMIN, Role.SITE_ADMIN],
    component: () => <AttachmentAudits />
  },
  {
    path: RouteKey.AUDIT_RESOURCE,
    exact: true,
    guardRequired:true,
    title: 'Audits.AuditHistory',
    role:[Role.ADMIN, Role.SITE_ADMIN],
    component: () => <ResourceAudits />
  },
  {
    path: RouteKey.AUDIT_HARDWARE_ATTACHMENT,
    exact: true,
    guardRequired:true,
    title: 'Audits.AuditHistory',
    role:[Role.ADMIN, Role.SITE_ADMIN],
    component: () => <HardwareAttachmentAudits />
  },
  {
    path: RouteKey.VEHICLE_COMPATIBILITY,
    exact: true,
    guardRequired:true,
    title: 'VehicleModel.VehicleLookupTool',
    component: () => <VehicleCompatibilityRouter />
  },
  {
    path: RouteKey.VEHICLE_COMPATIBILITY_SEARCH,
    exact: true,
    guardRequired:true,
    title: 'VehicleModel.VehicleLookupTool',
    component: () => <VehicleCompatibilityRouter />
  },
  {
    path: RouteKey.VEHICLE_MODEL,
    exact: true,
    guardRequired:true,
    title: 'VehicleModel.VehicleModel',
    component: () => <VehicleCompatibilityRouter />
  },
  {
    path: RouteKey.VEHICLE_MODEL_GROUP,
    exact: true,
    guardRequired:true,
    title: 'VehicleModel.VehicleModel',
    component: () => <VehicleCompatibilityRouter />
  },
  {
    path: RouteKey.SAP,
    exact: true,
    guardRequired:true,
    title: 'SapItem.SapDetail',
    role:[Role.ADMIN, Role.SITE_ADMIN],
    component: () => <ResourceListing />
  },
  {
    path: RouteKey.DOCUMENT,
    exact: true,
    guardRequired:true,
    title: 'Document.Title',
    role:[Role.ADMIN, Role.SITE_ADMIN],
    component: () => <Documents />
  },
  {
    path: RouteKey.SAP_FORM,
    exact: true,
    guardRequired:true,
    title: 'SapItem.SapDetail',
    role:[Role.ADMIN, Role.SITE_ADMIN],
    component: () => <ResourceForm />
  },
  {
    path: RouteKey.SSO,
    exact: true,
    title: '',
    component: () => <SSO />
  },
  {
    path: RouteKey.LOGIN,
    exact: true,
    title: 'Teletrac Navman',
    component: () => <Login />
  },
  {
    path: RouteKey.LOGOUT,
    exact: true,
    title: 'Common.Logout',
    component: () => <Logout />
  }
];

export default routes;