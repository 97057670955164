import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Panel, PanelBody } from '../../components/panel/panel.js';
import { Table, Modal, Form, Row, Col, Input, Tabs, Typography } from 'antd';
import style from './VehicleCompatibility.module.scss';
import FilterWrapper from "../../features/filterWrapper/FilterWrapper.js"
import { addToast } from "../../features/toast/toastsSlice"
import { RouteKey } from '../../config/page-key.js';
import { setPageTitle } from '../../features/header/headerSlice.js';
import Multiselect from '../../features/multiselect/Multiselect.js';

import {
	useChangePollStatusMutation, useUnVoteForScanRequestMutation, usePolls
} from '../../features/vehicleModels/vehicleModelApi.js';
import { 
	setDisplayHistory, 
	setHistoryStatusFilter, 
	setPollStatusFilter
} from "../../features/vehicleModels/vehicleModelPollFilterSlice.js"
import { getVehicleModelScanningRequestColumns, getVehicleModelPollHistoryColumns } from './VehicleModelHelper.js';
import { executeSingleAction } from '../../utils/ApiResponseHandler.js';

const VehicleScanningRequest = () => {
	const browserHistory = useHistory();

	const { Text } = Typography;
	const { i18n, t } = useTranslation();
	const dispatch = useDispatch();
	const [historyFilterForm] = Form.useForm();
	const [pollFilterForm] = Form.useForm();
	const [changePollStatus] = useChangePollStatusMutation();
	const [unVoteForScanRequest] = useUnVoteForScanRequestMutation();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [comment, setComment] = useState('');
	const [status, setStatus] = useState('');
	const [editingRecord, setEditingRecord] = useState();
	const pollStatusFilter = useSelector(state => state.vehicleModelPollFilter.pollStatusFilter);
	const historyStatusFilter = useSelector(state => state.vehicleModelPollFilter.historyStatusFilter);
	const {polls, history, isFetching} = usePolls();
	const [filteredPolls, setFilteredPolls] = useState(polls);
	const displayHistory = useSelector(state => state.vehicleModelPollFilter.displayHistory);
	const [filteredHistory, setFilteredHistory] = useState(history);

	const onTabChange = (key) => {
		dispatch(setDisplayHistory(key));
  	};
	
	useEffect(() => {
		dispatch(setPageTitle(t("VehicleModel.ScanRequests")));

		return () => {
			dispatch(setPageTitle(""));
		}
	}, []);

	useEffect(() => {
		setFilteredPolls(polls);
		setFilteredHistory(history);
		if (pollStatusFilter && pollStatusFilter.length > 0) {
			setFilteredPolls(polls.filter((value) => {
				return pollStatusFilter.indexOf(value.status) !== -1;
			}));
		} else {
			setFilteredPolls(polls);
		}
		if (historyStatusFilter && historyStatusFilter.length > 0) {
			setFilteredHistory(history.filter((value) => {
				return historyStatusFilter.indexOf(value.status) !== -1;
			}));
		} else {
			setFilteredHistory(history);
		}
	}, [polls, history, pollStatusFilter, historyStatusFilter]);

	const onFilterChange = (form, value) => {
		if (form === pollFilterForm) {
			dispatch(setPollStatusFilter(value));
		} else {
			dispatch(setHistoryStatusFilter(value));
		}
		form.submit();
	};

	const historyTabRenderer = () => {
		return (
			<Panel noPadding style={{ marginBottom: "15px" }} >
				<PanelBody>
					<Form form={historyFilterForm} layout="inline" style={{ background: "#f2f2f2" }}>
						<Row className={`${style.fullWidthRow} ${style.searchPanelRow}`}>
							<Col xs={{ span: 24 }}>
								<FilterWrapper size="large">
									<Form.Item name="status" style={{ margin: 0 }} initialValue={historyStatusFilter}>
										<Multiselect options={[
											{ value: 'APPROVED', label: t('VehicleModel.RequestStatus.APPROVED')},
											{ value: 'DECLINED', label: t('VehicleModel.RequestStatus.DECLINED')},
											{ value: 'IN_REVIEW', label: t('VehicleModel.RequestStatus.IN_REVIEW')}
										]}
										placeholder={t("VehicleModel.ScanningRequestStatus")}
										onFilterChange={value => onFilterChange(historyFilterForm, value)}
										/>
									</Form.Item>
								</FilterWrapper>
							</Col>
						</Row>
					</Form>
					<Row className={style.fullWidthRow}>
						<Col xs={{ span: 24 }} style={{ marginLeft: '10px'}}>
							<Table
							    pagination={{
									showSizeChanger: true
								}}
								size="small" columns={getVehicleModelPollHistoryColumns({
									cancelRequest: (e) => {
										unVoteForScanRequest({id: e.vehicleModel.id});
										dispatch(
											addToast({
												title:i18n.t("VehicleModel.ScanRequest"),
												type:"success",
												content: <Trans
													i18nKey={'VehicleModel.RequestCancelled'}
													components={{ primary: <Text type="success" strong />, secondary: <Text strong /> }}
												/>,
											})
										);
									}
								})}
								loading={isFetching}
								dataSource={filteredHistory} />
						</Col>
					</Row>
				</PanelBody>
			</Panel>
		);
	}

	const pollTabRenderer = () => {
		return (
			<Panel noPadding style={{ marginBottom: "15px" }} >
				<PanelBody>
					<Form form={pollFilterForm} layout="inline" style={{ background: "#f2f2f2" }}>
						<Row className={`${style.fullWidthRow} ${style.searchPanelRow}`}>
							<Col xs={{ span: 24 }}>
								<FilterWrapper size="large">
									<Form.Item name="status" style={{ margin: 0 }} initialValue={pollStatusFilter}>
										<Multiselect options={[
											{ value: 'APPROVED', label: t('VehicleModel.RequestStatus.APPROVED')},
											{ value: 'DECLINED', label: t('VehicleModel.RequestStatus.DECLINED')},
											{ value: 'IN_REVIEW', label: t('VehicleModel.RequestStatus.IN_REVIEW')}
										]}
										placeholder={t("VehicleModel.ScanningRequestStatus")}
										onFilterChange={value => onFilterChange(pollFilterForm, value)}
										/>
									</Form.Item>
								</FilterWrapper>
							</Col>
						</Row>
					</Form>
					<Row className={style.fullWidthRow}>
						<Col xs={{ span: 24 }} style={{ marginLeft: '10px'}}>
							<Table
							    pagination={{
									showSizeChanger: true
								}}
								size="small" columns={getVehicleModelScanningRequestColumns({
									approve: (e) => {
										setEditingRecord(e);
										setIsModalVisible(true);
										setStatus('APPROVED');
									},
									decline: (e) => {
										setEditingRecord(e);
										setIsModalVisible(true);
										setStatus('DECLINED');
									},
									audit: (e) => {
										browserHistory.push(RouteKey.AUDIT_VEHICLE_MODEL_POLL, { id: e.id, name: `${e.vehicleModel.make} ${e.vehicleModel.model} ${e.vehicleModel.yearOfManufacture}`})
									}
								})}
								loading={isFetching}
								dataSource={filteredPolls} />
						</Col>
					</Row>
				</PanelBody>
			</Panel>
		);
	}

	const items = [
		{
			key: 'history',
			label: t('VehicleModel.PollHistory'),
			children: historyTabRenderer()
		},
		{
			key: 'polls',
			label: t('VehicleModel.TopPolls'),
			children: pollTabRenderer()
		}
	  ];

	return (
		<div>
			<Tabs items={items} onChange={onTabChange} tabBarStyle={{ marginLeft: '20px'}} defaultActiveKey={displayHistory}/>
			<Modal
				title={t("VehicleModel.PollComment")}
				open={isModalVisible}
				okButtonProps={{ disabled: comment?.trim() === '' }}
				onOk={() => {
					if (editingRecord) {
						const payload = {
							id: editingRecord.id, 
							status: status, 
							comment: comment,
							identifier : "UpdatePollStatus" + editingRecord.id,
							loadingMessage : t("Common.Saving"),
							successMessage : t("VehicleModel.VehicleModelSaved")
						};
						executeSingleAction(payload, changePollStatus, dispatch);
					}
					setIsModalVisible(false);
					setComment('');
				}}
				onCancel={() => {
					setIsModalVisible(false);
					setComment('');
				}}>
				<Input value={comment} onChange={(event) => setComment(event.target.value)}/>
			</Modal>
		</div>
	)
}

export default VehicleScanningRequest;