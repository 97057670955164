import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_PATH } from '../../config';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { 
  VehicleModelApi,
  useGetDeviceModelsQuery,
  useGetVehicleModelsQuery
} from '../../features/vehicleModels/vehicleModelApi';
import { addToast } from "../../features/toast/toastsSlice"
import i18n from 'i18next';
import { parseErrorMessage } from '../../utils/strings';
import { checkIsAdminOrSiteAdmin } from '../user/Permission';
import { UserApi } from '../user/userApi';
import { ENTITY_TYPE_DEVICE_MODEL } from '../../pages/document/constant';

export const DocumentApi = createApi({
  reducerPath: "DocumentApi",
  baseQuery: fetchBaseQuery({ 
    baseUrl: API_PATH, 
    prepareHeaders: (headers, { getState, endpoint }) => {
      const { user } = getState();
      headers.set('Authorization', `Token token="${user?.current?.auth?.key}"`)
      return headers
  },
   }),
  tagTypes: ['document'],
  endpoints: (builder) => ({
    getDocuments: builder.query({
      query: (arg) => {
        const params = {
          embed: 'attachments',
          entityType:arg.entityType
        };

        return {
          url : `/eventattachments`,
          params
        };
      },
      providesTags: ['document'],
      async onQueryStarted({ entityType, companyId }, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled;
        const selectVehicleModels = VehicleModelApi.endpoints.getVehicleModels.select({ companyId });
        const selectDeviceModels = VehicleModelApi.endpoints.getDeviceModels.select({});

        const allVehicleModels = selectVehicleModels(getState()).data;
        const allDeviceModels = selectDeviceModels(getState()).data;

        dispatch(
          DocumentApi.util.updateQueryData('getDocuments',
            { companyId, entityType }
            , (draft) => {
              const response = JSON.parse(JSON.stringify(draft));

              let vehicleModelMap = allVehicleModels.reduce((map, item) => {
                map[item.id] = item;
                return map;
              }, {});

              let deviceModelMap = allDeviceModels.reduce((map, item) => {
                map[item.id] = item;
                return map;
              }, {});

              const formattedData = Array.from(new Set(response.map(i => i.attachment.id))).map(data => {
                const documentMatched = response.filter(i => i.attachment.id === data);
        
                const vehicleModelList = documentMatched.map(document => {
                  if (document.entityType === ENTITY_TYPE_DEVICE_MODEL) {
                    const matchedDeviceModel = deviceModelMap[document.entityId];

                    if (matchedDeviceModel) {
                      return {
                        name : matchedDeviceModel.name,
                        entityType: document.entityType,
                        type: document.type,
                        entityId: document.entityId,
                        id: document.id
                      }
                    }
                  }
                  else {
                    const matchedVehicleModel = vehicleModelMap[document.entityId];

                    if (matchedVehicleModel) {
                      return {
                        make: matchedVehicleModel.make,
                        model: matchedVehicleModel.model,
                        year: matchedVehicleModel.yearOfManufacture,
                        source: matchedVehicleModel.displaySource || matchedVehicleModel.source,
                        entityType: document.entityType,
                        type: document.type,
                        entityId: document.entityId,
                        id: document.id
                      }
                    }
                  }
                }).filter(i=>i !== undefined)
                const document = documentMatched[0];
        
                let returnObj = {
                  data: vehicleModelList,
                  name: document.attachment.name,
                  type: document.type,
                  url: document.attachment.url,
                  updatedAt: document.attachment.updatedAt,
                  attachmentId: document.attachment.id,
                  mimeType:document.attachment.mimeType,
                  id:document.id
                }

                if (document.notes) {
                  const noteObj = JSON.parse(document.notes || '{}');
                  returnObj.documentUrl = noteObj.docUrl;
                  returnObj.documentName = noteObj.docName;
                }

                return returnObj;
              })

              return formattedData;
            }
          ));
      }
    }),
    saveDocument: builder.mutation({
      query: (payload) => {
        let url = '/eventattachments/upload';
        
        const isMultipleFile = (payload.file || []).length > 1;

        if (isMultipleFile) {
          url += "s";
        }
        const formData = new FormData();

        let payloadBody ={
          entityType:payload.entityType,
        };
        
        if (payload.ids) {
          payloadBody["entityIds"] = payload.ids;
        }
        else {
          payloadBody["entityId"] = payload.id;
        }

        if (payload.file) {
          if (isMultipleFile){
            payload.file.map((file, index) => {
              formData.append('file'+index, file);
            });
          }
          else{
            formData.append('file', payload.file[0]);
          }
        }
        else if (payload.attachmentId) {
          payloadBody["attachmentId"] = payload.attachmentId;
        }

        if (payload.documentUrl) {
          payloadBody["documentUrl"] = payload.documentUrl;
        }

        if (payload.docType) {
          payloadBody["docType"] = payload.docType;
        }

        if (payload.comment) {
          payloadBody["comment"] = payload.comment;
        }

        formData.append('uploadParams', JSON.stringify(payloadBody));

        return {
          url,
          method: 'POST',
          body: formData
        }
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedData } = await queryFulfilled;
          const selectUser = UserApi.endpoints.getUserInfo.select({ userId: getState().user?.current?.id });
          const currentUser = selectUser(getState()).data;

          const userKey = getState().user?.current?.auth?.key;
          const isAdminOrSiteAdmin = checkIsAdminOrSiteAdmin(currentUser);

          (updatedData || []).forEach(doc=> {
            dispatch(
              VehicleModelApi.util.updateQueryData('getVehicleModelCompatibilities',
                { companyId: currentUser?.companyId, userKey, embed: isAdminOrSiteAdmin ? "devicemodels,pids,kits" : "devicemodels,pids" }
                , (draft) => {
                  const cacheData = draft.find(i => i.id === doc.entityId);
  
                  if (cacheData) {
                    cacheData.documentId = doc.id;
                    cacheData.attachmentId = doc.attachment?.id;

                    if (doc.notes) {
                      const noteObj = JSON.parse(doc.notes||'{}');
                      cacheData.documentLink = noteObj.docUrl;
                    }
                    else{
                      delete cacheData["documentLink"];
                    }
                  }
                })
            )
          })
        }
        catch {

        }
      },
      invalidatesTags: (result, error, arg)=>{
        return result ? ['document'] : [];
      },
    }),
    deleteDocument: builder.mutation({
      query: (payload) => {
        let url = "/eventattachments/unlink";

        let parsedPayload ={
          entityType:payload.entityType,
          attachmentId:payload.attachmentId
        };

        if (payload.ids) {
          parsedPayload["entityIds"]= payload.ids;
        }

        if (payload.id) {
          parsedPayload["entityId"]= payload.id;
        }

        if (payload.comment) {
          parsedPayload["comment"]= payload.comment;
        }

        return {
          url,
          method:'PUT',
          body: parsedPayload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      async onQueryStarted({ ids }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedData } = await queryFulfilled;
          const selectUser = UserApi.endpoints.getUserInfo.select({ userId: getState().user?.current?.id });
          const currentUser = selectUser(getState()).data;

          const userKey = getState().user?.current?.auth?.key;
          const isAdminOrSiteAdmin = checkIsAdminOrSiteAdmin(currentUser);

          (ids || []).forEach(id=> {
            dispatch(
              VehicleModelApi.util.updateQueryData('getVehicleModelCompatibilities',
                { companyId: currentUser?.companyId, userKey, embed: isAdminOrSiteAdmin ? "devicemodels,pids,kits" : "devicemodels,pids" }
                , (draft) => {
                  const cacheData = draft.find(i => i.id === id);
  
                  if (cacheData) {
                    delete cacheData["documentId"];
                    delete cacheData["attachmentId"];
                    delete cacheData["documentLink"];
                  }
                })
            )
          })
        }
        catch {

        }
      },
      invalidatesTags: (result, error, arg)=>{
        return (!arg.preventInvalidate) ? ['document'] : [];
      },
    })
  })
})

export const { 
  useGetDocumentsQuery,
  useDeleteDocumentMutation,
  useSaveDocumentMutation
} = DocumentApi

export const fetchDocument = async (attachmentId, dispatch, userKey) => {
  const url = "/eventattachments/view?attachmentId=" + attachmentId;
  const defaultParams = {
    attachmentId: attachmentId
  }

  dispatch(
    addToast({
      key: attachmentId,
      type: "loading",
      content: i18n.t("Document.DocumentLoadingInProgress")
    })
  );

  const response = await fetch(`${API_PATH + url}`, {
    method: 'GET',
    baseUrl: API_PATH,
    headers: {
      Authorization: `Token token="${userKey}"`
    },
    ...defaultParams
  });

  if (response.status !== 200) {
    response.json().then(e=>{
      dispatch(addToast({
        key: attachmentId,
        type: "error",
        content: parseErrorMessage(e)
      }));
    })
  }
  else {
    const reponseTxt = await response.text();
    dispatch(addToast({
      key: attachmentId,
      type: "success",
      content: i18n.t("Document.DocumentLoaded")
    }));
    return reponseTxt;
  }
}

export const useGetDocument = (companyId, entityType) => {
  const { data: vehicleModels, isFetching } = useGetVehicleModelsQuery({ companyId: companyId }, {pollingInterval: 1800000, skip:companyId === undefined});
  const { data: deviceModels, isFetching : isFetchingDeviceModels } = useGetDeviceModelsQuery({}, { pollingInterval: 3600000 });
  const { data: documents, isFetching : isFetchingDocument } = useGetDocumentsQuery({companyId: companyId, entityType:entityType}, {pollingInterval: 1800000, skip:companyId === undefined || vehicleModels === undefined || deviceModels === undefined});
  
  return useMemo(() => {
    const isDocumentDataLoaded = (documents || []).length !== 0 && documents[0].url !== undefined;
    
    if (vehicleModels && (isDocumentDataLoaded || documents !== undefined && documents.length === 0)){
      return {data:documents, isFetching:isFetching || isFetchingDocument};
    }
    else {
      return {isFetching:true}
    }
  }, [documents, vehicleModels, isFetching, isFetchingDocument, deviceModels, isFetchingDeviceModels]);
};

export const executeSaveDocument = (payload, deleteDocument, saveDocument, deletePayload, dispatch) => {
  return new Promise(async (resolve, reject) => {
		const key = "Document" + Date.now().toString();
    
    dispatch(
      addToast({
        key,
        type: "loading",
        content: i18n.t("Common.Saving") + "...",
      })
    );

    if (deletePayload) {
      const delPayload = {
        ...deletePayload,
        preventInvalidate:true,
      }
  
      await deleteDocument(delPayload);
    }

    saveDocument(payload)
      .unwrap()
      .then(() => {
        dispatch(
          addToast({
            key,
            type: "success",
            content: i18n.t("Document.DocumentSaved"),
          })
        );
        resolve();
      })
      .catch((error) => {
        if (error) {
          dispatch(
            addToast({
              key,
              type: "error",
              content: parseErrorMessage(error.data),
            })
          );
        }
        reject(error);
      });
  });
};

export const executeDeleteDocument = (payload, deleteDocument, dispatch) =>{
  return new Promise((resolve, reject) => {
		const key = "Document" + Date.now().toString();

  dispatch(addToast({
    key,
    type: "loading",
    content: i18n.t("Common.Deleting") + "...",
  }));

  deleteDocument(payload)
    .unwrap()
    .then(() => {
      dispatch(addToast({
        key,
        type: "success",
        content: i18n.t("Document.DocumentDeleted"),
      }));
      resolve();
    })
    .catch((error) => {
      if (error) {
        dispatch(addToast({
          key,
          type: "error",
          content: parseErrorMessage(error.data),
        }));
      }
      reject(error);
    })
  });
}