import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';

import { useCurrentCompany } from '../../features/company/companySlice.js';

import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../features/header/headerSlice';
import InstallationDocumentListing from './InstallationDocumentListing.js';
import HardwareDocumentListing from './HardwareDocumentListing.js';

const Documents = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const items = [
		{
			key: t("Document.VehicleInstallation"),
			label: t("Document.VehicleInstallation"),
			children: <InstallationDocumentListing />,
		},
		{
			key: t("Document.HardwareInstallation"),
			label: t("Document.HardwareInstallation"),
			children: <HardwareDocumentListing />,
		}
	];

	useEffect(() => {
		dispatch(setPageTitle(t("Document.Title")));

		return () => {
			dispatch(setPageTitle(""));
		}
	}, [t]);

	return (
		<div>
			<Tabs defaultActiveKey="1" items={items} tabBarStyle={{ marginLeft: '20px' }} />
		</div>
	)
}

export default Documents;