import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_PATH } from '../../config';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { getRolePermission, checkIsAdminOrSiteAdmin } from './Permission';

export const UserApi = createApi({
  reducerPath: "UserProfileApi",
  baseQuery: fetchBaseQuery({ 
    baseUrl: API_PATH, 
    prepareHeaders: (headers, { getState, endpoint }) => {
      const { user } = getState();
      headers.set('Authorization', `Token token="${user?.current?.auth?.key}"`)
      return headers
  },
   }),
  tagTypes: ['userProfile'],
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: (arg) => {
        const params = {
          pruning: 'ALL',
          embed: 'associations,fleets,locations,user_session'
        };

        return {
          url : `/users/${arg.userId}`,
          params
        };
      },
      providesTags: ['userProfile'],
    }),
    getUserPreference: builder.query({
      query: (arg) => {
        return {
          url :`/users/${arg.userId}/config/settings`
        };
      },
      providesTags: ['userProfile'],
    })
  })
})

export const { 
  useGetUserInfoQuery,
  useGetUserPreferenceQuery
} = UserApi

export const useUserInfo = () => {
  const userId = useSelector(state => state?.user?.current?.id);
  const { data } = useGetUserInfoQuery({userId}, {skip:userId=== undefined});

  return useMemo(() => {
    const rolePermission = getRolePermission(data);
    const isAdminOrSiteAdmin = checkIsAdminOrSiteAdmin(data);
    
    return { ...data, permission :rolePermission, isAdminOrSiteAdmin, isUserAllowed : rolePermission.length !== 0 };
  }, [data]);
};

export const useUserPreference = () => {
  const userId = useSelector(state => state?.user?.current?.id);
  const { data } = useGetUserPreferenceQuery({userId}, {skip:userId === undefined});
  
  return data;
};