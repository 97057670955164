import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Panel, PanelBody } from './../../components/panel/panel.js';
import { Table, Skeleton, Space, 
	Form, Button, Row, Col, Spin, Typography, 
	notification, Tooltip, Alert, Tabs, Card 
} from 'antd';
import style from './VehicleCompatibility.module.scss';
import { addToast } from "../../features/toast/toastsSlice"
import FilterWrapper from "../../features/filterWrapper/FilterWrapper"

import { exportFile } from '../../features/fileExport/ExcelFile';
import { getExportOptionButtons, getVehicleModelDataColumnsV2, getMMYCombination, getExportFilename } from './VehicleModelHelper.js'
import {
	useVehicleCompatibility, 
	getVehicleModelDocumentById,
	useGrouppedVehicleCompatibility
} from '../../features/vehicleModels/vehicleModelApi';
import { useCurrentCompany } from '../../features/company/companySlice.js';

import {
	CloudDownloadOutlined,
	SearchOutlined,
	DownOutlined,
	UpOutlined,
	MinusCircleOutlined,
	CheckCircleOutlined,
	AuditOutlined,
	RightOutlined,
	EditOutlined,
	InfoCircleFilled,
	MergeCellsOutlined,
	FileTextOutlined,
	ScanOutlined,
	PlusCircleOutlined
} from '@ant-design/icons';

import VehicleCompatibilityInfoModal from "./VehicleCompatibilityInfoModal.js";
import VehicleCompatibilityResourceModal from './VehicleCompatibilityResourceModal.js';
import VehicleCompatibilityDetailModal from './VehicleCompatibilityDetailModal.js';
import VehicleCompatibilitySearchModal from "./VehicleCompatibilitySearchModal.js";
import VehicleCompatibilityMergeModal from "./VehicleCompatibilityMergeModal.js";
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitle } from '../../features/header/headerSlice';
import { ResponsiveButton } from '../../features/responsive';
import { RouteKey } from '../../config/page-key.js';
import { Can, useCan } from "../../features/user/Can.js";
import { Permission, Role } from '../../features/user/Permission.js';
import Multiselect from '../../features/multiselect/Multiselect.js';
import { Buttons } from '../../components/control/Buttons.js';
import {
	setSearchFilters,
	setPageNumber
} from "../../features/vehicleModels/vehicleModelFilterSlice.js"
import { fetchDocument, useGetDocument } from '../../features/document/documentApi';
import { useUserKey } from '../../features/user/ssoSlice';
import { isValidHttpUrl } from '../../utils/strings.js';
import { KitType } from './constant.js';
import { useMediaQuery } from 'react-responsive';
import { useCompanies } from '../../features/company/companyApi.js';
import { VehicleMakeModelYearFormModal } from './VehicleMakeModelYearFormModal.js';
import { ENTITY_TYPE_DEVICE_MODEL } from '../document/constant.js';
import { CsvLink } from '../../features/fileExport/CsvLink.js';

const { Title, Text } = Typography;

const VehicleCompatibility = () => {
	const history = useHistory();
	const csvLinkRef = useRef(null);
	const { i18n, t } = useTranslation();
	const currentCompany = useCurrentCompany();
	const dispatch = useDispatch();
	const [csvData, setCsvData] = useState([]);
	const userKey = useUserKey();
	const can = useCan();
	const isMobile = useMediaQuery({ maxWidth: 767 })

	const [showCsvSearch, setShowCsvSearch] = useState(false);
	const [infoModelOpen, setInfoModalOpen] = useState(false);
	const [vehicleCompatibilityDetailModal, setVehicleCompatibilityDetailModal] = useState(false);
	const [vehicleMergeModal, setVehicleMergeModal] = useState(false);
	const [newVehicleModelModal, setNewVehicleModelModal] = useState(false);
	const [selectedVehicleCompatibility, setSelectedVehicleCompatibility] = useState();
	const [filterForm] = Form.useForm();
	const { vehicleCompatibility, isFetching: isVehicleCompatibilityLoading } = useGrouppedVehicleCompatibility(currentCompany.id);
	const [filterVehicleCompatibility, setFilterVehicleCompatibility] = useState(vehicleCompatibility);
	
	const { vehicleCompatibility : rawVehicleCompatibility } = useVehicleCompatibility(currentCompany.id);
	const currentPage = useSelector(state => state.vehicleModelFilter.pageNumber);
	const searchObj = useSelector(state => state.vehicleModelFilter.searchFilter);
	const companies = useCompanies();
	const [selectedKit, setSelectedKit] = useState();
	const { data: documents, isFetching : isFetchingDocument } = useGetDocument(currentCompany.id, ENTITY_TYPE_DEVICE_MODEL);

	const handlePageChange = (page) => {
		dispatch(setPageNumber(page));
	};

	useEffect(() => {
		if (history?.location?.state) {
			filterForm.setFieldsValue(history?.location?.state);
			let obj = { ...history?.location?.state };

			if (vehicleCompatibility && history?.location?.state?.records) {
				obj.recordIds = history?.location?.state?.records;
			}
			onFinish(obj);

			history.replace({ ...history.location, state: undefined });
		}
	}, [history?.location?.state]);

	useEffect(() => {
		dispatch(setPageTitle(t("VehicleModel.VehicleLookupTool")));

		return () => {
			dispatch(setPageTitle(""));
		}
	}, [t]);

	useEffect(() => {
		setFilterVehicleCompatibility(getFilteredRecord(vehicleCompatibility));
	}, [searchObj, vehicleCompatibility, t]);

	const getFilteredRecord = (r) => {
		let filteredData = r || [];
		if (searchObj) {
			if (searchObj.make && searchObj.make.length !== 0) {
				filteredData = filteredData.filter(i => searchObj.make.includes(i.make?.trim()));
			}

			if (searchObj.model && searchObj.model.length !== 0) {
				filteredData = filteredData.filter(i => searchObj.model.includes(i.model?.trim()));
			}

			if (searchObj.year && searchObj.year.length !== 0) {
				filteredData = filteredData.filter(i => searchObj.year.includes(i.yearOfManufacture) || searchObj.year.some(target => i.yearList?.includes(target)));
			}

			if (searchObj.isSupported && searchObj.isSupported.length !== 0) {
				filteredData = filteredData.filter(i => searchObj.isSupported?.includes(i.isSupported ?? -1));
			}

			if (searchObj.recordIds && searchObj.recordIds.length !== 0) {
				filteredData = filteredData.filter(i => searchObj.recordIds.includes(i.id));
			}
		}

		return filteredData;
	}

	const onFilterChange = value => {
		filterForm.setFieldValue("recordIds", undefined);
		filterForm.submit();
	};

	const onCsvFilterChange = (isSupported) => {
		let obj = { ...searchObj };
		obj.isSupported = isSupported;
		filterForm.setFieldValue("isSupported", isSupported);
		onFinish(obj);
	}

	const fullValues = useMemo(() => {
		return getMMYCombination(filterForm, vehicleCompatibility);

	}, [vehicleCompatibility,
		filterForm?.getFieldValue("year"),
		filterForm?.getFieldValue("make"),
		filterForm?.getFieldValue("model")]);

	const handleExportExcel = () => {
		let filteredData = getFilteredRecord(rawVehicleCompatibility);

		if (isIOSMobile() && (filteredData || []).length >= 10000) {
			notification.error({
				placement: 'topRight',
				description: t("ValidationErrors.DataSizeTooLargeError", { number: 10000 }),
				style: { background: "#fff2f0" }
			})
			return;
		}

		const extractedPids = [...new Set(filteredData.flatMap(obj => obj.supportedPid))];
		const pidCategories = Array.from(new Set(extractedPids.map(i => i.category.toUpperCase() === "UNKNOWN" ? "Others" : i.category).sort((a, b) => {
			if (a.toUpperCase() === "OTHERS") return 1;
			if (b.toUpperCase() === "OTHERS") return -1;
			return 0;
		})))

		const parsedData = filteredData.map((data) => {
			let rowObj = {
				[t('VehicleModel.Make')]: data.make,
				[t('VehicleModel.Model')]: data.model,
				[t('VehicleModel.Year')]: data.yearOfManufacture,
				[t('VehicleModel.Source')]: data.source,
				[t('VehicleModel.DbcKey')]: data.dbcFileKey || "-",
				[t('VehicleModel.Vendor')]: data.deviceType || "-",
				[t('VehicleModel.Supported')]: data.isSupported ? t("VehicleModel.Yes") : (data.isSupported === undefined ? t("VehicleModel.Unknown") : t("VehicleModel.No")),
				[t('VehicleModel.Verified')]: data.verified ? t("VehicleModel.Yes") : t("VehicleModel.No")
			}

			pidCategories.forEach(categoryName => {
				const pids = (data.grouppedPid || {})[categoryName];
				if (pids && pids.length !== 0) {
					rowObj[categoryName] = Array.from(pids).join('\n');
				}
				else {
					rowObj[categoryName] = "-"
				}
			})

			return rowObj;
		});

		exportFile(parsedData, {
			width: Array.apply(null, { length: Object.keys(parsedData[0]).length }).map(_ => ({ wch: 25 })),
			title: t('VehicleModel.ExportFileName')
		}, t("Common.ExportWarning"))
	}

	const onFinish = (e) => {
		dispatch(setSearchFilters({
			...searchObj,
			...{
				make: e.make,
				model: e.model,
				year: e.year,
				isSupported: e.isSupported,
				recordIds: e.recordIds
			}
		}));
		dispatch(setPageNumber(1));
	};

	const isIOSMobile = () => {
		return ([
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod'
		].includes(navigator.userAgentData?.platform || navigator.platform)
			|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)) && isMobile;
	}

	useEffect(() => {
		csvData?.length && csvLinkRef?.current?.link?.click();
	}, [csvData]);

	const handleExportCSV = () => {
		let filteredData = getFilteredRecord(rawVehicleCompatibility);
		const extractedPids = [...new Set(filteredData.flatMap(obj => obj.supportedPid))];
		const pidCategories = Array.from(new Set(extractedPids.map(i => i.category.toUpperCase() === "UNKNOWN" ? "Others" : i.category).sort((a, b) => {
			if (a.toUpperCase() === "OTHERS") return 1;
			if (b.toUpperCase() === "OTHERS") return -1;
			return 0;
		})))

		setCsvData(
			filteredData.map((data) => {
				let parsedData = {
					[t('VehicleModel.Make')]: `"${data.make}"`,
					[t('VehicleModel.Model')]: `"${data.model}"`,
					[t('VehicleModel.Year')]: data.yearOfManufacture,
					[t('VehicleModel.Source')]: data.source,
					[t('VehicleModel.DbcKey')]: data.dbcFileKey || "-",
					[t('VehicleModel.Vendor')]: data.deviceType || "-",
					[t('VehicleModel.Supported')]: data.isSupported ? t("VehicleModel.Yes") : (data.isSupported === undefined ? t("VehicleModel.Unknown") : t("VehicleModel.No")),
					[t('VehicleModel.Verified')]: data.verified ? t("VehicleModel.Yes") : t("VehicleModel.No")
				}

				pidCategories.forEach(categoryName => {
					const pids = (data.grouppedPid || {})[categoryName];
					if (pids && pids.length !== 0) {
						parsedData[categoryName] = '"' + Array.from(pids).join('\r\n') + '"';
					}
					else {
						parsedData[categoryName] = "-"
					}
				})

				return parsedData;
			})
		);
	}

	const onViewKit = (kit, vehicleModel) => {
		setSelectedKit({ kit, vehicleModel })
	}

	return (
		<div className={style.mainPanel}>
			<CsvLink data={csvData || []} ref={csvLinkRef} fileName={getExportFilename({
				entityName: t('VehicleModel.ExportFileName'),
				extension: ".csv"
			})} />
			{searchObj?.recordIds && <Row >
				<Col xs={{ span: 24 }} lg={{ span: 14 }} xl={{ span: 10 }} xxl={{ span: 8 }}>
					<Card className={style.csvSearchPanel}>
						<Row>
							<Col xs={{ span: 4 }} className={style.centerAlign}>
								<Button disabled={true} style={{ background: "#e2f7fc", borderColor: "transparent", width: "60px", height: "60px" }}
									icon={<FileTextOutlined style={{ color: "#000", fontSize: "30px" }} />} />
							</Col>
							<Col xs={{ span: 20 }} className={style.centerAlign}>
								<Row className={style.summaryTable}>
									<Col xs={{ span: 12 }} lg={{ span: 6 }} className={style.centerAlign}>
										<Button type="text" className={style.summaryCountBtn} disabled>
											<Text strong>{filterVehicleCompatibility.length}</Text>
											<Title level={5} style={{ marginTop: 0, textAlign: "center" }}>{t("VehicleModel.LoadedModel")}</Title>
										</Button>
									</Col>
									<Col xs={{ span: 12 }} lg={{ span: 6 }} className={style.centerAlign}>
										<Button type="text" className={style.summaryCountBtn} onClick={() => {
											onCsvFilterChange([true])
										}}>
											<Text strong>{filterVehicleCompatibility.filter(i => i.isSupported).length}</Text>
											<Title level={5} style={{ marginTop: 0, textAlign: "center" }}>{t("VehicleModel.Supported")}</Title>
										</Button>
									</Col>
									<Col xs={{ span: 12 }} lg={{ span: 6 }} className={style.centerAlign}>
										<Button type="text" className={style.summaryCountBtn} onClick={() => {
											onCsvFilterChange([false])
										}}>
											<Text strong>{filterVehicleCompatibility.filter(i => !i.isSupported).length}</Text>
											<Title level={5} style={{ marginTop: 0, textAlign: "center" }}>{t("VehicleModel.Unsupported")}</Title>
										</Button>
									</Col>
									<Col xs={{ span: 12 }} lg={{ span: 6 }} className={style.centerAlign}>
										<Button type="text" className={style.summaryCountBtn} onClick={() => {
											onCsvFilterChange([-1])
										}}>
											<Text strong>{filterVehicleCompatibility.filter(i => i.isSupported === undefined).length}</Text>
											<Title level={5} style={{ marginTop: 0, textAlign: "center" }}>{t("VehicleModel.Unknown")}</Title>
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>}
			<Panel noPadding style={{ marginBottom: "15px", boxShadow: "unset" }}>
				<PanelBody>
					<Form form={filterForm} layout="inline" onFinish={onFinish} style={{ background: "#f2f2f2" }}>
						<FilterWrapper size="medium" spacing={10} containerStyle={{ padding: 10 }}>
							<Form.Item name="make" style={{ margin: 0 }} initialValue={searchObj?.make || []}>
								<Multiselect
									options={fullValues.make}
									onFilterChange={onFilterChange}
									placeholder={t("VehicleModel.Make")}
									isLoading={isVehicleCompatibilityLoading} />
							</Form.Item>
							<Form.Item name="model" style={{ margin: 0 }} initialValue={searchObj?.model || []}>
								<Multiselect
									options={fullValues.model}
									onFilterChange={onFilterChange}
									placeholder={t("VehicleModel.Model")}
									isLoading={isVehicleCompatibilityLoading} />
							</Form.Item>
							<Form.Item name="year" style={{ margin: 0 }} initialValue={searchObj?.year || []}>
								<Multiselect
									options={fullValues.year}
									onFilterChange={onFilterChange}
									placeholder={t("VehicleModel.Year")}
									isLoading={isVehicleCompatibilityLoading} />
							</Form.Item>
							<Form.Item name="isSupported" style={{ margin: 0 }} initialValue={searchObj?.isSupported || []}>
								<Multiselect
									options={fullValues.supportList}
									onFilterChange={onFilterChange}
									placeholder={t("VehicleModel.SupportStatus")}
									isLoading={isVehicleCompatibilityLoading} />
							</Form.Item>
							<Space>
								<ResponsiveButton
									loading={isVehicleCompatibilityLoading}
									onClick={() => { setShowCsvSearch(prev => !prev) }}
									title={t("VehicleModel.CsvSearch")}
									icon={<SearchOutlined />} />
								<Can requiredPermission={[Permission.COMMON_EDIT]}>
									<ResponsiveButton
										onClick={() => {
											setNewVehicleModelModal(true);
										}}
										icon={<PlusCircleOutlined />}
										loading={isVehicleCompatibilityLoading}
										title={t("VehicleModel.AddVehicleModel")} />
								</Can>
								<Can requiredPermission={[Permission.EXPORT]}>
									<Buttons moreButtons={getExportOptionButtons({ handleExportExcel, handleExportCSV, isLoading: isVehicleCompatibilityLoading })}
										noMargin={true}>
										<ResponsiveButton loading={isVehicleCompatibilityLoading} disabled={filterVehicleCompatibility?.length === 0}
											title={t("Common.Export")}
											icon={<CloudDownloadOutlined />} />
									</Buttons>
								</Can>
							</Space>
						</FilterWrapper>
					</Form><Row className={style.fullWidthRow}>
						<Col xs={{ span: 24 }}>
							{isVehicleCompatibilityLoading || vehicleCompatibility === undefined ? <Skeleton active /> :
								<Table
									rowKey={(record) => record.rowId}
									className={style.vehicleModelTable}
									columns={getVehicleModelDataColumnsV2(companies)}
									dataSource={filterVehicleCompatibility}
									pagination={{
										current: currentPage,
										onChange: handlePageChange,
										showSizeChanger: true
									}}
									expandIcon={(props) => {
										if (props.expanded) {
											return <a style={{ color: 'black' }} onClick={e => {
												props.onExpand(props.record, e);
											}}><UpOutlined /></a>
										} else {
											return <a style={{ color: 'black' }} onClick={e => {
												props.onExpand(props.record, e);
											}}><DownOutlined /></a>
										}
									}}
									expandable={{
										onExpand: (expand, record) => {
											if (expand) {
												record.groupModel.filter(r => !!r.documentId).forEach(r => {
													dispatch(getVehicleModelDocumentById.initiate({ id: r.id }));
												})
											}
										},
										expandedRowRender: (record) => {
											const tabItems = record.groupModel.map(m => {

												let canDataArr = [];

												const groupedPidData = (m?.supportedPid || []).reduce((acc, item) => {
													const key = `${item.canId}-${item.dbcKey || ""}`;

													if (!acc[key]) {
														acc[key] = [];
													}

													acc[key].push(item);

													return acc;
												}, {});

												let deviceModelIds = [];
												Object.keys(groupedPidData).map((d) => {
													const [canId, dbcKey] = d.split('-');
													const pids = groupedPidData[d].reduce((acc, pid) => {
														const key = pid.deviceModelName;

														if (!acc.has(key)) {
															acc.set(key, []);
														}

														acc.get(key).push(pid);
														return acc;
													}, new Map());
													
													deviceModelIds = [...deviceModelIds, ...Array.from(new Set(groupedPidData[d].map(i=>i.deviceModelId)))]

													let canData = {}

													if (m.documentInfo?.installManualInfo) {
														canData = { ...m.documentInfo?.installManualInfo[`can${canId}Input`] };

														if (m.documentInfo?.installManualInfo?.canConnection[`ecuConnectorWire`]) {
															const canDescription = (canData.canInput?.canHigh || "") + (canData.canInput?.canLow || "");
															canData.hasInstallationDetail = true;
															const isOBDII = m.documentInfo?.installManualInfo?.canConnection[`ecuConnectorWire`]?.includes("OBD connector");
															canData.isCanCliqRequired = isOBDII && !canDescription?.includes("pin 6") && !canDescription?.includes("pin 14");
														}
													}
													canData.pids = pids;
													canData.id = canId;
													canData.dbcKey = dbcKey;

													canDataArr.push(canData)
												});

												const canCliqId = canDataArr.find(can => can.isCanCliqRequired);
												let documentMap = Object.fromEntries((documents || []).filter(i=>(i.data||[]).length > 0).map(item => [item.data[0].entityId, item]))
												return {
													key: m.id,
													label: m.displaySource.toUpperCase() + " - " + m.priority,
													children: (
														<Spin spinning={!!m.documentId && m.isDocumentFetched === undefined}>
															<div className={style.vehicleModelDetailCell}>
																<Row>
																	<Col xs={{ span: 24 }}>
																		<Title level={5} style={{fontSize:18}}>{t("VehicleModel.InstallationData")}</Title>
																	</Col>
																</Row>
																<Row>
																	<Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 5 }} style={{ padding: 10 }}>
																		<Space direction={"vertical"} size={8}>
																			<Text type="secondary" className={style.subHeader}>{t("VehicleModel.SupportedDevices")}</Text>
																			<Space direction={"horizontal"} size={8} wrap>
																				{(canDataArr || []).length !== 0 ? canDataArr.map(cd => {
																					return (
																						[...cd.pids.keys()].map(hw => {
																							return <div className={style.smallCard}>{hw}</div>
																						})
																					)
																				}) : " - "}
																			</Space>
																		</Space>
																	</Col>
																	<Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }} style={{ padding: 10 }}>
																		<Space direction={"vertical"} size={15}>
																			<Text type="secondary" className={style.subHeader}>{t("VehicleModel.Hardware")}</Text>
																			<Row>
																				{(canDataArr || []).length !== 0 ? canDataArr.map(cd => {
																					return (
																						<Col xs={{ span: 24 / canDataArr.length }}>
																							{canDataArr.length > 1 &&
																								<Text strong>{t("VehicleModel.CanInput", { index: cd.id })}&nbsp;&nbsp;</Text>}
																							{cd.hasInstallationDetail && <Space direction={"vertical"} size={16}>
																								<Text className={style.grayBg}>{cd.canHigh}</Text>
																								<Text className={style.grayBg}>{cd.canLow}</Text>
																							</Space>}
																							{!cd.hasInstallationDetail &&
																								<>-</>
																							}
																						</Col>
																					)
																				}) : " - "}
																			</Row>
																		</Space>
																	</Col>
																	<Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }} style={{ padding: 10 }}>
																		<Space direction={"vertical"} size={10}>
																			<Text type="secondary" className={style.subHeader}>{t("VehicleModel.Notes")}</Text>
																			{(canDataArr || []).length > 1 && <Alert message={t("VehicleModel.CustomNote.OneCanAtATime")} type="info" />}
																			{canCliqId !== undefined &&
																				<>
																					<Alert type="warning" message={t("VehicleModel.CustomNote.PlugAndPlayNotSupported", { id: canCliqId.id })} />
																					<Alert type="warning" message={t("VehicleModel.CustomNote.CanCliqRequired", { id: canCliqId.id })} />
																				</>
																			}
																			{m.documentId &&
																				<Alert message={t("VehicleModel.CustomNote.InstallationRequired")} type="info" />
																			}
																			{m?.extras?.note &&
																			    <Alert message={m?.extras?.note} type="info" />
																			}
																			{!m.documentId && !m?.extras?.note && !canCliqId && (canDataArr || []).length <= 1 &&
																				<Text> - </Text>
																			}
																		</Space>
																	</Col>
																	<Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }} style={{ padding: 10, display:"flex", flexDirection:"column" }}>
																		<Text type="secondary" className={style.subHeader}>{t("VehicleModel.Documentation")}</Text>
																		<Space direction={"vertical"} size={0}>
																			{m.documentId || m.documentLink || deviceModelIds.length > 0 ? <>
																				{m.documentId && <Button style={{ padding: 0 }} onClick={() => {
																					var win = window.open();
																					fetchDocument(m.documentId, dispatch, userKey).then(e => {
																						if (isValidHttpUrl(e)) {
																							win.location = e;
																						}
																						else {
																							win.document.write(e);
																						}
																					}).catch(e => win.close())
																				}} type="link" className={style.infoBtnIcon}
																					icon={<InfoCircleFilled  onClick={(e) => {
																						setInfoModalOpen(true);
																						e.stopPropagation();
																					}} className={style.infoIcon} />}>
																					{t("VehicleModel.InstallationSupport")}
																				</Button>}
																				{m.documentLink && <Button style={{ padding: 0 }} onClick={() => {
																					var win = window.open();
																					win.location = m.documentLink;
																				}} type="link" className={style.infoBtnIcon}
																					icon={<InfoCircleFilled onClick={(e) => {
																						setInfoModalOpen(true);
																						e.stopPropagation();
																					}} className={style.infoIcon} />}>
																					{t("VehicleModel.InstallationSupportLink")}
																				</Button>}
																				{
																					deviceModelIds.filter(i => documentMap[i]).map(d => {
																						let document = documentMap[d];

																						return <>
																							{document.documentUrl && document.documentName && <Button style={{ padding: 0 }} onClick={() => {
																								var win = window.open();
																								win.location = document.documentUrl;
																							}} type="link" className={style.infoBtnIcon}
																								icon={<InfoCircleFilled className={style.infoIcon} />}>
																								{document.documentName}
																							</Button>}
																							{!!document.mimeType && <Button style={{ padding: 0 }} onClick={() => {
																								var win = window.open();
																								fetchDocument(document.id, dispatch, userKey).then(e => {
																									if (isValidHttpUrl(e)) {
																										win.location = e;
																									}
																									else {
																										win.document.write(e);
																									}
																								}).catch(e => win.close())
																							}} type="link" className={style.infoBtnIcon}
																								icon={<InfoCircleFilled className={style.infoIcon} />}>
																								{document.name}
																							</Button>}
																						</>
																					})
																				}
																			</> : <Text> - </Text>}
																		</Space>
																	</Col>
																</Row>
																{(canDataArr || []).length !== 0 && <Row>
																	<Col xs={{ span: 24 }}>
																		<Space direction='vertical'>
																			<Title level={5} style={{fontSize:18}}>{t("VehicleModel.SupportedData")}</Title>
																		</Space>
																	</Col>
																</Row>}
																{
																	canDataArr.map(can => {
																		return (
																			<Row>

																				{can.pids?.size > 0 ?
																					([...can.pids.keys()].map(key => {
																						const pids = can.pids.get(key) || [];
																						return (
																							<>
																								<Col xs={{ span: 24 }} style={{ marginTop: 8, marginBottom:10 }}>
																									<Text type={"secondary"} strong>{t("VehicleModel.CanBus") + " " + can.id + " {" + key + "}"}</Text>
																								</Col>
																								<Col xs={{ span: 24 }}>
																									<Space wrap style={{ marginBottom: '8px' }}>
																										{
																											(pids.filter(pid=> pid.category.toUpperCase() !== "UNKNOWN").map(pid => {
																												return (
																													<Button shape="round" className={style.compatibilityInfolbl}
																														icon={pid.category.toUpperCase() === "UNKNOWN" ? <MinusCircleOutlined style={{ color: "grey" }} /> : <CheckCircleOutlined style={{ color: "green" }} />}>
																														{pid.description}
																													</Button>
																												)
																											}))
																										}
																									</Space>
																								</Col>
																							</>
																						)
																					})) : <Col xs={{ span: 24 }}>-</Col>}
																			</Row>
																		);
																	})
																}
															</div>
															<Row style={{marginTop:10}}>
																<Col xs={{ span: 24 }} className={style.showMorePanel}>
																	<div>
																		<Can requiredPermission={[Permission.AUDIT]}>
																			<ResponsiveButton type="link" onClick={() => {
																				let records = (record.fullGroupModels || []).filter(i=>i.source === m.source).map(i=>i.id).filter((value, index, self) => self.indexOf(value) === index)
																				
																				history.push(RouteKey.AUDIT_VEHICLE_MODEL, { id: records, name: `${m.make} ${m.model}` })
																			}}
																				title={t("Audits.Audits")}
																				icon={<AuditOutlined />} />
																		</Can>
																		<Can requiredPermission={[Permission.COMMON_EDIT]}>
																			<ResponsiveButton type="link" onClick={() => { 
																				let records = (record.fullGroupModels || []).filter(i=>i.source === m.source).map(i=>i.id).filter((value, index, self) => self.indexOf(value) === index)
																				if (records.length > 1) {
																					history.push(RouteKey.VEHICLE_MODEL_GROUP, { record: records });
																				}
																				else {
																					history.push(RouteKey.VEHICLE_MODEL, { record: m });
																				}
																			}} title={t("Common.Edit")} icon={<EditOutlined />} />
																		</Can>
																		{(m.groupId === undefined || m.groupId === null) && !m.isDummyInternal &&
																			<Can requiredPermission={[Permission.MERGE_VEHICLE_MODEL]}>
																				<ResponsiveButton type="link" onClick={() => {
																					setSelectedVehicleCompatibility(m);
																					setVehicleMergeModal(true);
																				}} title={t("VehicleModel.Merge")} icon={<MergeCellsOutlined />} />
																			</Can>
																		}
																		{
																			((record.supportedKit || []).length !== 0 || (record.supportedPid || []).length !== 0 || (record.groupModel || []).length > 1) &&
																			<Button type="link" className={style.showMoreLink} onClick={() => {
																				setSelectedVehicleCompatibility(m);
																				setVehicleCompatibilityDetailModal(true);
																			}}>
																				{t("Common.ShowMore")}
																				<RightOutlined />
																			</Button>
																		}
																	</div>
																</Col>
															</Row>
														</Spin>
													)
												}
											})
											return (
												<Spin spinning={isVehicleCompatibilityLoading}>
													<Row>
														<Col xs={{ span: 24 }} style={{paddingLeft:30}}>
															<Tabs defaultActiveKey="1" items={tabItems}/>
														</Col>
													</Row>
												</Spin>
											);
										},
										rowExpandable: (record) => true,
									}} />
							}
						</Col>
					</Row>
				</PanelBody>
			</Panel>
			<VehicleCompatibilitySearchModal
				isModalOpen={showCsvSearch}
				vehicleCompatibilityList={vehicleCompatibility}
				onComplete={(keywords, searchedData, totalRecord, matchedRecord) => {
					setShowCsvSearch(false);

					let obj = { ...searchObj };
					obj.recordIds = searchedData.map(i=>i.id);
					onFinish(obj);

					dispatch(
						addToast({
							title: i18n.t("VehicleModel.CsvSearchSubmitted"),
							type: "success"
						})
					);
				}}
				groupedModel={true}
				onClose={() => { setShowCsvSearch(false) }} />
			<VehicleMakeModelYearFormModal
				isModalOpen={newVehicleModelModal}
				onClose={() => {
					setNewVehicleModelModal(false);
				}} />
			<VehicleCompatibilityMergeModal
				isModalOpen={vehicleMergeModal}
				vehicleDetail={selectedVehicleCompatibility}
				onClose={() => {
					setSelectedVehicleCompatibility(undefined);
					setVehicleMergeModal(false);
				}} />

			<VehicleCompatibilityInfoModal
				isModalOpen={infoModelOpen}
				onClose={() => { setInfoModalOpen(false) }} />
			<VehicleCompatibilityDetailModal
				setSelectedKit={onViewKit}
				vehicleDetail={selectedVehicleCompatibility}
				isModalOpen={vehicleCompatibilityDetailModal}
				onClose={() => {
					setSelectedVehicleCompatibility(undefined);
					setVehicleCompatibilityDetailModal(false);
				}} />
			<VehicleCompatibilityResourceModal
				kitDetail={selectedKit}
				isModalOpen={selectedKit !== undefined}
				onClose={() => { setSelectedKit(undefined) }} />
		</div>
	)
}

export default VehicleCompatibility;