import React from 'react';
import { Card, Button } from 'antd';
import { Buttons } from '../control/Buttons'

const Panel = (props) => {
	return (
		<Card title={props.header} style={props.style} 
		    headStyle={{ paddingLeft: 24, paddingRight: 0 }} 
			bodyStyle={props.noPadding ? { padding: 0 } : {}} 
			bordered={false}
			extra={(props.newButtonAction  || props.buttons) ? 
			<div style={{ display: "flex", alignItems: "center", paddingRight:"15px" }}>{
				props.newButtonAction ? props.newButtonAction() : null
			}
				{
					props.buttons ? <Buttons
						moreButtons={props.buttons}
						{...props}
					/> : null
				}
			</div> : null
		}>
			{props.children}
		</Card>
	);
};

const PanelBody = props => {
	return (
		<div className={props.className}>
			{props.children}
		</div>
	);
};

export { Panel, PanelBody };
