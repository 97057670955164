export const LANGUAGE_LOCALES = {
  EN_US: 'en-US',
  EN_AU: 'en-AU',
  EN_GB: 'en-GB',
  EN_NZ: 'en-NZ',
  EN_CA: 'en-CA',
  EN_MX: 'en-MX',
  EN_CL: 'en-CL',
  ES_MX: 'es-MX',
  ES_CL: 'es-CL',
  ES_US: 'es-US',
  FR_FR: 'fr-FR',
  FR_CA: 'fr-CA',
  DA_DK: 'da-DK'
};

export const LANGUAGES = {
  EN: 'en',
  ES: 'es',
  FR: 'fr',
  DA: 'da'
};