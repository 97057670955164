import XLSX from 'sheetjs-style';

const lower = (str = '') => str.toLowerCase();

export const exportFile = (data, properties, customHeaderString) => {
  const { width, title } = properties;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(
    data.sort((a, b) =>
      lower(a?.Name) > lower(b?.Name) ? 1 : lower(b?.Name) > lower(a?.Name) ? -1 : 0
    ),
    {
      cellDates: true
    }
  );

  // Create a new worksheet
  const newWorksheet = XLSX.utils.aoa_to_sheet([]);

  if (customHeaderString) {
    // Add custom header string to the first row
    const customHeader = [customHeaderString];
    const customHeaderRow = customHeader.map((header) => [header]);
    XLSX.utils.sheet_add_aoa(newWorksheet, customHeaderRow);
  }
  else {
    const customHeader = data[0];
    const customHeaderRow = [Object.keys(customHeader)];
    XLSX.utils.sheet_add_aoa(newWorksheet, customHeaderRow);
  }

  // Copy data from the original worksheet
  const range = XLSX.utils.decode_range(worksheet['!ref']);
  for (let rowNum = range.s.r + (customHeaderString ? 0 : 1); rowNum <= range.e.r; rowNum++) {
    const row = [];
    for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
      const cellAddress = { r: rowNum, c: colNum };
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      const cell = worksheet[cellRef];
      if (cell && cell.t === 's') {
        cell.s = {
          alignment: {
            wrapText: true
          }
        };
      }

      if (rowNum == 0) {
        cell.s = {
          font: {
            bold: true
          }
        };
      }
      row.push(cell);
    }
    XLSX.utils.sheet_add_aoa(newWorksheet, [row], { origin: -1, autoWidth: true });
  }

  // Adjust the column widths
  newWorksheet['!cols'] = width;

  // Calculate the new range
  const newRange = {
    s: { r: 0, c: 0 }, // Starting cell is A1
    e: { r: range.e.r + (customHeaderString ? 1 : 0), c: range.e.c } // Include the custom header row
  };

  if (customHeaderString) {
    newWorksheet['A1'].s = {
      font: {
        bold: true,
        color: { rgb: "FF0000" }
      },
    }
  }

  // Set the range for the new worksheet
  newWorksheet['!ref'] = XLSX.utils.encode_range(newRange);

  XLSX.utils.book_append_sheet(workbook, newWorksheet, title);

  XLSX.writeFile(workbook, `${title}.xlsx`);
};