import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AuditsTable } from '../../components/auditsTable/AuditsTable';
import { setBackButton, setPageTitle } from '../../features/header/headerSlice';
import { useGetAuditList, useGetAudits } from '../../features/audit/auditApi'

//constants
import { AUDIT_ENTITY } from '../../components/auditsTable/constants';
import { useHistory } from 'react-router';
import { useCurrentCompany } from '../../features/company/companySlice';
import { useGetVehicleModelsQuery, useVehicleModelWithSource } from '../../features/vehicleModels/vehicleModelApi';

const VehicleModelAudits = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [auditData, setAuditData] = useState([]);
	const currentCompany = useCurrentCompany();
  const { data, isFetching, refetch } = useGetAuditList(AUDIT_ENTITY.VEHICLE_MODEL, history.location.state.id);
  const { data: vehicleModels, isFetching :isFetchingVehicleModel } = useGetVehicleModelsQuery({ companyId: currentCompany.id }, {pollingInterval: 1800000, skip:currentCompany?.id === undefined});

  const pageTitle = !history.location.state.name
    ? t('Audits.AuditHistory')
    : t('Audits.AuditHistoryFor', { name: history.location.state.name });

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle, dispatch]);

  useEffect(()=>{
    if (!isFetching && data && !isFetchingVehicleModel) {
      setAuditData(data.map(i=>{
        const vm = vehicleModels.find(k=>k.id === i.entityId);
        return {
          ...i,
          comment : vm && !i.comment ? t('Audits.AuditHistoryFor', { name: vm?.make+" "+vm?.model+" "+vm?.yearOfManufacture }) : i.comment
        }
      }));
    }
  }, [data, isFetching, isFetchingVehicleModel])
  
  return <AuditsTable audits={auditData} isFetching={isFetching} />;
};

export default VehicleModelAudits;