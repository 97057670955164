import React from 'react';
import { Modal, Drawer, Radio, Space } from 'antd';
import { useMediaQuery } from 'react-responsive'

export const ResponsiveModal = ({isModalOpen, onClose, ...props}) => {
  const {style, ...otherProps} = props;
  const isMobile =useMediaQuery({ maxWidth: 767 })

  return isMobile ?
    <Drawer
      placement={"bottom"}
      onClose={onClose}
      {...otherProps}
      height={"auto"}
      open={isModalOpen}>{props.children}</Drawer> :
    <Modal
      centered={true}
      style={style}
      {...otherProps}
      open={isModalOpen} onCancel={onClose}><div style={{paddingTop:"20px"}}>{props.children}</div></Modal>;
}