import React, { useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { Popover, Spin } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import style from './MoreMenu.module.scss';
import { useCan } from '../../../features/user/Can';

export const MoreMenu = ({
  items = [],
  trigger = 'click',
  placement = 'left',
  size = 'xl',
  border = false,
  noMargin=false,
  children,
  ...props
}) => {
  const [isVisible, setVisibility] = useState(false);
  const className = cn(style.moreContainer, { [style.xl]: size === 'xl', [style.border]: border });

  const can = useCan();

  return (
    <Popover
      content={
        <ul className={style.list}>
          {items.filter(i=>i.permission === undefined || can({ everyPermission: i.permission })).map((item, index) => {
            return item.link ? (
              <Link
                key={`moreMenu-${index}`}
                className={style.listItem}
                to={{ pathname: item.link, state: item?.state }}
                id={item.id}
              >
                {item.name}
              </Link>
            ) : (
              <li
                key={`moreMenu-${index}`}
                className={`${style.listItem} ${item.danger ? style.danger : ''} ${item.disabled ? style.disabled : ''
                  }`}
                onClick={e => {
                  if (item.onClick) {
                    item.onClick(e);
                  }
                }}
              >
                {item.name}
                {item.isLoading && <Spin size="small" className={style.buttonLoader} />}
              </li>
            );
          })}
        </ul>
      }
      trigger={trigger}
      open={isVisible}
      placement={placement}
      onOpenChange={() => setVisibility(!isVisible)}
      getPopupContainer={trigger => trigger.parentElement}
      {...props}
    >
      {children ? children : <EllipsisOutlined className={className} style={noMargin ? {margin:0} : {}} />}
    </Popover>
  );
};
