import React, { useMemo } from 'react';
import { Redirect, useHistory } from 'react-router';
import { RouteKey } from '../../config/page-key';

const Login = props => {
  const history = useHistory();

  let redirectUri = RouteKey.SSO;
  if (history.location.search) {
    redirectUri += '?from=/' + history.location.search;
  } else if (
    history.location.state?.from &&
    (history.location.state.from.pathname !== RouteKey.DEFAULT || history.location.state.from.search)
  ) {
    redirectUri +=
      '?from=' + history.location.state.from.pathname + history.location.state.from.search;
  }
  
  return <Redirect to={redirectUri} />;
};

export default Login;