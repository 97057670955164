import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RouteKey } from '../../config/page-key';
import { useUser } from '../../features/user/ssoSlice';
import { useHistory } from 'react-router-dom';
import {  resetRemoteSignIn, logout } from '../../features/user/ssoSlice';

const Logout = props => {
  const dispatch = useDispatch();
  const user = useUser();
	const history = useHistory();
  
  const keycloakConfig = user?.auth?.keycloakConfig;

  let redirectUrl = RouteKey.SSO;
  if (keycloakConfig != null) {
    if (window.keycloak?.logout) {
      if (history.location?.state?.type) {
        redirectUrl += '?logoutType='+history.location?.state?.type;
      }
      else{
        redirectUrl += '?logoutType=1';
      }
      window.keycloak.logout({
        redirectUri: window.location.origin + redirectUrl
      });
    }
  }

  useEffect(() => {
    dispatch(logout());
    dispatch(resetRemoteSignIn());
  }, []);

  return <Redirect to={redirectUrl} />;
};

export default Logout;
