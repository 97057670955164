import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RouteKey } from '../../../config/page-key';
import { UserOutlined } from '@ant-design/icons';
import headerStyles from './dropdown.module.scss';
import { Link } from 'react-router-dom';
import { Dropdown, Avatar, Menu, Space } from 'antd';
import { useUserInfo } from '../../../features/user/userApi';

const DropdownProfile = () => {
	const { i18n, t } = useTranslation();
	const history = useHistory();
	const user = useUserInfo();
	
	const renderProfileOption = () =>{
		return (
			<Menu>
				<Menu.Item onClick={() => { history.push(RouteKey.LOGOUT) }}>{t("Logout")}</Menu.Item>
			</Menu>
		)
	}

	return (
		<Dropdown placement="bottomRight" overlay={renderProfileOption()} trigger={['click']}>
			<Link onClick={(e) => e.preventDefault()} className={`${headerStyles.Dropdown} ${headerStyles. navbarUser}`} style={{ "display": "flex" }}>
				<Space size={0} className={headerStyles.ClickableDiv}>
					<Avatar size={18} className={headerStyles.avatorIcon} ><span style={{fontSize:14}}>{user.firstName ? user?.firstName[0] + user?.lastName[0] : "-"}</span></Avatar>
				</Space>
			</Link>
		</Dropdown>
	);
};

export default DropdownProfile;
